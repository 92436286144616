
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">
        <div class="row vh-100 g-0">
          <div class="col-lg-6 position-relative d-none d-lg-block">
            <div class="bg-holder" style="background-image:url(../assets/img/bg/31.png);">
            </div>
            <!--/.bg-holder-->
  
          </div>
          <div class="col-lg-6">
            <div class="row flex-center h-100 g-0 px-4 px-sm-0">
              <div class="col col-sm-6 col-lg-7 col-xl-6">
                <div class="text-center mb-6 mx-auto"><img class="mb-7 d-dark-none" src="../../../assets/img/spot-illustrations/1.png" alt="phoenix" /><img class="mb-7 d-light-none" src="../../../assets/img/spot-illustrations/dark_1.png" alt="phoenix" />
                  <div class="mb-6">
                    <h4 class="text-1000">Your account is disabled</h4>
                    <p class="text-700">
                        If you beleive this is not correct, 
                        please contact our customer support team and we would be happy to assist.
                    </p>
                  </div>
                  <div class="d-grid"><a class="btn btn-primary" href="./"><span class="fas fa-angle-left me-2"></span>Go to sign in page</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->
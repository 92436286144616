import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr';
import { number } from 'echarts';
import flatpickr from 'flatpickr'; 
import { BloodSugarModel } from 'src/app/shared/model/blood.sugar.model';
import { UserModel } from 'src/app/shared/model/user.model';
import { BloodSugarService } from 'src/app/shared/services/blood.sugar.service';
import { ToastrService } from 'ngx-toastr';
import { SharedEventService } from 'src/app/shared/services/shared.event.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-blood-sugar-form',
  templateUrl: './blood-sugar-form.component.html',
  styleUrls: ['./blood-sugar-form.component.css']
})
export class BloodSugarFormComponent implements OnInit, AfterViewInit {

  @ViewChild('myBloodSugarField') myBloodSugarField: ElementRef | any;
  @ViewChild('offcanvasBloodSugarForm') offcanvasBloodSugarForm: ElementRef | any;
  @ViewChild('bloodSugarFormCanvasCloseButton') bloodSugarFormCanvasCloseButton: ElementRef | any;

  bloodMeasure: BloodSugarModel;
  
  userModel: UserModel | undefined;

  timeOptions:FlatpickrDefaultsInterface = {
    enableTime: true,
    noCalendar: true,
    minuteIncrement: 1,
    dateFormat: 'G:i K',    
  }

  dateOptions:FlatpickrDefaultsInterface = {
    enableTime: false,
    noCalendar: false,
    dateFormat: 'F d, Y',
  }


  constructor(
    private bloodSugarService: BloodSugarService,
    private actRoute: ActivatedRoute,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private eventService: SharedEventService,
    private userService: UserService,
  ) {
    this.bloodMeasure = new BloodSugarModel();
    this.bloodFormReset();
  }

  ngAfterViewInit() {


    if(this.offcanvasBloodSugarForm) {


      const offcanvasElement = this.offcanvasBloodSugarForm.nativeElement;

      // Add an event listener for the Bootstrap offcanvas event 'show.bs.offcanvas'
      offcanvasElement.addEventListener('shown.bs.offcanvas', () => {        
        this.myBloodSugarField.nativeElement.focus();        
      });

      offcanvasElement.addEventListener('hidden.bs.offcanvas', () => {
        this.bloodFormReset();        
      });
    }

  }

  ngOnInit() {

    // subscribe to userModel
    this.userService.getProfile().subscribe({
      next: (value) => {
        if(value) {
          this.userModel = value;          
        }
      },
    });

    // get measureType from a user's profile
    if(this.userModel) {
      this.bloodMeasure.measureType = this.userModel?.dataConfig.bloodSugarMeasureType;
    }

  }

  bloodSugarValueChange() {
    
    const isOk = this.validateBloodSugarForm();
    if(isOk) {
      const nativeElement = this.myBloodSugarField.nativeElement;
      this.renderer.removeClass(nativeElement, 'is-invalid');
    }
  }
  

  bloodFormReset() {
    this.bloodMeasure.date = flatpickr.formatDate(new Date(), "F d, Y");
    this.bloodMeasure.time = flatpickr.formatDate(new Date(), "G:i K");
    this.bloodMeasure.value = null;
    this.bloodMeasure.mealTag = "Random Blood Sugar (RBS)";
    this.bloodMeasure.activityTag =  "Sedentary (Little or No)";
    this.bloodMeasure.notes = "";

     // update css
     //const nativeElement = this.myBloodSugarField.nativeElement;
     //this.renderer.removeClass(nativeElement, 'is-invalid');
  }

  closeBloodSugarForm() {

    // close it using a close button
    const button  = this.bloodSugarFormCanvasCloseButton.nativeElement;
    if(button) {
      button.click();
    }

    //this.renderer.removeClass(offcanvasElement, 'show');
    //this.renderer.addClass(offcanvasElement, 'offcanvas');

  }

  bloodSugarFormSubmit() {

    this.updateBloodSugarValues();
    this.updateDateValues();
    
    const isOk: boolean = this.validateBloodSugarForm();

    if(isOk) {
      
      // call API to crate a new measure
      this.bloodSugarService.create(this.bloodMeasure).subscribe({
        next: (value) => {
          console.log("create result: " + value);                 
          this.toastr.success('New blood sugar value was saved!');          
          this.eventService.sendMessage(this.eventService.BLOOD_SUGAR_DATA_CHANGE);
          this.eventService.sendMessageWithObject(this.eventService.NEW_BLOOD_SUGAR_VALUE, value);
        },
      });

      // close form
      this.closeBloodSugarForm();     
    }
    
  }

  validateBloodSugarForm(): boolean {
    
    var result: boolean = true;

    const value = this.bloodMeasure.value;
    if(!value || value < 1 || value > 999) {
      result = false;

      // update css
      const nativeElement = this.myBloodSugarField.nativeElement;

      // Add a CSS class
      this.renderer.addClass(nativeElement, 'is-invalid');
      this.myBloodSugarField.nativeElement.focus();   
    }

    return result
  }

  updateBloodSugarValues() {
    
    // calculate mg/dL and mmol/L values
    const value = this.bloodMeasure.value;
    const measureType = this.bloodMeasure.measureType;
    if(value && value > 0) {
      if(measureType == "mg/dL") {
        this.bloodMeasure.mgValue = Number(value.toFixed(0));
        this.bloodMeasure.mmolValue = Number((value / 18.01588).toFixed(1));
      }
      else {
        this.bloodMeasure.mgValue = Number((value * 18.01588).toFixed(0))
        this.bloodMeasure.mmolValue = Number(value.toFixed(1));
      }
    }
  }

  updateDateValues() {

    // update date
    let tempDate = flatpickr.parseDate(this.bloodMeasure.date, "F d, Y");
    let tempTime = flatpickr.parseDate(this.bloodMeasure.time, "G:i K");
    if(tempDate && tempTime) {

      tempDate.setHours(tempTime.getHours());
      tempDate.setMinutes(tempTime.getMinutes());
      this.bloodMeasure.creationDate = tempDate.getTime();
    
    }
  }

}

import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })
export class SharedEventService {

    messageEmitter = new EventEmitter<string>();
    messageWithObjectEmitter = new EventEmitter<string[]>();

    readonly FASTING_BLOOD_SUGAR_FORM: string = "FASTING_BLOOD_SUGAR_FORM"
    readonly BLOOD_SUGAR_DATA_CHANGE: string = "BLOOD_SUGAR_DATA_CHANGE"    
    readonly NEW_BLOOD_SUGAR_VALUE: string = "NEW_BLOOD_SUGAR_VALUE"    

    readonly INSULIN_DATA_CHANGE: string = "INSULIN_DATA_CHANGE"    
    readonly NEW_INSULIN_VALUE: string = "NEW_INSULIN_VALUE"    

    sendMessage(message: string) {
        this.messageEmitter.emit(message);
    }

    sendMessageWithObject(message: string, object: any) {
        if(object) {
            const array: string[] = [message, JSON.stringify(object)];
            this.messageWithObjectEmitter.emit(array);       
        }        
    }
}
import { Component, OnInit } from '@angular/core';
import { SaStep1Component } from './sa-step-1/sa-step-1.component';
import { AuthService } from 'src/app/shared/services/auth.service';

import { ActivatedRoute, Route, Router } from '@angular/router';
import { CachedUserService } from 'src/app/shared/services/cached.user.service';
import { UserModel, UserSelfAssessmentModel } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/services/user.service';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-self-assessment',
  templateUrl: './self-assessment.component.html',
  styleUrls: ['./self-assessment.component.css']
})
export class SelfAssessmentComponent implements OnInit {

  public fbUser: any;
  public fbToken: any;


  public userModel: UserModel | undefined;
  public selfAssessmentModel: UserSelfAssessmentModel = new UserSelfAssessmentModel();

  constructor(
    private authService: AuthService,    
    public userService: UserService,
    //private actRoute: ActivatedRoute,
  ) { 
    console.log("constructor: user.isAuthenticated: " + this.authService.isAuthenticated)

  }

  ngOnInit() { 

    // subsribe to userModel
    this.userService.getProfile().subscribe({
      next: (value) => {
        console.log("ngAfterViewInit new user")
        if(value) {
          this.userModel = value;     
          this.loadAssessment();
        }
      },
    });   

  }

  loadAssessment() {
    this.userService.getSelfAssessment().subscribe({
      next: (value) => {
          //console.log("value: " + value); 
          this.selfAssessmentModel = value;           
      },
      error: (err) => {
        if(err.status === 404) {
          console.log("error")

          // create and save new SelfAssessment object
          const user = this.userModel;
          if(user) {
            const newObj = new UserSelfAssessmentModel();
            newObj.uid = user.uid;
            this.userService.createSelfAssessment(newObj).subscribe({
              next: (value) => {
                console.log("createSelfAssessment value: " + value);
              }
            })        
            this.selfAssessmentModel = newObj;
          }            
        } 
        else {
          console.log('An error occurred:', err);
        }
      },
    });
  }

}

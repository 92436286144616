export class DailyRoutineModel {
    
    //  User ID
    uid: string;

    // Core Functions

    fastingBloodSugar: string | undefined;
    bloodPressure: string | undefined;
    medications: string | undefined;
    medications2: string | undefined;
    footCare: string | undefined;
    stressLevel: string | undefined;
    symptomaticEpisodes: string | undefined;
    sleepQuality: string | undefined;
    sleepQuality2: string | undefined;


    // Diet & Nutrition
    weight: string | undefined;
    calories: string | undefined;
    carbs: string | undefined;
    healthySnacks: string | undefined;
    alcohol: string | undefined;
    alcohol2: string | undefined;
    intermittentFasting: string | undefined;
    excercise: string | undefined;
    excercise2: string | undefined;
  
    private creationDate: number;
    private dateMarker: number;

    constructor() {
      this.uid = "";
      
      this.creationDate = 0;
      this.dateMarker = 0;
      this.setCreationDate((new Date()).getTime());
    }

    getCreationDate(): number {
      return this.creationDate;
    }

    setCreationDate(value: number) {
      this.creationDate = value;
      const inputDate = new Date(this.creationDate);      
      this.dateMarker = this.generateDateMarker(inputDate);
    }

    getDateMarker(): number {
      return this.dateMarker;
    }

    generateDateMarker(date: Date):number {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0');
      return Number(`${year}${month}${day}`);
    }

  }
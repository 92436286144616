import { AfterViewInit, Component } from '@angular/core';
import { faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { UserModel } from 'src/app/shared/model/user.model';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements AfterViewInit {

  userModel: UserModel = new UserModel();

  faSquareCheck = faSquareCheck;
  faCheck = faCheck;

  urlSoloFree: string = "";
  urlSoloPlus: string = "";

  constructor(
    private userService: UserService,    
    private subsriptionService: SubscriptionService,  
  ) {}

  ngAfterViewInit(): void {
    
    // load userModel
    this.userService.getProfile().subscribe({
      next: (value) => {
        if(value) {
          this.userModel = value;               
          //this.initFormValues();     
        }
      },
    });

    // get Subscription Session URL for Solo Free
    this.subsriptionService.getCheckoutSessionURL("Solo Free").subscribe({
      next: (value) => {
        this.urlSoloFree = (value as any).url;
        //console.log("urlSoloFree: " + this.urlSoloFree);
      }
    });

    // get Subscription Session URL for Solo Plus
    this.subsriptionService.getCheckoutSessionURL("Solo Plus").subscribe({
      next: (value) => {
        this.urlSoloPlus = (value as any).url;
        //console.log("urlSoloPlus: " + this.urlSoloPlus);
      }
    })

  }

}

import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JourneyConfig } from 'src/app/shared/model/journey.config';
import { UserModel } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-onboarding-final',
  templateUrl: './onboarding-final.component.html',
  styleUrls: ['./onboarding-final.component.css']
})
export class OnboardingFinalComponent implements OnInit, AfterViewInit {

  userModel: UserModel | any;
  suggestedJourney: JourneyConfig | undefined;

  constructor(
    private userService: UserService,  
    private renderer: Renderer2,  
    private toastr: ToastrService,
    private router: Router,
  ) {

    // set initial value for userModel
    this.userModel = this.userService.getCurrentUserModelValue
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {

    // load userModel
    this.userService.getProfile().subscribe({
      next: (value) => {
        if(value) {
          this.userModel = value;               
          this.userModel.onboardingStatus = "Step 3";
          this.saveData();  
        }
      },
    });

  
  }

  saveData() {
    this.userService.saveProfile(this.userModel).subscribe({
      next: (value) => {
        // nothing for now
      },
    })
  }

  launchJourney() {    
    this.userModel.onboardingStatus = "Baseline Period";
    this.userModel.subscriptionPlan = "Solo Plus Trial";
    this.userModel.subscriptionStatus = "active";
    this.saveData();
    this.router.navigateByUrl("/dashboard");    
  }

}

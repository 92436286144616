

    <div class="card p-4">   
        
        <div class="d-flex me-3">
            <div>
                <h4>Blood Sugar Journal</h4>
                <!--<a routerLink="/blood-sugar-list">Blood Sugar Journal</a>-->
                <p class="fs--1 fw-semi-bold">Explore your personal blood sugar trends</p>
            </div>
            <div class="ms-auto">
                <!--<button #addBloodSugarButton (click)="addBloodButton()" class="btn btn-primary btn-sm" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBloodSugarForm">New Record</button>-->
                <button #addBloodSugarButton (click)="addBloodButton()" class="btn btn-sm btn-phoenix-secondary  btn-small" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBloodSugarForm"><fa-icon [icon]="faPlus" /></button>
                <!--<button #addBloodSugarButton class="btn btn-sm btn-phoenix-secondary rounded-pill btn-small" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDailyRoutineConfig"><fa-icon [icon]="faSliders" /></button>                                                                                                                  -->
            </div>
        </div> 

        <div *ngIf="isBloodSugarDataEmpty" class="d-flex align- justify-content-center h-100">
            <div class="card-body alert alert-soft-primary">
                <h5 class="card-title">Tip</h5>
                <p class="text-secondary">
                    Begin tracking your Blood Sugar measurements here. 
                </p>
                <p class="text-secondary mb-5">
                    Click the button below to add your first record, 
                    or choose to hide this widget if you don't plan to track Blood Sugar.
                </p>
                <a class="btn btn-primary" data-bs-toggle="offcanvas" data-bs-target="#offcanvasInsulinForm">Add Blood Sugar Record</a>                
                <a class="btn btn-outline-secondary ms-4" (click)="disableBloodSugarWidget()">Hide Blood Sugar Journal</a>
            </div>
        </div>

              
        <div *ngIf="!isBloodSugarDataEmpty" class="row g-0">
            
                <div class="d-flex align- justify-content-center h-100">
                    <div class="echart-total-sales-chart w-100">
                        <div echarts [loading]="false" [options]="chartOption" (chartInit)="onChartInit($event)" class="demo-chart"></div>
                    </div>
                    <!--<img class="w-100" src="../assets/img/bg/tmp-blood-sugar.png"/>-->
                </div>  
            
        </div>

        <div *ngIf="!isBloodSugarDataEmpty && this.userModel"  class="row g-0">            
            <div class="d-flex flex-wrap">                
                <div class="flex-fill flex-1 d-flex">
                    <div class="form-check form-switch"> 
                        <input [(ngModel)]="this.userModel.journeyConfig.showRandomBloodSugar" class="form-check-input" id="" type="checkbox" (change)="configLeversChange()">
                        <label class="form-check-label" for="">Random Blood Sugar (RBS)</label>
                    </div>  
                </div>
                <div class="flex-fill flex-1 d-flex">
                    <div class="form-check form-switch">
                        <input [(ngModel)]="this.userModel.journeyConfig.showBeforeMeals" class="form-check-input" id="" type="checkbox" (change)="configLeversChange()">
                        <label class="form-check-label" for="">Before Meals (Preprandial)</label>
                    </div>  
                </div>
                <div class="flex-fill flex-1 d-flex">
                    <div class="form-check form-switch">
                        <input [(ngModel)]="this.userModel.journeyConfig.showPostMeals" class="form-check-input" id="flexSwitchCheckCheckedDisabled" type="checkbox" (change)="configLeversChange()">
                        <label class="form-check-label" for="flexSwitchCheckCheckedDisabled">Post Meals (Postprandial)</label>
                    </div> 
                </div>
                <div class="flex-fill flex-1 d-flex">
                    <div class="form-check form-switch">
                        <input [(ngModel)]="this.userModel.journeyConfig.showFastingBloodSugar" class="form-check-input custom-switch-green" id="flexSwitchCheckChecked" type="checkbox" (change)="configLeversChange()">
                        <label class="form-check-label" for="flexSwitchCheckChecked">Fasting Blood Sugar</label>
                    </div> 
                </div>
                <div class="flex-fill flex-1 d-flex">
                    <div class="form-check form-switch">
                        <input [(ngModel)]="this.userModel.journeyConfig.showFeelingUnwellOrStressed" class="form-check-input custom-switch-orange" id="flexSwitchCheckCheckedDisabled" type="checkbox" (change)="configLeversChange()">
                        <label class="form-check-label" for="flexSwitchCheckCheckedDisabled">Feeling Unwell or Stressed</label>
                    </div>
                </div>
                <div class="flex-fill flex-1 d-flex">
                    <div class="form-check form-switch">
                        <input [(ngModel)]="this.userModel.journeyConfig.showDuringSymptomaticEpisode" class="form-check-input custom-switch-red" id="flexSwitchCheckCheckedDisabled" type="checkbox" (change)="configLeversChange()">
                        <label class="form-check-label" for="flexSwitchCheckCheckedDisabled">During Symptomatic Episode</label>
                    </div>
                </div>
            </div>
        </div>
      
    </div>



  <!-- Blood Sugar Offcanfas Form -->
  <app-blood-sugar-form></app-blood-sugar-form>

import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
//import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

import { environment } from 'src/environments/environment';


//import { AngularFireAuth } from '@angular/fire/compat/auth';
//import { GoogleAuthProvider } from 'firebase/auth';

import { Auth, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, User, signInWithPopup, user } from '@angular/fire/auth';
import { Subscription } from 'rxjs';
import { EmailFormModel } from 'src/app/shared/model/email.form';
import { FeedbackService } from 'src/app/shared/services/feedback.service';
import { ToastrService } from 'ngx-toastr';


//import { applypol}

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit, OnDestroy {


  fbUser: any;

  faCheck = faCheck;
  faFacebook = faFacebook;
  faFacebookF = faFacebookF;

  loginInProgress: boolean = false;

  private auth: Auth = inject(Auth);
  user$ = user(this.auth)
  userSubscription: Subscription;
 
  emailForm: EmailFormModel = new EmailFormModel();

  constructor(    
    public authService: AuthService,
    private userService: UserService,
    private feedbackService: FeedbackService,
    private router: Router,    
    private toastr: ToastrService,
  ) {

    this.userSubscription = this.user$.subscribe((aUser: User | null) => {
      //handle user state changes here. Note, that user will be null if there is no currently logged in user.
     //console.log(aUser);
     this.fbUser = aUser;
    })

  }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    // when manually subscribing to an observable remember to unsubscribe in ngOnDestroy
    this.userSubscription.unsubscribe();
  }

  // Close the modal by its ID
  closeModalById(modalId: string): void {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      //const bootstrapModal = new bootstrap.Modal(modalElement);
      //bootstrapModal.hide();
    }
  }

  successCallback(event: any) {

  }
  /*
  successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {
    console.log("successCallback")

    const isNewUser = signInSuccessData.authResult.additionalUserInfo?.isNewUser;
    const user = signInSuccessData.authResult.user;
    
    if (user) {      


      // update local user cache
      //localStorage.setItem('user', JSON.stringify(user));
      this.authService.reportSuccessfulLogin(user);

        this.loginInProgress = true;

        // get user from our database         
        this.userService.getProfile().subscribe({
          next: (userModel) => {
            
            var url = "/onboarding";

            if(userModel) {

              // 1. check if user is disabled
              if(!userModel.active) {
                url = "/disabledUser";
              }

              // 2. check if user has finished onboarding
              if(userModel.onboardingStatus === "Finished" ||
                  userModel.onboardingStatus === "Baseline Period") {
                url = "/dashboard";
              }


              console.log("userModel.uid: " + userModel?.uid)
              // now let's decide on the onboarding status and routing
                          
            }
            else {
              // TODO
              // userModel is somehow undefined
            }

            //redirect to dashboard
            setTimeout(() => {           
              
              // hide it after redirect actuall happens
              setTimeout(() => {      
                this.loginInProgress = false;
              }, 2000);
              window.location.href = url;
              
            }, 2000);

            //this.router.navigateByUrl(url);
            //window.location.href = url;

            ///this.loginInProgress = false;

          }
        })
        // get user from our database
        
      }  
    

  }
  */
    
  errorCallback(event: any) {}

  /*
  errorCallback(errorData: FirebaseUISignInFailure) {
    console.log("errorCallback")
  }
  */
    
  uiShownCallback() {
    console.log("uiShownCallback")
  }
  

  _logout() { 
    this.authService.SignOut2();
    this.router.navigateByUrl("/logout");
 }

  successfullLogin(user: any) {

    //console.log("successfullLogin")

 
    if (user) {      


        // update local user cache
        //localStorage.setItem('user', JSON.stringify(user));
        
        //this.authService.reportSuccessfulLogin(user);

        this.loginInProgress = true;

        // get user from our database         
        this.userService.getProfile().subscribe({
          next: (userModel) => {

            //console.log("userModel here: " + JSON.stringify(userModel))
            
            //var url = "/onboarding";
            var url = "/dashboard";
                        
            if(userModel) {

              //this.authService.userModel = userModel;

              // 1. check if user is disabled
              if(!userModel.active) {
                url = "/disabledUser";
              }

              // 2. check if user has finished onboarding
              if(userModel.onboardingStatus === "Finished" ||
                  userModel.onboardingStatus === "Baseline Period") {
                url = "/dashboard";
              }


              //redirect to dashboard
              setTimeout(() => {           
              
                // hide it after redirect actuall happens
                setTimeout(() => {      
                  this.loginInProgress = false;
                }, 2000);
                window.location.href = url;
                //console.log("target url: " + url)
                
              }, 2000);

              //console.log("userModel.uid: " + userModel?.uid)
              // now let's decide on the onboarding status and routing
                          
            }

          }
        })
        // get user from our database
        
      } 
  }

 async clickSignInWithGoogle() {
  
  const provider = new GoogleAuthProvider();
  
  //provider.addScope("openid");
  provider.addScope("https://www.googleapis.com/auth/userinfo.email");
  provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
  //console.log("default scopes: " + provider.getScopes())
  provider.setCustomParameters({       
        prompt: 'select_account',
        auth_type: 'reauthenticate'
  });

  const result = await signInWithPopup(this.auth, provider);
  // The signed-in user info.
  const user = result.user;
  //console.log("signInWithPopup user: " + JSON.stringify(user))

  // This gives you a ID Token Token.
  user.getIdToken().then((token) => {
    if(token) {
      //console.log("authService.token: " + token);
      this.authService.idToken = token;
      this.successfullLogin(user);
    }
  });
  


 }


 async clickSignInWithMicrosoft() {

  
  const provider = new OAuthProvider('microsoft.com');
  
  //provider.addScope("openid");
  provider.addScope("User.Read");
  //console.log("default scopes: " + provider.getScopes())

  const result = await signInWithPopup(this.auth, provider);
  // The signed-in user info.
  const user = result.user;

  //console.log("signInWithPopup user: " + JSON.stringify(user))
  
  // This gives you a ID Token Token.
  user.getIdToken().then((token) => {
    if(token) {
      //console.log("authService.token: " + token);
      this.authService.idToken = token;
      this.successfullLogin(user); 
    }
  });


 }

 async clickSignInWithFacebook() {

  const provider = new FacebookAuthProvider();
  
  provider.addScope("public_profile");
  provider.addScope("email");
  provider.setCustomParameters({ display: 'popup', auth_type: 'reauthenticate' });
  //provider.setCustomParameters({ display: 'popup' });

  const result = await signInWithPopup(this.auth, provider);
  // The signed-in user info.
  const user = result.user;
  //console.log("signInWithPopup user: " + JSON.stringify(user))

  // This gives you a ID Token Token.
  user.getIdToken().then((token) => {
    if(token) {
      //console.log("authService.token: " + token);
      this.authService.idToken = token;
      this.successfullLogin(user);
    }
  });

 }


 clickFeedbackForm() {
  console.log("clickFeedbackForm");
  this.feedbackService.submitEmailForm(this.emailForm).subscribe({
    next: (value) => {
        //console.log("data sent: " + value);
        this.resetEmailForm();
    },
  });
 }

 resetEmailForm() {
    this.emailForm = new EmailFormModel();
 }

}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SelfAssessmentComponent } from '../self-assessment.component';

@Component({
  selector: 'app-sa-step-7',
  templateUrl: './sa-step-7.component.html',
  styleUrls: ['./sa-step-7.component.css']
})
export class SaStep7Component {

  constructor(
    public router: Router,
    public ss: SelfAssessmentComponent
  ) { }

  onChange() {
    const value = this.ss.selfAssessmentModel;
    this.ss.userService.saveSelfAssessment(value).subscribe({
      next: (value) => {
          console.log("saveSelfAssessment result: " + value);
      },
    });
  }
  
  _prevButton() { 
    this.router.navigateByUrl("/self-assessment/6");
 }
  _nextButton() { 
    this.router.navigateByUrl("/self-assessment/8");
 }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelfAssessmentComponent } from '../self-assessment.component';

@Component({
  selector: 'app-sa-step-1',
  templateUrl: './sa-step-1.component.html',
  styleUrls: ['./sa-step-1.component.css']
})
export class SaStep1Component implements OnInit {

  constructor(
    public router: Router,
    private fb: FormBuilder,
    public ss: SelfAssessmentComponent
  ) { }

  ngOnInit(): void {

  }

  onSubmit() {
    console.log("submit button");
  }

  onChange() {
    const value = this.ss.selfAssessmentModel;
    this.ss.userService.saveSelfAssessment(value).subscribe({
      next: (value) => {
          console.log("saveSelfAssessment result: " + value);
      },
    });
  }

  _nextButton() {     
    this.router.navigateByUrl("/self-assessment/2");
 }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/shared/model/user.model';
import { SubscriptionModel } from 'src/app/shared/model/subscription';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';
import { faArrowRightFromBracket, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public userModel: UserModel | any;
  public subscription: SubscriptionModel | any;

  faArrowRightFromBracket = faArrowRightFromBracket;
  faDoorOpen = faDoorOpen;

  constructor(
    private userService: UserService,
    private authService: AuthService,    
    private router: Router,
    private subscriptionService: SubscriptionService,  
  ) {}

  ngOnInit(): void {
    
    // load userModel
    this.userService.getProfile().subscribe({
      next: (value) => {
        if(value) {
          this.userModel = value;   
          this.loadSubscription(value);            
        }
      },
    });
  }

  private loadSubscription(user: UserModel) {
    
    const uid = user.uid;      
    this.subscriptionService.getByUid(uid).subscribe({
      next: (value) => {
        if(value) {
          this.subscription = value;   
          //this.checkSubscriptionStatus(user, value)    
        }
        else { 
          //this.handleExpiredSubscription();
        }
      },        
    });
  
}

  logout() { 
    console.log('logout')
    this.authService.SignOut2().then(() => {
      //this.router.navigateByUrl("/logout");
    });
    this.router.navigateByUrl("/logout");
    
  }

  manageSubscriptionClick(event: Event) {
    event.preventDefault();

    // get Subscription Session URL
    this.subscriptionService.getCustomerPortalURL().subscribe({
      next: (value) => {
        const url = (value as any).url;
        console.log("URL: " + url);
        window.location.href = url;
      }
    })
  }
  
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";


@Component({
  selector: 'app-block1',
  templateUrl: './block1.component.html',
  styleUrls: ['./block1.component.css']
})
export class Block1Component implements OnInit {

  constructor(
    public authService: AuthService
  ) { }

  ngOnInit() { 
    //console.log("user: " + this.authService.userData)
    console.log("user.isAuthenticated: " + this.authService.isAuthenticated)
  }


  _loginWithGoogle() {  }


  _logout() { 
      this.authService.SignOut2();
   }

}

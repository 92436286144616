    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    
    <main class="main" id="top">

        <!--<nav class="navbar navbar-vertical navbar-expand-lg" style="display:block">
          vertical nav bar
        </nav>-->
        
          <app-header></app-header>
          
          
        <div class="content" >
  
          <!--==========================-->
  
          <div class="row g-0 w-100">

            <nav *ngIf="this.userModel.onboardingStatus==='Step 3'" aria-label="breadcrumb" class="mb-4"> 
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item fs-0"><a routerLink="/onboarding">Onboarding</a></li>
                <li class="breadcrumb-item active fs-0" aria-current="page">Step 3. Choose Your Path</li> 
              </ol>
            </nav>  
            
            
            <section id="pricing" class="position-static pt-15 pt-md-5 pt-lg-2 mt-0">
  
              <div class="container-small px-lg-7 px-xxl-3">
                <!--
                <div class="text-center mb-3 mb-lg-7">
                  <h5 class="text-info mb-3">Pricing</h5>
                  <h2 class="mb-2">Choose the best deal for you</h2>
                </div>
                -->
                <div class="row g-3 mb-7 mb-lg-11">
                  <div class="col-lg-4">
                    <div class="pricing-card">
                      <div class="card bg-transparent border-0">
                        <div class="card-body p-7">
                          <div class="d-flex align-items-center mb-4">
                            <div class="mb-0"><h3>Solo Free</h3></div>                             
                          </div>
                          <h1 class="fs-5 d-flex align-items-center gap-1 mb-3">$0<span class="fs-0 fw-normal">/ month</span></h1>
                          
                          <a href="{{urlSoloFree}}" class="btn btn-lg w-100 mb-7 btn-primary" >Subscribe</a> 
                          <h5 class="mb-4">What’s included</h5>
                          <ul class="fa-ul ps-3 m-0 pricing">
                            <li class="d-flex align-items-center mb-3">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">Core Platform Functionality</p>
                            </li>
                            <li class="d-flex align-items-center mb-3">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">Vitals Summary</p>
                            </li>
                            <li class="d-flex align-items-center mb-3">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">Blood Sugar Journal</p>
                            </li>
                            <li class="d-flex align-items-center mb-3">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">Insulin Journal</p>
                            </li>
                            <li class="d-flex align-items-center mb-0">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">Essential Notifications</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="pricing-card">
                      <div class="card bg-transparent border border-2 border-info rounded-4">
                        <div class="card-body p-7">
                          <div class="d-flex align-items-center mb-4">
                            <div class="mb-0"><h3>Solo Plus</h3></div> 
                            <div class="badge badge-phoenix badge-phoenix-success p-1 ps-3 pe-3 ms-3 mb-1 fs--1">30 Days Free</div>
                          </div>
                          <h1 class="fs-5 d-flex align-items-center gap-1 mb-3">$5.99<span class="fs-0 fw-normal">/ month</span></h1>
                          <!--
                            <div class="fs-3 fw-bold">30 days free</div>
                            <div class="fs--1 fw-bold ">Then $5.99 per month</div> 
                          -->
                          
                          <a href="{{urlSoloPlus}}" class="btn btn-lg w-100 mb-7 btn-primary" >Subscribe</a>
                          <h5 class="mb-4">What’s included</h5>
                          <ul class="fa-ul ps-3 m-0 pricing">
                            <li class="d-flex align-items-center mb-3">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">Everything from Solo Free</p>
                            </li>
                            <li class="d-flex align-items-center mb-3">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">Health Metrics</p>
                            </li>
                            <li class="d-flex align-items-center mb-3">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">Lifestyle Insights</p>
                            </li>
                            <li class="d-flex align-items-center mb-3">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">Fitness Trackers</p>
                              <span class="badge badge-phoenix badge-phoenix-info ms-2 fs--2">Coming Soon</span>
                            </li>
                            <li class="d-flex align-items-center mb-0">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">Notifications &amp; Reminders</p>
                            </li>                        
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="pricing-card">
                      <div class="card bg-transparent border-0">
                        <div class="card-body p-7">
                          <div class="d-flex align-items-center mb-4">
                            <div class="mb-0"><h3>Circle Plus</h3></div>                             
                          </div>                          
                          <h1 class="fs-5 d-flex align-items-center gap-1 mb-3">$9.99<span class="fs-0 fw-normal">/ month</span></h1>
                          <button class="btn btn-lg w-100 mb-7 btn-phoenix-primary">Coming Soon</button>
                          <h5 class="mb-4">What’s included</h5>
                          <ul class="fa-ul ps-3 m-0 pricing">
                            <li class="d-flex align-items-center mb-3">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">Everything from Solo Plus</p>
                            </li>
                            <li class="d-flex align-items-center mb-3">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">Smart Devices Integration</p>
                            </li>
                            <li class="d-flex align-items-center mb-3">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">"Share with Doctor"</p>
                            </li>
                            <li class="d-flex align-items-center mb-3">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">"Care Circle Access"</p>
                            </li>
                            <li class="d-flex align-items-center mb-0">
                              <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                              <p class="mb-0">Enhanced Notifications</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <!--
                <div class="card rounded-4 border-0 offer-card">
                  <div class="card-body d-md-flex align-items-center gap-4 py-5"><img class="mb-4 mb-md-0 d-dark-none" src="../../assets/img/spot-illustrations/air-plane.png" width="155" alt="" /><img class="mb-4 mb-md-0 d-light-none" src="../../assets/img/spot-illustrations/air-plane-dark.png" width="155" alt="" />
                    <div>
                      <p>Business Starter, Business Standard, and Business Plus plans can be purchased for a maximum of 300 users. There is no minimum or maximum user limit for Enterprise plans.</p>
                      <p class="mb-6">Phoenix customers may have access to additional features for a limited promotional period</p><a class="btn btn-link me-2 p-0 fs--1" href="#!" role="button">Contact Support<i class="fa-solid fa-angle-right ms-2"></i></a>
                    </div>
                  </div>
                </div>
                -->
    
              </div>
    
    
              <!-- end of .container-->
      
            </section>


            
            <div *ngIf="this.userModel.onboardingStatus!=='Step 3'" class="assessment-title mb-3">Subscription Options</div>
            
                <!--
                <div class="row row-cols-1 row-cols-lg-3 mb-3 text-center">
                  <div class="col ps-7 pe-7">
                    <div class="card mb-4 rounded-3 shadow-sm">
                      <div class="card-header py-3">
                        <h5 class="my-0 fw-bold">Solo Free</h5> 
                      </div>
                      <div class="card-body">
                        <h2 class="card-title pricing-card-title">$0<small class="text-body-secondary fw-light">/mo</small></h2>
                        <ul class="list-unstyled mt-3 mb-4 fs--1">
                            <li>Core Platform Functionality</li>
                            <li>Vital Summary</li>
                            <li>Blood Sugar Journal</li>
                            <li>Insulin Journal</li>
                            <li>Essential Notifications</li>                          
                        </ul>
                        <a href="{{urlSoloFree}}" class="w-100 btn btn btn-primary">Subscribe</a>
                      </div>
                    </div>
                  </div>
                  <div class="col ps-7 pe-7">
                    <div class="card mb-4 rounded-3 shadow-sm ">
                      <div class="card-header py-3">
                        <h5 class="my-0 fw-bold">Solo Plus</h5>
                      </div>
                      <div class="card-body">
                        <div class="fs-2 fw-bold">30 days free</div>
                        <div class="fs--1 fw-bold">Then $5.99 per month</div>                        
                        <ul class="list-unstyled mt-2 mb-4 fs--1">
                          <li>Everything from Solo Free</li>
                          <li>Health Metrics & Lifestyle Insights</li>
                          <li>Fitness Trackers Integration</li>
                          <li>Notifications & Reminders</li> 
                        </ul>
                        <a href="{{urlSoloPlus}}" class="w-100 btn btn btn-primary">Subscribe</a>
                      </div>
                    </div>
                  </div>
                  <div class="col ps-7 pe-7">
                    <div class="card mb-4 rounded-3 shadow-sm border-primary ">
                      <div class="card-header py-3  border-primary">
                        <h5 class="my-0 fw-bold">CircleLife Plus</h5>
                      </div>
                      <div class="card-body">
                        <h2 class="card-title pricing-card-title">$9.99<small class="text-body-secondary fw-light">/mo</small></h2>
                        <ul class="list-unstyled mt-3 mb-4 fs--1">
                          <li>Everything from Solo Plus</li>
                          <li>Smart Devices Integration</li>
                          <li>"Share with Doctor"</li>
                          <li>"Care Circle Access"</li>
                          <li>Enhanced Notifications</li>
                          
                        </ul>
                        <a class="w-100 btn btn btn-phoenix-primary cursor-regular">Coming Soon</a>  
                      </div>
                    </div>
                  </div>
                </div>
                -->
            
                <h2 class="text-center mb-4">Compare plans</h2>
            
                <div class="table-responsive ps-7 pe-7">
                  <table class="table text-center">
                    <thead>
                      <tr>
                        <th class="fw-semi-bold" style="width: 34%;"></th>
                        <th class="fw-semi-bold" style="width: 22%;">Solo Free</th>
                        <th class="fw-semi-bold" style="width: 22%;">Solo Plus</th>
                        <th class="fw-semi-bold" style="width: 22%;">CircleLife Plus</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row" class="text-start fw-semi-bold">
                          Vitals Summary
                          <div class="fs--1 fw-medium">
                            - Average Fasting Blood Sugar<br>                            
                            - Average Before Meals (Preprandial)<br>
                            - Average Post Meals (Postprandial)<br>
                            - Average Insulin Total Daily Dose<br>                            
                        </div>
                        </th>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                      </tr>
                      <tr>
                        <th scope="row" class="text-start fw-semi-bold">
                            Blood Sugar Journal
                            <div class="fs--1 fw-medium">
                                - Random Blood Sugar (RBS)<br>
                                - Before Meals (Preprandial)<br>
                                - Post Meals (Postprandial)<br>
                                - Fasting Blood Sugar<br>
                                - Feeling Unwell or Stressed<br>
                                - During Symptomatic Episode
                            </div>
                        </th>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                      </tr>
                    </tbody>
            
                    <tbody>
                      <tr>
                        <th scope="row" class="text-start fw-semi-bold">
                            Insulin Journal
                            <div class="fs--1 fw-medium">
                                - Rapid-acting<br>
                                - Short-acting<br>
                                - Intermediate-acting<br>
                                - Long-acting                                
                            </div>
                        </th>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                      </tr>
                      <tr>
                        <th scope="row" class="text-start fw-semi-bold">
                            Health Metrics
                            <div class="fs--1 fw-medium">
                                - Medications Adherence<br>
                                - Blood Pressure<br>
                                - Foot Care<br>
                                - Symptomatic Episodes<br>
                                - Stress Level<br>
                                - Sleep Quality
                            </div>
                        </th>
                        <td></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                      </tr>
                      <tr>
                        <th scope="row" class="text-start fw-semi-bold">
                            Lifestyle Insights
                            <div class="fs--1 fw-medium">
                                - Weight<br>
                                - Calories<br>
                                - Carbs<br>
                                - Healthy Snacks<br>
                                - Alcohol<br>
                                - Intermittent Fasting<br>
                                - Excercise
                            </div>
                        </th>
                        <td></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                      </tr>
                      <tr>
                        <th scope="row" class="text-start fw-semi-bold">Notifications & Reminders</th>
                        <td></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                      </tr>
                      <tr>
                        <th scope="row" class="text-start fw-semi-bold">Fitness Trackers Integration (Coming soon)</th>
                        <td></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                      </tr>                                            
                      <tr>
                        <th scope="row" class="text-start fw-semi-bold">Smart Devices Integration</th>
                        <td></td>
                        <td></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                      </tr>
                      <tr>
                        <th scope="row" class="text-start fw-semi-bold">"Share with Doctor"</th>
                        <td></td>
                        <td></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                      </tr>
                      <tr>
                        <th scope="row" class="text-start fw-semi-bold">"Care Circle Access"</th>
                        <td></td>
                        <td></td>
                        <td><fa-icon [icon]="faCheck" [size]="'lg'" /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              
  
          </div>
  
          <!--==========================-->
  
        </div>
        
      </main>
      <!-- ===============================================-->
      <!--    End of Main Content-->
      <!-- ===============================================-->


      <app-footer></app-footer>
  
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    
    <main class="main" id="top">

        <!--<nav class="navbar navbar-vertical navbar-expand-lg" style="display:block">
          vertical nav bar
        </nav>-->
        
          <app-header></app-header>
          
          
        <div class="content" >
  
          <!--==========================-->
  
          <div class="row g-0">
            
            <div class="col-lg-6">
              <div class="row flex-center g-0 px-4 px-sm-0">
                
                <nav aria-label="breadcrumb" class="mb-4"> 
                    <ol class="breadcrumb mb-0">
                      <li class="breadcrumb-item fs-0"><a routerLink="/onboarding">Onboarding</a></li>
                      <li class="breadcrumb-item active fs-0" aria-current="page">Launch Your Journey</li> 
                    </ol>
                  </nav>
    
                
                    
                
                    <!-- profile starts -->

                    <div class="d-flex min-vh-75 w-100 flex-column justify-content-center">
                        
                        <div class="flex-grow-1 "></div>
                        <div class="d-flex d-flex justify-content-center">
                    
                            <div class="assessment-block pe-4 mb-4" >
                                
                                <!-- Personalized Care Journey Sections -->

                                <!-- Personalized Daily Routine -->
                                <div>  
                                    
                                    <h5 class="mt-4 mb-2">Congratulations on Completing Your Onboarding!</h5>                                       
                                    <div>
                                        You've taken the first steps towards a healthier and more empowered 
                                        lifestyle with Novalumen. Now that your personalized care journey is set, 
                                        it's time to embrace a new chapter of well-being. Dive into your individual 
                                        plan, explore the features, and let Novalumen be your trusted companion on 
                                        this transformative journey. Your health, your way — start now!
                                    </div>

                                    <h5 class="mt-4 mb-2">What Happens Next Two Weeks?</h5>   
                                    <div>
                                        For the next 14 days, let the platform continue to learn and adapt to your 
                                        individual behaviors and patterns, refining your care plan based on 
                                        your unique lifestyle and preferences. Your health journey is about 
                                        to get even more precise.
                                    </div>

                    

                                    <button class="mt-3 btn btn-success me-1 mb-1" type="button" routerLink="/dashboard">Launch Your Journey</button>

                                    


                                  
                                </div>

                                
                               

                            </div>
                        </div>

                        <div class="p-2 flex-grow-1"></div>
                        
                        <!--
                        <div class="d-flex mb-2">
                            <div class="me-auto p-2">
                            <button class="btn btn-link" type="submit">Prev</button>
                            </div>
                            <div class="p-2">
                            <button class="btn btn-link" type="submit">Skip</button>
                            </div>
                            <div class="p-2">
                                <button class="btn btn-primary" type="submit">Next</button>
                            </div>
                        </div>
                        -->

                    </div>
                    <!--
                    <p>
                        🌟Cheers to your health journey with Novalumen! For any assistance, we're here for you. 🌟

                    </p>
                -->

                    <!-- profile ends -->
                    
                                                                        
                
                
  
  
              </div>
            </div>
  
  
            <div class="col-lg-6 position-relative d-none d-lg-block  ">
              <div class="d-flex align-items-center justify-content-center h-100">
                <img class="w-100" src="../assets/img/spot-illustrations/16.png"/>
              </div>  
            </div>
  
          </div>
  
          <!--==========================-->
  
        </div>
        
      </main>
      <!-- ===============================================-->
      <!--    End of Main Content-->
      <!-- ===============================================-->


      <app-footer></app-footer>
  
import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent {

  constructor(
    private titleService: Title, 
    private metaService: Meta,
  ) {
    this.titleService.setTitle('Privacy Notice');
  }

}

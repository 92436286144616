import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { FirebaseUserModel } from '../model/user.model';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { filter, take } from 'rxjs';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

export const FirebaseAuthResolver: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    authService: AuthService = inject(AuthService)
  ): Observable<any> =>
  authService.user$.pipe (
    catchError((err) => {
        return of('No data' + err);
      })
    );
import { HttpHeaders } from "@angular/common/http";
import { CachedUserService } from "./cached.user.service";
import { Injectable } from "@angular/core";
import { UserService } from "./user.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

    private timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    constructor(
        private authService: AuthService,
    ) {}

    generateHttpHeaders(): HttpHeaders {
        const idToken = this.authService.idToken;
        //const user = this.cachedUserService.getUser;
        
        if(idToken) {
          const headers = new HttpHeaders({
            'Authorization': 'Bearer your-access-token',
            'Content-Type': 'application/json',
            'client_id': 'nvl-web',
            'timezone': this.timezone,
            'token': idToken
          });
          return headers;
        }
        else {
          const headers = new HttpHeaders({
            'Authorization': 'Bearer your-access-token',
            'Content-Type': 'application/json',    
            'client_id': 'nvl-web',
            'timezone': this.timezone,      
          });
          return headers;
        }
      }

}
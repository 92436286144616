import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserModel } from 'src/app/shared/model/user.model';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-subscription-confirmation',
  templateUrl: './subscription-confirmation.component.html',
  styleUrls: ['./subscription-confirmation.component.css']
})
export class SubscriptionConfirmationComponent implements OnInit{

  userModel: UserModel = new UserModel();

  constructor(
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private userService: UserService,  
  ) {}

  ngOnInit(): void {

    const sessionId = this.route.snapshot.paramMap.get('sessionId');
    console.log('Parameter Value:', sessionId);

    
    if(sessionId) {

      this.subscriptionService.getByCheckoutSessionId(sessionId).subscribe({
        next: (value) => {
          //console.log("getByCheckoutSessionId: " + JSON.stringify(value));
          this.launchJourney();
        }
      })
    }
  
  }

  launchJourney() {   
    
    // load userModel
    this.userService.getProfile().subscribe({
      next: (value) => {        
        if(value) {
          this.userModel = value;    
          if(this.userModel.onboardingStatus !== "Finished") {          
            this.userModel.onboardingStatus = "Finished";
            this.saveData();   
          }
        }
      },
    });    
 
  }

  saveData() {
    this.userService.saveProfile(this.userModel).subscribe({
      next: (value) => {
        // nothing for now
      },
    })
  }
}

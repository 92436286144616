import { Component, Input, OnInit } from '@angular/core';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { UserModel } from 'src/app/shared/model/user.model';
import { DashboardSharedService } from 'src/app/shared/services/dashboard.shared.service';
import { SharedEventService } from 'src/app/shared/services/shared.event.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  @Input() public userModel: UserModel | any;

  faSliders = faSliders;
  
  constructor(
    private userService: UserService,
    public dashboardSharedService: DashboardSharedService,
    private eventService: SharedEventService,
  ) {}

  ngOnInit(): void {

    const days = this.userModel.dataConfig.rangeInDays; 
    //console.log("summary days: " + days)
    
    // blood sugar
    this.dashboardSharedService.getBloodSugarData(days).subscribe({
      next: (value) => {
        //console.log("summary subsribe to blood data: " + value?.length)
      }
    });

    // insulin
    this.dashboardSharedService.getInsulinData(days).subscribe({
      next: (value) => {
        //console.log("summary subsribe to insulin data: " + value?.length)
      }
    });


    // subscribe to events from Shared Event Service
    this.eventService.messageEmitter.subscribe((message: string) => {      
      this.eventListener(message);
    });
  }

  rangeChange() {
    this.dashboardSharedService.loadBloodSugarData(this.userModel.dataConfig.rangeInDays);
    this.dashboardSharedService.loadInsulinData(this.userModel.dataConfig.rangeInDays);
    this.saveProfile();
  }

  saveProfile() {
    if(this.userModel) {      
      this.userService.saveProfile(this.userModel).subscribe({
        next:(value) => {
          this.userService.pushNextValue(this.userModel);
        }
      })
    }
  }

  eventListener(message: string) {

    //console.log('eventListener: ' + message) 
    
    if(message === this.eventService.BLOOD_SUGAR_DATA_CHANGE) {   
       
      //console.log('got new blood sugar data in summary') 
      this.dashboardSharedService.loadBloodSugarData(this.userModel.dataConfig.rangeInDays);
    }

  }

}

import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr';
import { InsulinModel } from 'src/app/shared/model/insulin.model';
import flatpickr from 'flatpickr';
import { InsulinService } from 'src/app/shared/services/insulin.service';
import { ToastrService } from 'ngx-toastr';
import { SharedEventService } from 'src/app/shared/services/shared.event.service';

@Component({
  selector: 'app-insulin-form',
  templateUrl: './insulin-form.component.html',
  styleUrls: ['./insulin-form.component.css']
})
export class InsulinFormComponent implements OnInit, AfterViewInit {

  @ViewChild('myDosageUnitsField') myDosageUnitsField: ElementRef | any;
  @ViewChild('offcanvasInsulinForm') offcanvasInsulinForm: ElementRef | any;
  @ViewChild('offcanvasCloseButton') offcanvasCloseButton: ElementRef | any;


  insulinRecord: InsulinModel = new InsulinModel();

  timeOptions:FlatpickrDefaultsInterface = {
    enableTime: true,
    noCalendar: true,
    minuteIncrement: 1,
    dateFormat: 'G:i K',    
  }

  dateOptions:FlatpickrDefaultsInterface = {
    enableTime: false,
    noCalendar: false,
    dateFormat: 'F d, Y',
  }

  constructor(
    private insulinService: InsulinService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private eventService: SharedEventService,
  ){}

  ngOnInit(): void {
    this.formReset();
  }

  ngAfterViewInit(): void {
    if(this.offcanvasInsulinForm) {

      const offcanvasElement = this.offcanvasInsulinForm.nativeElement;

      // Add an event listener for the Bootstrap offcanvas event 'show.bs.offcanvas'
      offcanvasElement.addEventListener('shown.bs.offcanvas', () => {        
        this.myDosageUnitsField.nativeElement.focus();        
      });

      offcanvasElement.addEventListener('hidden.bs.offcanvas', () => {
        this.formReset();        
      });
    }
  }

  formReset() {
    this.insulinRecord.date = flatpickr.formatDate(new Date(), "F d, Y");
    this.insulinRecord.time = flatpickr.formatDate(new Date(), "G:i K");
    this.insulinRecord.dosageUnits = null;
    this.insulinRecord.dosageType = "Non-Relevant";
    this.insulinRecord.insulinType =  "Rapid-acting";
    this.insulinRecord.injectionSite =  "Other";
    this.insulinRecord.notes = "";     
  }

  closeForm() {
    // close it using a close button
    const button  = this.offcanvasCloseButton.nativeElement;
    if(button) {
      button.click();
    }
  }

  formSubmit() {
    
    this.updateDateValues();
    
    const isOk: boolean = this.validateForm();

    if(isOk) {
      
      // call API to crate a new measure
      this.insulinService.create(this.insulinRecord).subscribe({
        next: (value) => {
          console.log("create result: " + value);                 
          this.toastr.success('New insulin record was saved!');          
          this.eventService.sendMessage(this.eventService.INSULIN_DATA_CHANGE);
          this.eventService.sendMessageWithObject(this.eventService.NEW_INSULIN_VALUE, value);
        },
      });

      // close form
      this.closeForm();     
    }
  }

  validateForm(): boolean {
    
    var result: boolean = true;

    const value = this.insulinRecord.dosageUnits;
    if(!value || value < 0.1 || value > 999) {
      result = false;

      // update css
      const nativeElement = this.myDosageUnitsField.nativeElement;

      // Add a CSS class
      this.renderer.addClass(nativeElement, 'is-invalid');
      this.myDosageUnitsField.nativeElement.focus();   
    }

    return result
  }

  updateDateValues() {

    // update date
    let tempDate = flatpickr.parseDate(this.insulinRecord.date, "F d, Y");
    let tempTime = flatpickr.parseDate(this.insulinRecord.time, "G:i K");
    if(tempDate && tempTime) {

      tempDate.setHours(tempTime.getHours());
      tempDate.setMinutes(tempTime.getMinutes());
      this.insulinRecord.creationDate = tempDate.getTime();
    
    }
  }
  

  // inherited functions to change

  bloodSugarValueChange() {}
  
}

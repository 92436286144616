<div #offcanvasBloodSugarForm class="offcanvas offcanvas-end" id="offcanvasBloodSugarForm" tabindex="1" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header bg-soft border-bottom">
      <h5 id="offcanvasRightLabel">New Blood Sugar Record</h5>
      <button #bloodSugarFormCanvasCloseButton class="btn-close text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">

        <!--<div>{{ this.bloodMeasure | json }}</div>-->

        <!--<form #heroForm="ngForm" class="needs-validation">-->
            <div class="row g-0">
                <div class="w-50 pe-2">
                    <label class="form-label" for="datepicker">Date</label>
                    <input [(ngModel)]="this.bloodMeasure.date" mwlFlatpickr [options]="dateOptions" class="form-control datetimepicker flatpickr-input w-100 pe-2" type="text" placeholder="dd/mm/yyyy" >
                </div>
                <div class="w-50 ps-2">
                    <label class="form-label" for="timepicker1">Time</label>
                    <input [(ngModel)]="this.bloodMeasure.time" mwlFlatpickr [options]="timeOptions"  class="form-control timeOptions datetimepicker flatpickr-input w-100 pe-2" type="text" placeholder="hour : minute" >
                </div>
            </div>

            <div class="row g-0 mt-3">
                <div class="w-50 pe-2">
                    <label class="form-label">Blood Sugar Value</label>
                    <input #myBloodSugarField [(ngModel)]="this.bloodMeasure.value" (ngModelChange)="this.bloodSugarValueChange()" class="form-control w-100 pe-2" type="number" [step]="0" placeholder="" required="" >
                    <div class="invalid-feedback">Please provide a valid value.</div>
                </div>
                <div class="w-50 ps-2">
                    <label class="form-label">Type</label><br/>
                    
                    <div class="mt-2 ms-3">
                        <div class="form-check form-check-inline ">
                            <input [(ngModel)]="this.bloodMeasure.measureType" class="form-check-input" type="radio" name="measureType" id="inlineRadio1" value="mg/dL">
                            <label class="form-check-label" for="inlineRadio1">mg/dL</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.bloodMeasure.measureType" class="form-check-input" type="radio" name="measureType" id="inlineRadio2" value="mmol/L">
                            <label class="form-check-label" for="inlineRadio2">mmol/L</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row g-0 mt-3">
                <div class="w-50">
                    <label class="form-label">Meal Tag</label><br/>
                    
                    <div class="mt-2">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.bloodMeasure.mealTag" class="form-check-input" type="radio" name="mealTag" id="mealTag7" value="Random Blood Sugar (RBS)">
                            <label class="form-check-label" for="mealTag7">Random Blood Sugar (RBS)</label>
                        </div>
                    </div>            
                    <div class="">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.bloodMeasure.mealTag" class="form-check-input" type="radio" name="mealTag" id="mealTag2" value="Before Meals (Preprandial)">
                            <label class="form-check-label" for="mealTag2">Before Meals (Preprandial)</label>
                        </div>
                    </div>
                    <div class="">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.bloodMeasure.mealTag" class="form-check-input" type="radio" name="mealTag" id="mealTag3" value="Post Meals (Postprandial)">
                            <label class="form-check-label" for="mealTag3">Post Meals (Postprandial)</label>
                        </div>
                    </div>
                    <!--<div class="">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.bloodMeasure.mealTag" class="form-check-input" type="radio" name="mealTag" id="mealTag4" value="Before Snacks">
                            <label class="form-check-label" for="mealTag4">Before Snacks</label>
                        </div>
                    </div>-->
                    <div class="">
                        <div class="form-check form-check-inline ">
                            <input [(ngModel)]="this.bloodMeasure.mealTag" class="form-check-input" type="radio" name="mealTag" id="mealTag1" value="Fasting Blood Sugar">
                            <label class="form-check-label" for="mealTag1">Fasting Blood Sugar</label>
                        </div>
                    </div>
                    <div class="">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.bloodMeasure.mealTag" class="form-check-input" type="radio" name="mealTag" id="mealTag5" value="Feeling Unwell or Stressed">
                            <label class="form-check-label" for="mealTag5">Feeling Unwell or Stressed</label>
                        </div>
                    </div>
                    <div class="">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.bloodMeasure.mealTag" class="form-check-input" type="radio" name="mealTag" id="mealTag6" value="During Symptomatic Episode">
                            <label class="form-check-label" for="mealTag6">During Symptomatic Episode</label>
                        </div>
                    </div>        
                    
                </div>
                <div class="w-50 ps-2">
                    <label class="form-label">Activity Tag</label><br/>
                    
                    <div class="mt-2 ms-3">
                        <div class="form-check form-check-inline ">
                            <input [(ngModel)]="this.bloodMeasure.activityTag" class="form-check-input" type="radio" name="activityTag" id="activityTag1" value="Sedentary (Little or No)">
                            <label class="form-check-label" for="activityTag1">Sedentary (Little or No)</label>
                        </div>
                    </div>
                    <div class="ms-3">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.bloodMeasure.activityTag" class="form-check-input" type="radio" name="activityTag" id="activityTag2" value="Light">
                            <label class="form-check-label" for="activityTag2">Light</label>
                        </div>
                    </div>
                    <div class="ms-3">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.bloodMeasure.activityTag" class="form-check-input" type="radio" name="activityTag" id="activityTag3" value="Moderate">
                            <label class="form-check-label" for="activityTag3">Moderate</label>
                        </div>
                    </div>
                    <div class="ms-3">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.bloodMeasure.activityTag" class="form-check-input" type="radio" name="activityTag" id="activityTag4" value="Vigorous">
                            <label class="form-check-label" for="activityTag4">Vigorous</label>
                        </div>
                    </div>
                    <!--<div class="ms-3">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.bloodMeasure.activityTag" class="form-check-input" type="radio" name="activityTag" id="activityTag5" value="Not Applicable">
                            <label class="form-check-label" for="activityTag5">Not Applicable</label>
                        </div>
                    </div>-->
                    
                </div>
            </div>

            <div class="row g-0 mt-3">
                <div class="w-100">
                    <label for="exampleFormControlTextarea1" class="form-label">Notes</label>
                    <textarea [(ngModel)]="this.bloodMeasure.notes" class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="You can add a note here for this measurement"></textarea>
                </div>            
            </div>


            <div class="row g-0 mt-3">
                <div class="w-50 pe-2">
                    <button type="button" class="btn btn-outline-secondary mb-3 w-100" (click)="this.closeBloodSugarForm()">Cancel</button> <!-- data-bs-dismiss="offcanvas" -->
                </div>
                <div class="w-50 ps-2">
                    <button type="submit" class="btn btn-primary mb-3 w-100" (click)="bloodSugarFormSubmit()">Submit</button>
                </div>
            </div>

        <!--</form>-->

        

    </div>
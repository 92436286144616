export class InsulinModel {

    id: string;
    uid: string;
    date: string;
    time: string;
    dosageUnits: number | null;
    dosageType: string;
    insulinType: string;
    injectionSite: string;
    notes: string;
    creationDate: number;

    constructor() {
        this.creationDate = (new Date()).getTime();
        this.id = "";
        this.uid = "";
        this.date = "";
        this.time = "";
        this.dosageUnits = null;
        this.dosageType = "";
        this.insulinType = "";
        this.injectionSite = "";
        this.notes = "";            
    }

}
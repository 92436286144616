<div class="d-flex min-vh-75 w-100 flex-column justify-content-center">
    <div class="">                    
        <p>Question: 10 of 10</p>
        <div class="progress" style="height:8px">
        <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
    <div class="flex-grow-1 "></div>
    <div class="d-flex d-flex justify-content-center">

        <div class="assessment-block">
            
            <p class="question-header">What monitoring devices/tools do you have available?</p>

            <div class="form-check">
                <input class="btn-check" id="flexRadioDefault1" type="checkbox" [(ngModel)]="ss.selfAssessmentModel.monitoringDevices.bloodGlucose" (change)="onChange()"/>        
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault1">Blood Glucose Meter</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault2" type="checkbox" [(ngModel)]="ss.selfAssessmentModel.monitoringDevices.cgm" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault2">Continuous Glucose Monitoring (CGM) System</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault3" type="checkbox" [(ngModel)]="ss.selfAssessmentModel.monitoringDevices.insulinPump" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault3">Insulin Pump</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault4" type="checkbox" [(ngModel)]="ss.selfAssessmentModel.monitoringDevices.bloodPressure" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault4">Blood Pressure Monitor</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault5" type="checkbox" [(ngModel)]="ss.selfAssessmentModel.monitoringDevices.pulseOximeter" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault5">Pulse Oximeter</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault6" type="checkbox" [(ngModel)]="ss.selfAssessmentModel.monitoringDevices.weightScale" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault6">Weight Scale</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault7" type="checkbox" [(ngModel)]="ss.selfAssessmentModel.monitoringDevices.fitnessTracker" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault7">Fitness Tracker and/or Smartphone Apps</label>
            </div>

        </div>
    </div>
    <div class="p-2 flex-grow-1"></div>
    
    <div class="d-flex mb-2">
        <div class="me-auto p-2">
        <button class="btn btn-link" (click)="_prevButton()" type="submit">Prev</button>
        </div>
        <div class="p-2">
            <button class="btn btn-link" (click)="_nextButton()" type="submit">Skip</button>
        </div>
        <div class="p-2">
        <button class="btn btn-primary" (click)="_nextButton()" type="submit">Next</button>
        </div>
    </div> 
</div>

<div class="d-flex w-100 flex-column justify-content-center">

    <div class="card p-4 pb-1 pt-3">                    
      
        <!--<h4>Welcome {{ userModel?.displayName }}</h4>-->

        <div class="row g-0 me-3">
            <div class="d-flex">
                <div>
                    <h4>Vitals Summary</h4>
                    <div class="fs--1 fw-semi-bold mb-2">Here is the average data of what is happening in your body</div>
                </div>
                <!--<div class="ms-auto mt-1 me-3">
                    <button #dailyRoutineConfigButton class="btn btn-sm btn-phoenix-secondary btn-small" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDailyRoutineConfig"><fa-icon [icon]="faSliders" /></button>
                </div>-->
                <div class="ms-auto">
                    <select [(ngModel)]="this.userModel.dataConfig.rangeInDays" name="range" class="form-select form-select-sm" aria-label="Range in days" (change)="rangeChange()"> 
                        <option value="7">Last 7 Days</option>
                        <option value="14">Last 14 Days</option>
                        <option value="30">Last Month</option>
                        <option value="90">Last 3 Months</option>
                    </select>  
                    
                </div>
            </div>          
        </div>

        <div *ngIf="this.dashboardSharedService.isBloodSugarDataEmpty && this.dashboardSharedService.isInsulinDataEmpty" class="d-flex align- justify-content-center h-100">
            <div class="card-body alert alert-soft-primary">                
                <div class="text-secondary">
                    Begin tracking your blood sugar or insulin, 
                    and this widget will generate trending data for you. 
                </div>                            
            </div>
        </div>
        
        <div *ngIf="!this.dashboardSharedService.isBloodSugarDataEmpty || !this.dashboardSharedService.isInsulinDataEmpty" class="row g-0">
            <div class="d-flex flex-wrap">
                <div *ngIf="this.userModel?.journeyConfig?.vsFastingBloodSugar" class="mt-2 mb-2 flex-fill flex-1 d-flex">
                    <div class="green-box align-self-center"></div>
                    <div class="ms-3 align-self-center ">
                        <div class="fs--1 fw-bold mb-0 summary-item-title">Fasting Blood Sugar</div>
                        <h6 class="fs-0 fw-bolder">{{ dashboardSharedService.vitals.fastingBloodSugar }}</h6>
                    </div>
                </div>
                <div *ngIf="this.userModel?.journeyConfig?.vsBeforeMeals" class="mt-2 mb-2 flex-fill flex-1 d-flex">
                    <div class="green-box align-self-center"></div>
                    <div class="ms-3 align-self-center ">
                        <div class="fs--1 fw-bold mb-0 summary-item-title">Before Meals (Preprandial)</div>
                        <h6 class="fs-0 fw-bolder">{{ dashboardSharedService.vitals.beforeMealsBloodSugar }}</h6>
                    </div>
                </div>
                <div *ngIf="this.userModel?.journeyConfig?.vsPostMeals" class="mt-2 mb-2 flex-fill flex-1 d-flex">
                    <div class="green-box align-self-center"></div>
                    <div class="ms-3 align-self-center ">
                        <div class="fs--1 fw-bold mb-0 summary-item-title">Post Meals (Postprandial)</div>
                        <h6 class="fs-0 fw-bolder">{{ dashboardSharedService.vitals.postMealsBloodSugar }}</h6>
                    </div>
                </div>
                <div *ngIf="this.userModel?.journeyConfig?.vsInsulinTotalDailyDose" class="mt-2 mb-2 flex-fill flex-1 d-flex">
                    <div class="blue-box align-self-center"></div>
                    <div class="ms-3 align-self-center ">
                        <div class="fs--1 fw-bold mb-0 summary-item-title">Insulin Total Daily Dose</div>
                        <h6 class="fs-0 fw-bolder">{{ dashboardSharedService.vitals.insulinTotalDailyDose }}</h6>
                    </div>
                </div>
                <!--
                <div class="mt-2 mb-2 flex-fill flex-1 justify-content-center  d-flex">
                    <div class="blue-box align-self-center"></div>
                    <div class="ms-3 align-self-center ">
                        <div class="fs--1 fw-bold mb-2 summary-item-title">Daily Calories</div>
                        <h6 class="fs-1 fw-bolder">N/A</h6>
                    </div>
                </div>
                <div class="mt-2 mb-2 flex-fill flex-1 justify-content-center  d-flex">
                    <div class="grey-box align-self-center"></div>
                    <div class="ms-3 align-self-center ">
                        <div class="fs--1 fw-bold mb-2 summary-item-title">Medication Adherence</div>
                        <h6 class="fs-1 fw-bolder">N/A</h6>
                    </div>
                </div>
                -->        
            </div>

        </div>
    </div>

</div>
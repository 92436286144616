
<p>
    {{ environment.production}}
</p>

<p>
    {{ environment.apiServerURL}}
</p>

<p>
    {{ dexterLogin }}
</p>

<p>
    <a href="{{ dexterLogin }}">Dexter login</a>
</p>

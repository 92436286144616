<div class="d-flex min-vh-75 w-100 flex-column justify-content-center">
    <div class="">                    
        <p>Question: 2 of 10</p>
        <div class="progress" style="height:8px">
        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
    <div class="flex-grow-1 "></div>
    <div class="d-flex d-flex justify-content-center">

        <div class="assessment-block">
            
            <p class="question-header">What is your most recent HbA1c level?</p>

            <div class="form-check">
                <input class="btn-check" id="flexRadioDefault1" type="radio" name="question2" value="Less than 5.7%" [(ngModel)]="ss.selfAssessmentModel.hba1cLevel" (change)="onChange()" />        
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault1">Less than 5.7%</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault2" type="radio" name="question2" value="5.7% - 6.4%" [(ngModel)]="ss.selfAssessmentModel.hba1cLevel" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault2">5.7% - 6.4%</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault3" type="radio" name="question2" value="6.5% - 7.0%" [(ngModel)]="ss.selfAssessmentModel.hba1cLevel" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault3">6.5% - 7.0%</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault4" type="radio" name="question2" value="7.1% - 8.0%" [(ngModel)]="ss.selfAssessmentModel.hba1cLevel" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault4">7.1% - 8.0%</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault5" type="radio" name="question2" value="8.1% or higher" [(ngModel)]="ss.selfAssessmentModel.hba1cLevel" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault5">8.1% or higher</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault6" type="radio" name="question2" value="I don't know" [(ngModel)]="ss.selfAssessmentModel.hba1cLevel" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault6">I don't know</label>
            </div>


        </div>
    </div>
    <div class="p-2 flex-grow-1"></div>
    
    <div class="d-flex mb-2">
        <div class="me-auto p-2">
        <button class="btn btn-link" (click)="_prevButton()" type="submit">Prev</button>
        </div>
        <div class="p-2">
        <button class="btn btn-link" (click)="_nextButton()" type="submit">Skip</button>
        </div>
        <div class="p-2">
        <button class="btn btn-primary" (click)="_nextButton()" type="submit">Next</button>
        </div>
    </div>
</div>


<nav class="navbar navbar-top fixed-top navbar-expand" id="navbarDefault">
    <div class="collapse navbar-collapse justify-content-between">
        <div class="navbar-logo">
            <button class="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTopCollapse" aria-controls="navbarTopCollapse" aria-expanded="false" aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>
            <a class="navbar-brand flex-1 flex-lg-grow-0" routerLink="/dashboard">
              <div class="d-flex align-items-center"><img src="../../assets/img/icons/logo3.png" alt="phoenix" width="27" />
                <p class="logo-text ms-2">Novalumen</p>
              </div>
            </a>
            <!--<a class="navbar-brand me-1 me-sm-3" href="./dashboard">
                <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <p class="logo-text ms-2">Novalumen</p>
                    </div>
                </div>
            </a>-->
        </div>  
    </div> 
    
    <!-- start -->

    <!-- User Profile Element-->
    <ul class="navbar-nav navbar-nav-icons flex-row">   

        <!--
        <li class="nav-item dropdown">
          <div class="text-center pt-4 pb-3 me-2">
            <div class="badge badge-phoenix badge-phoenix-success p-1 ps-3 pe-3 ms-3 mb-1 fs--1">subscribe</div>
          </div>
        </li>
        -->


        <li class="nav-item dropdown">
            <a class="nav-link lh-1 pe-0" id="navbarDropdownUser" href="#!" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                <div class="avatar avatar-l ">
                   <img class="rounded-circle " src="{{ userModel?.photoURL }}" alt="" />
                </div>
             </a>
          <div class="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border border-300" aria-labelledby="navbarDropdownUser">
            <div class="card position-relative border-0">
              <div class="card-body p-0">
                                
                <div class="text-center pt-2 pb-2">                  
                  <div class="avatar avatar-xl ">
                    <img class="rounded-circle " src="{{ userModel?.photoURL }}" alt="" />
                  </div>
                  <h6 class="mt-2 text-black">{{ userModel?.displayName }}</h6> 
                  <div class="badge badge-phoenix badge-phoenix-success p-1 ps-3 pe-3 fs--2">{{ subscription?.productName }}</div>
                </div>                
                
              </div>
              <div class="overflow-auto scrollbar">
                <ul class="nav d-flex flex-column mb-2 pb-1">
                  <li class="nav-item"><a class="nav-link px-3" routerLink="/dashboard"><span class="me-2 text-900" data-feather="pie-chart"></span>Dashboard</a></li>
                  <li class="nav-item"><a class="nav-link px-3" routerLink="/profile"> <span class="me-2 text-900" data-feather="user"></span><span>My Profile</span></a></li>
                  <li class="nav-item"><a class="nav-link px-3" href="#!" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDailyRoutineConfig"> <span class="me-2 text-900" data-feather="help-circle"></span>Personalize Your Journey</a></li>                  
                  <li *ngIf="subscription && subscription.productName!=='Starter'" class="nav-item"><a class="nav-link px-3" href="#" (click)="manageSubscriptionClick($event)"> <span class="me-2 text-900" data-feather="user"></span><span>Manage Subscription</span></a></li>
                  <li *ngIf="subscription && subscription.productName==='Starter'" class="nav-item"><a class="nav-link px-3" routerLink="/pricing"> <span class="me-2 text-900" data-feather="help-circle"></span>Upgrade</a></li>                  
                  <!--
                  <li class="nav-item"><a class="nav-link px-3" href="#!"> <span class="me-2 text-900" data-feather="settings"></span>Settings &amp; Privacy </a></li>
                  <li class="nav-item"><a class="nav-link px-3" href="#!"> <span class="me-2 text-900" data-feather="help-circle"></span>Help Center</a></li>
                  -->
                  
                  <!--
                  <hr/>

                  <li class="nav-item"><a class="nav-link px-3" routerLink="/self-assessment/1"> <span class="me-2 text-900" data-feather="lock"></span> - Self Assessment</a></li>
                  <li class="nav-item"><a class="nav-link px-3" routerLink="/onboarding"> <span class="me-2 text-900" data-feather="globe"></span> - Onboarding</a></li>
                  <li class="nav-item"><a class="nav-link px-3" routerLink="/personal-journey"> <span class="me-2 text-900" data-feather="globe"></span> - Personal Journey</a></li>
                  <li class="nav-item"><a class="nav-link px-3" routerLink="/onboarding-final"> <span class="me-2 text-900" data-feather="globe"></span> - Onboarding Final</a></li>
                  <li class="nav-item"><a class="nav-link px-3" routerLink="/pricing"> <span class="me-2 text-900" data-feather="globe"></span> - Pricing</a></li>
                  -->

                </ul>
              </div>
              <div class="card-footer p-0 border-top">
                <!--<ul class="nav d-flex flex-column my-3">
                  <li class="nav-item"><a class="nav-link px-3" href="#!"> <span class="me-2 text-900" data-feather="user-plus"></span>Add another account</a></li>
                </ul>-->                
                <div class="px-3 mt-3"> <a class="btn btn-phoenix-primary d-flex flex-center w-100" href="#!" (click)="this.logout()"> <span class="me-2" data-feather="log-out"> </span>Sign out</a></div>
                <div class="my-2 text-center fw-bold fs--2 text-600"><a class="text-600 me-1" href="https://www.novalumen.com/privacy" target="_blank">Privacy Notice</a>&bull;<a class="text-600 mx-1" href="https://www.novalumen.com/terms" target="_blank">Terms of Service</a></div>
              </div>
            </div>
          </div>
        </li>
    </ul>

    <!-- Quick Sign Out button-->
    <ul class="navbar-nav navbar-nav-icons flex-row ms-3 me-2">        
        <!--<fa-icon [size]="'2x'" [icon]="faDoorOpen" class="custom-icon" /> -->
        <a class="" href="#!" title="Sign out" (click)="this.logout()">
            <fa-icon [size]="'xl'" [icon]="faArrowRightFromBracket" class="custom-icon"/> 
        </a>
    </ul>

    <!-- end -->

</nav>
import { BloodPressureModel } from "./blood.pressure.model";
import { BloodSugarModel } from "./blood.sugar.model";
import { JourneyConfig } from "./journey.config";

export class FirebaseUserModel {
    uid!: string;
    email!: string;
    emailVerified: string = '';
    displayName!: string;
    isAnonymous: boolean = false;
    photoURL: string = '';
    stsTokenManager!: {
      refreshToken: string;
      accessToken: string;
      expirationTime: number;
    };
    createdAt!: number;
    lastLoginAt!: number;
    apiKey!: string;
    appName!: string;
    
  }

  export class UserDataConfigModel {
    bloodSugarMeasureType: string;
    timezone: string;
    diabetesType: string;
    gender: string;
    birthYear: number | null;   
    rangeInDays: number = 7;

    constructor() {
      this.bloodSugarMeasureType = "";
      this.timezone = "";
      this.diabetesType = "";
      this.gender = "";
      this.birthYear = 0;
    }
  }

  export class UserModel {
    uid!: string;
    email!: string;
    emailAlternative!: string;
    displayName!: string;
    photoURL: string = '';

    creationDate: string = '';
    lastUpdatedDate: string = '';

    // subscription data
    subscriptionPlan: string = '';
    subscriptionStatus: string = '';
    onboardingStatus: string = '';
    active: boolean = true;

    dataConfig: UserDataConfigModel;
    journeyConfig: JourneyConfig;

    constructor() {
      this.dataConfig = new UserDataConfigModel();
      this.journeyConfig = new JourneyConfig();
    }
  }

  export class UserSelfAssessmentModel {
    uid: string = '';
    diabetesType: string = '';
    hba1cLevel: string = '';
    bloodGlucoseCheckFrequency: string = '';
    activityLevel: string = '';
    dietRestrictions: string = '';
    weightStatus: string = '';
    bloodSugarEpisodes: string = '';
    diabetesRelatedComplications: string = '';
    medications!: {
      insulin: boolean;
      metformin: boolean;
      sulfonylureas: boolean;
      dpp4: boolean;
      tzds: boolean;
      sglt2: boolean;
      glp1: boolean;
      others: boolean;
    };
    monitoringDevices!: {
      bloodGlucose: boolean;
      cgm: boolean;
      insulinPump: boolean;
      bloodPressure: boolean;
      pulseOximeter: boolean;
      weightScale: boolean;
      fitnessTracker: boolean;
    }

    creationDate?: {
      seconds: number;
      nanos: number;
    };

    lastUpdatedDate?: {
      seconds: number;
      nanos: number;
    };
    

  }
import { inject, Injectable, NgZone, OnDestroy, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { Observable, of, Subscription  } from 'rxjs';
import { switchMap } from 'rxjs/operators';

//import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Auth, GoogleAuthProvider, OAuthProvider, User, signInWithPopup, user } from '@angular/fire/auth';

import { FirebaseUserModel, UserModel } from '../model/user.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnInit, OnDestroy {

  private auth: Auth = inject(Auth);
  user$ = user(this.auth)
  userSubscription: Subscription;

  public idToken: string | undefined;
  
  //userData: any; // Save logged in user data

  public isLoggedInFlag: boolean;
  //public fbUser: User;
  //public userModel: UserModel | null = null;
  //public firebaseToken: string;

  public fbToken:any;

  constructor(
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning    
  ) {

    this.isLoggedInFlag = false;

    this.userSubscription = this.user$.subscribe((aUser: User | null) => {
      //handle user state changes here. Note, that user will be null if there is no currently logged in user.
     //console.log("authService.userSubscription: " + aUser);
     
     if(aUser) {
      
      // 1. Get Token
      this.isLoggedInFlag = true;
      aUser.getIdToken().then((token) => {
        if(token) {
          //console.log("authService.token: " + token);
          this.idToken = token;
        }
      });
      
     }
    })

    
    
  }


  ngOnInit(): void {}

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  reportSuccessfulLogin(user: User) {    
      
      //localStorage.setItem('user', JSON.stringify(user));    
  }

  
  

  // correct isAuthenticated implementation
  get isAuthenticated(): boolean {
    //return this.afAuth.currentUser !== null;
    return false;
  }

  get getAuthenticatedUser() {
  
    const fbUser = this.auth.currentUser;
    //console.log("fbUser: " + fbUser)
    return fbUser;
    
  }

  get getUserFromLocalStorage2(): string | null {
    const userValue = localStorage.getItem("user");
    //const userObj:FirebaseUserModel = JSON.parse(userValue);
    return userValue;
  }

  /*
  get getCachedUser(): FirebaseUserModel | null {
    //const userValue = localStorage.getItem("user");
    if(userValue) {
      const userObj:FirebaseUserModel = JSON.parse(userValue);
      return userObj;
    }
    else {
      return null;
    }
  }
  */

  
  
  // Sign out
  SignOut() {
    return this.auth.signOut().then(() => {      
      this.router.navigate(['sign-in']);
    });
  }

  // Sign out #2
  SignOut2() {
    return this.auth.signOut().then(() => {
      
      
    });
  }

  /*
  async getIdTokenMethod() {
  
    //console.log("2323");
    let value = await this.auth.currentUser.then((result) => {
      return result
    });
    //return value?.getIdToken;
  }
  */
   
}
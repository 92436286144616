import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ECharts, EChartsOption } from 'echarts';
import { SharedEventService } from 'src/app/shared/services/shared.event.service';
import { InsulinFormComponent } from './insulin-form/insulin-form.component';
import { InsulinService } from 'src/app/shared/services/insulin.service';
import { InsulinModel } from 'src/app/shared/model/insulin.model';
import { UserModel } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/services/user.service';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DashboardSharedService } from 'src/app/shared/services/dashboard.shared.service';

@Component({
  selector: 'app-insulin',
  templateUrl: './insulin.component.html',
  styleUrls: ['./insulin.component.css']
})
export class InsulinComponent implements OnInit {

  @ViewChild('addInsulinButton') addInsulinButton: ElementRef | any;

  faPlus = faPlus;

  @Input() public userModel: UserModel | undefined; 
  //userModel: UserModel = new UserModel();

  chartOption: EChartsOption = {};
  echartsInstance: ECharts | undefined;

  isInsulinDataEmpty = false;

  chartData: any[] = [];
  dataRapid: any[] = [];
  dataShort: any[] = [];
  dataIntermediate: any[] = [];
  dataLong: any[] = [];

  constructor(
    private insulinService: InsulinService,
    private eventService: SharedEventService,    
    private userService: UserService,
    public dashboardSharedService: DashboardSharedService,
  ) {
    this.chartOption = {};    
  }
  
  ngOnInit(): void {

    // subscribe to userModel
    this.userService.getProfile().subscribe({
      next: (value) => {
        if(value) {
          this.userModel = value;    
          
          // load initial insulin data
          const days = this.userModel.dataConfig.rangeInDays; 
          this.dashboardSharedService.getInsulinData(days).subscribe({
            next: (value) => {
              if(value) {
                this.chartData = value;
                this.echartsInstance?.hideLoading();
                //this.updateChartData_old();
                this.updateChartData(value);   
                
                if(value.length === 0) this.isInsulinDataEmpty = true;
                else this.isInsulinDataEmpty = false;
              }
            }
          });

        }
      },
    });
    
    // subscribe to events from Shared Event Service
    this.eventService.messageEmitter.subscribe((message: string) => {      
      this.eventListener(message);
    });
    
  }

  eventListener(message: string) {

    console.log('eventListener: ' + message) 
    
    if(message === this.eventService.INSULIN_DATA_CHANGE) {   
       // event to reload a blood sugar values in this widget
      console.log('got new blood sugar data') 
      this.reloadChartData();
    }

  }

  reloadChartData() {

    if(this.userModel) {
      const rangeInDays = this.userModel.dataConfig.rangeInDays;    
      this.dashboardSharedService.loadInsulinData(rangeInDays);
    }

  }

  configLeversChange() {

    if(this.userModel) {

      // save user profile data
      this.userService.saveProfile(this.userModel).subscribe({
        next: (value) => {
          //console.log("saved")
        },
      });

      // refresh chart
      this.updateChartData(this.chartData);
    }
  }

  onChartInit(ec: any) {
    this.echartsInstance = ec;
    //this.echartsInstance?.hideLoading();
  }


  disableInsulinWidget() {
    
    if(this.userModel) {
      const model: UserModel = this.userModel;
      model.journeyConfig.coreInsulin = false;

      this.userService.saveProfile(model).subscribe({
        next:(value) => {
          // nothing 
        }
      });
    }
  }


  updateChartData(value: InsulinModel[]) {

    if(this.userModel) {

      // Rapid-acting
      this.dataRapid = value.filter((item: InsulinModel) => {
        return (item.insulinType === 'Rapid-acting');
      }).map(item => [item.creationDate, item.dosageUnits]); 

      // Short-acting
      this.dataShort = value.filter((item: InsulinModel) => {
        return (item.insulinType === 'Short-acting');
      }).map(item => [item.creationDate, item.dosageUnits]); 

      // Intermediate-acting
      this.dataIntermediate = value.filter((item: InsulinModel) => {
        return (item.insulinType === 'Intermediate-acting');
      }).map(item => [item.creationDate, item.dosageUnits]); 

      // Long-acting
      this.dataLong = value.filter((item: InsulinModel) => {
        return (item.insulinType === 'Long-acting');
      }).map(item => [item.creationDate, item.dosageUnits]); 

    

      const myChartOption: EChartsOption = {

        legend: {
          show: false,
          selected: {
            'Rapid-acting': this.userModel.journeyConfig.showRapidActing,          
            'Short-acting': this.userModel.journeyConfig.showShortActing,
            'Intermediate-acting': this.userModel.journeyConfig.showIntermediateActing,
            'Long-acting': this.userModel.journeyConfig.showLongActing,
            
          },
        },
        grid: {
          left: '10px',   // Adjust left padding
          right: '10px',  // Adjust right padding
          top: '10px',    // Adjust top padding
          bottom: '10px', // Adjust bottom padding
          containLabel: true
        },
        xAxis: {
          type: 'time', // Set X-axis type to 'time'
          axisPointer: {
            type: 'line'
          }      
          //boundaryGap: false, // Adjust boundaryGap based on your preference.
        },
        yAxis: {
          type: 'value',          
          axisPointer: {
            type: 'line'
          },
          axisLabel: {
            formatter: '{value} units',
          }  
        },
        series: [
          {
            name: 'Rapid-acting',
            data: this.dataRapid,
            type: 'scatter', // or other chart type,  
            //type: 'line', // or other chart type,  
            //smooth: true,
            symbol: 'circle', 
            symbolSize: 10,
            itemStyle: {
              color: '#d00505',
            },      
          }, 
          {
            name: 'Short-acting',
            data: this.dataShort,
            type: 'scatter', // or other chart type,  
            //type: 'line', // or other chart type,  
            //smooth: true,
            symbol: 'circle', 
            symbolSize: 10,
            itemStyle: {
              color: '#d06405',
            },      
          }, 
          {
            name: 'Intermediate-acting',
            data: this.dataIntermediate,
            type: 'scatter', // or other chart type,  
            //type: 'line', // or other chart type,  
            //smooth: true,
            symbol: 'circle', 
            symbolSize: 10,
            itemStyle: {
              color: '#007bff',
            },      
          },         
          {
            name: 'Long-acting',
            data: this.dataLong,                    
            type: 'scatter', // line or other chart type,  
            //smooth: true,
            symbol: 'circle', 
            symbolSize: 10,
            itemStyle: {
              color: '#3ab14a',          
            },         
          },  
        ],
        tooltip: {
          show: true,        
          trigger: 'axis', // Display tooltips on hover over data points
          axisPointer: {
            type: 'line',           
          },
          //valueFormatter: value => value.toFixed(2) + ' ml',
          valueFormatter: (value:any) => value + ' mg/dL',
          //formatter: '{HH}:{mm}<br> {MMM} {dd}, {yyyy}<br> {c}'
        },      
      }; 

      this.chartOption = myChartOption;
    }

  }

}

export const environment = {
    production: false,

    //apiServerURL: 'https://api.dev.novalumen.com',
    apiServerURL: 'http://localhost:8080',
    
    firebase: {
        apiKey: "AIzaSyBqXqSjjBC4KMYQyhJXG-TVNzywyrvb7as",
        authDomain: "nonprod1-svc-wn8g-f56a9.firebaseapp.com",
        projectId: "nonprod1-svc-wn8g-f56a9",
        storageBucket: "nonprod1-svc-wn8g-f56a9.appspot.com",
        messagingSenderId: "134545604138",
        appId: "1:134545604138:web:617e738f05310021b18d53",
        measurementId: "G-623K9MKDGL"
    }
  };
import { Component, OnInit } from '@angular/core';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { Block1Component } from './components/block1/block1.component';
import { AuthService } from './shared/services/auth.service';
import { User } from 'firebase/auth';
import { FirebaseUserModel } from './shared/model/user.model';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public fbUser: any;
  public fbToken: any;
  
  constructor(
    public router: Router,
    public authService: AuthService,
    private gaService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.subscribeToRouterEvents();
  }

  private subscribeToRouterEvents(): void {
    
    this.router.events.subscribe((event) => {      

      if (event instanceof NavigationEnd) {
        // Navigation has successfully ended
        this.gaService.pageView("pv " + event.url); 
        this.gaService.event( event.url);         
      }

    });
  }
 
}

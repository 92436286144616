import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BloodSugarModel } from "../model/blood.sugar.model";
import { InsulinModel } from "../model/insulin.model";
import { BloodSugarService } from "./blood.sugar.service";
import { InsulinService } from "./insulin.service";
import { VitalsSummaryModel } from "../model/vitals.summary.model";

@Injectable({
    providedIn: 'root',
})
export class DashboardSharedService {

    vitals: VitalsSummaryModel = new VitalsSummaryModel();

    private bloodSugarSubject = new BehaviorSubject<BloodSugarModel[] | null>(null);    
    private bloodSugarData$: Observable<BloodSugarModel[] | null> = this.bloodSugarSubject.asObservable();

    private insulinSubject = new BehaviorSubject<InsulinModel[] | null>(null);    
    private insulinData$: Observable<InsulinModel[] | null> = this.insulinSubject.asObservable();

    private isBloodSugarDataLoading: boolean = false;
    private isInsulinDataLoading: boolean = false;

    public isBloodSugarDataReady: boolean = false;
    public isInsulinDataReady: boolean = false;

    public isBloodSugarDataEmpty: boolean = false;
    public isInsulinDataEmpty: boolean = false;

    private daysBloodSugar: number = 7;
    private daysInsulin: number = 7;

    constructor(
        private bloodSugarService: BloodSugarService,
        private insulinService: InsulinService,
    ) {}

    getBloodSugarData(days: number): Observable<BloodSugarModel[] | null> {        
        
        let needToReload = false;
        if(days !== this.daysBloodSugar 
            || !this.isBloodSugarDataReady) {
            needToReload = true;
        }

        //console.log("needToReload: " + needToReload)
        if(needToReload) {
            this.loadBloodSugarData(days);
        }

        return this.bloodSugarData$;
    }

    getInsulinData(days: number): Observable<InsulinModel[] | null> {        
        
        let needToReload = false;
        if(days !== this.daysInsulin
            || !this.isInsulinDataReady ) {
            needToReload = true;
        }

        //console.log("needToReload: " + needToReload)
        if(needToReload) {
            this.loadInsulinData(days);
        }

        return this.insulinData$;
    }


    loadBloodSugarData(days: number) {

        if(!this.isBloodSugarDataLoading) {

            this.isBloodSugarDataLoading = true;
            this.daysBloodSugar = days;
            this.bloodSugarService.getForLastDays(days).subscribe({
                next: (value) => {
                    if(value) {
                        this.bloodSugarSubject.next(value);
                        this.isBloodSugarDataLoading = false;
                        this.isBloodSugarDataReady = true;
                        this.recalculateBloodSugarVitals(value);

                        if(value.length !== 0) this.isBloodSugarDataEmpty = false;
                        else this.isBloodSugarDataEmpty = true;
                    }                     
                },
                error: (err) => {
                    console.log("err: " + err);
                    this.isBloodSugarDataLoading = false;
                },
            });
        }
        else {
            // data is already loading - do nothing
        }
    }

    loadInsulinData(days: number) {

        if(!this.isInsulinDataLoading) {

            this.isInsulinDataLoading = true;
            this.daysInsulin = days;
            this.insulinService.getForLastDays(days).subscribe({
                next: (value) => {
                    if(value) {
                        this.insulinSubject.next(value);
                        this.isInsulinDataLoading = false;
                        this.isInsulinDataReady = true;
                        this.recalculateInsulinVitals(value);

                        if(value.length !== 0) this.isInsulinDataEmpty = false;
                        else this.isInsulinDataEmpty = true;
                    }                     
                },
                error: (err) => {
                    console.log("err: " + err);
                    this.isInsulinDataLoading = false;
                },
            });
        }
        else {
            // data is already loading - do nothing
        }
    }


    private recalculateBloodSugarVitals(value: BloodSugarModel[]) {

        this.vitals.fastingBloodSugar = 
            this.recalculateBloodSugarAverageByMealTag(value, "Fasting Blood Sugar");
        
        this.vitals.beforeMealsBloodSugar = 
            this.recalculateBloodSugarAverageByMealTag(value, "Before Meals (Preprandial)");

        this.vitals.postMealsBloodSugar = 
            this.recalculateBloodSugarAverageByMealTag(value, "Post Meals (Postprandial)");

        
    }

    private recalculateInsulinVitals(value: InsulinModel[]) {

        this.vitals.insulinTotalDailyDose = 
            this.recalculateInsulinDoseAverage(value);
    }

    private recalculateBloodSugarAverageByMealTag(value: BloodSugarModel[], mealTag: string): string {
        
        const fastingArray = value.filter(item => item.mealTag === mealTag)        
        const sumValue = fastingArray.reduce((sum, item) => {
            let mgValue = 0;
            if(item.mgValue) 
                mgValue = item.mgValue
            return sum + mgValue}
            , 0);
        if(fastingArray.length > 0) {
            const fastingAverage = sumValue / fastingArray.length;
            return "" + Math.ceil(fastingAverage) + "mg/dL"
        }
        else {
            return "-"
        }
    }

    private recalculateInsulinDoseAverage(value: InsulinModel[]): string {
        
        const filteredArray = value.filter(item => (item.dosageUnits))        
        const sumValue = filteredArray.reduce((sum, item) => {
            let dose = 0;
            if(item.dosageUnits) 
            dose = item.dosageUnits
            return sum + dose}
            , 0);
        if(filteredArray.length > 0) {
            const averageValue = sumValue / filteredArray.length;
            return "" + Math.ceil(averageValue) + " units"
        }
        else {
            return "-"
        }
    }



}
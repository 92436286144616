<div class="d-flex w-100 flex-column justify-content-center">

    <div class="row g-0">
        <div class="container2">
            <div class="box-left">
                <div class="card p-4 h-100">

                    <div class="d-flex me-3">
                        <div class="">
                            <h4>Health Metrics</h4>                            
                        </div>
                        <div class="ms-auto">                            
                            <button #dailyRoutineConfigButton class="btn btn-sm btn-phoenix-secondary btn-small" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDailyRoutineConfig"><fa-icon [icon]="faSliders" /></button>
                        </div>
                    </div>  

                    <div class="w-100">
                        <div class="me-3 mt-2">                                            
                            <h6 class="">Progress: {{ dailyRoutineProgress }}%</h6>
                            <div class="progress" style="height:8px">                            
                                <div class="progress-bar bg-success" role="progressbar" style="width: {{ dailyRoutineProgress }}%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="mt-2 d-flex flex-wrap">                        
                        
                        <!--
                        <div *ngIf="userModel.journeyConfig.fastingBloodSugar" class="flex-fill flex-1 mt-2 me-3">                            
                            <div class="daily-question">Fasting Blood Sugar (mg/dl):</div>
                            <div>     
                                <div class="input-group input-group-sm">                                    
                                    <input [(ngModel)]="this.dailyRoutineModel.fastingBloodSugar" class="form-control" type="text" placeholder="TODO" readonly (click)="addFastingBloodSugar()"/>                                    
                                </div>
                            </div>
                        </div>                        
                        -->

                        <div *ngIf="userModel?.journeyConfig?.hmBloodPressure" class="flex-fill flex-1 mt-2 me-3">
                            <!--<fa-icon [icon]="faAngleRight" />-->
                            <div class="daily-question">Blood Pressure (mmHg):</div>
                            <div>     
                                <div class="input-group input-group-sm">
                                    <input #myBloodPressureField [(ngModel)]="this.dailyRoutineModel.bloodPressure" class="form-control" type="text" placeholder="120/80" (change)="changeBloodPressure()"/>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="userModel?.journeyConfig?.hmMedications" class="flex-fill flex-1 mt-2 me-3">
                            <!--<fa-icon [icon]="faAngleRight" />-->
                            <div class="daily-question">did you miss any medications today?</div>
                            <div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic example">
                                <button #buttonMedications1 class="btn btn-phoenix-secondary" type="button" name="medications" (click)="buttonMedicationsClick('No')">No</button>                              
                                <button #buttonMedications2 class="btn btn-phoenix-secondary" type="button" name="medications" (click)="buttonMedicationsClick('One')">One</button>  
                                <button #buttonMedications3 class="btn btn-phoenix-secondary" type="button" name="medications" (click)="buttonMedicationsClick('Multiple')">Multiple</button>                                                                                                                                                                                                                      
                            </div>                                                      
                        </div> 

                        <div *ngIf="userModel?.journeyConfig?.hmFootCare" class="flex-fill flex-1 mt-2 me-3">
                            <!--<fa-icon [icon]="faAngleRight" />-->
                            <div class="daily-question">Foot Care:</div>
                            <div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic example">
                                <button #buttonFootCareGood class="btn btn-phoenix-secondary" type="button" (click)="buttonFootCareClick('Good')">Good</button>                              
                                <button #buttonFootCareAverage class="btn btn-phoenix-secondary" type="button" (click)="buttonFootCareClick('Average')">Average</button>                                                              
                                <button #buttonFootCareNeedsAttention class="btn btn-phoenix-secondary" type="button" (click)="buttonFootCareClick('Needs Attention')">Needs Attention</button>                                                              
                            </div>
                        </div>

                        <div *ngIf="userModel?.journeyConfig?.hmSymptomaticEpisodes" class="flex-fill flex-1 mt-2 me-3">
                            <!--<fa-icon [icon]="faAngleRight" />-->
                            <div class="daily-question">Symptomatic Episodes:</div>
                            <div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic example">
                                <button #buttonSymptomaticEpisodes1 class="btn btn-phoenix-secondary" type="button" (click)="buttonSymptomaticEpisodesClick('No')">No</button>                              
                                <button #buttonSymptomaticEpisodes2 class="btn btn-phoenix-secondary" type="button" (click)="buttonSymptomaticEpisodesClick('Mild')">Mild</button>                                                              
                                <button #buttonSymptomaticEpisodes3 class="btn btn-phoenix-secondary" type="button" (click)="buttonSymptomaticEpisodesClick('Severe')">Severe</button>                                                              
                            </div>
                        </div>
 
                        <div *ngIf="userModel?.journeyConfig?.hmStressLevel" class="flex-fill flex-1 mt-2 me-3">
                            <!--<fa-icon [icon]="faAngleRight" />-->
                            <div class="daily-question">Stress Level:</div>
                            <div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic example">
                                <button #buttonStressLevel1 class="btn btn-phoenix-secondary" type="button" (click)="buttonStressLevelClick('Low')">Low</button>                              
                                <button #buttonStressLevel2 class="btn btn-phoenix-secondary" type="button" (click)="buttonStressLevelClick('Moderate')">Moderate</button>                                                              
                                <button #buttonStressLevel3 class="btn btn-phoenix-secondary" type="button" (click)="buttonStressLevelClick('High')">High</button>                                                              
                            </div>
                        </div>

                        <div *ngIf="userModel?.journeyConfig?.hmSleepQuality" class="flex-fill flex-1 mt-2 me-3">
                            <!--<fa-icon [icon]="faAngleRight" />-->
                            <div class="daily-question">Sleep Quality</div>
                            <div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic example">
                                <button #buttonSleepQuality1 class="btn btn-phoenix-secondary" type="button" (click)="buttonSleepQualityClick('Excellent')">Excellent</button>                              
                                <button #buttonSleepQuality2 class="btn btn-phoenix-secondary" type="button" (click)="buttonSleepQualityClick('Regular')">Regular</button>                                                              
                                <button #buttonSleepQuality3 class="btn btn-phoenix-secondary" type="button" (click)="buttonSleepQualityClick('Subpar')">Subpar</button>                                                              
                            </div>                            
                        </div>  
                    </div>

                </div>
            </div>
            <div class="box-right">
                <div class="card p-4 h-100">                    
                    <div class="d-flex me-3">
                        <div class="">
                            <h4>Lifestyle Insights</h4>                            
                        </div>
                        <div class="ms-auto">                            
                            <button #dailyRoutineConfigButton class="btn btn-sm btn-phoenix-secondary btn-small" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDailyRoutineConfig"><fa-icon [icon]="faSliders" /></button>                                                                                                                  
                        </div>
                    </div> 

                    <div class="w-100">
                        <div class="me-3 mt-2">                                            
                            <h6 class="">Progress: {{ dietAndNutritionProgress }}%</h6>
                            <div class="progress" style="height:8px">                            
                                <div class="progress-bar bg-success" role="progressbar" style="width: {{ dietAndNutritionProgress }}%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="mt-2 d-flex flex-wrap">   
                        
                        <div *ngIf="userModel?.journeyConfig?.liWeight" class="flex-fill flex-1 mt-2 me-3">
                            <!--<fa-icon [icon]="faAngleRight" />-->
                            <div class="daily-question">What is your weight today? (Lbs)</div>                             
                            <div class="input-group input-group-sm">
                                <input #myWeightField [(ngModel)]="this.dailyRoutineModel.weight" class="form-control" type="text" placeholder="150" (change)="changeWeight()"/>
                            </div>                            
                        </div> 

                        <div *ngIf="userModel?.journeyConfig?.liCalories" class="flex-fill flex-1 mt-2 me-3">
                            <!--<fa-icon [icon]="faAngleRight" />-->
                            <div class="daily-question">total calories you ate today:</div>                                                        
                            <div class="input-group input-group-sm">
                                <input #myCaloriesField [(ngModel)]="this.dailyRoutineModel.calories" class="form-control" type="text" placeholder="2000" (change)="changeCalories()"/>
                            </div>                            
                        </div>

                        <div *ngIf="userModel?.journeyConfig?.liCarbs" class="flex-fill flex-1 mt-2 me-3">
                            <!--<fa-icon [icon]="faAngleRight" />-->
                            <div class="daily-question">How many grams carbs did you have?</div>                                                        
                            <div class="input-group input-group-sm">
                                <input #myCarbsField [(ngModel)]="this.dailyRoutineModel.carbs" class="form-control" type="text" placeholder="100" (change)="changeCarbs()"/>
                            </div>                            
                        </div> 

                        <div *ngIf="userModel?.journeyConfig?.liHealthySnacks" class="flex-fill flex-1 mt-2 me-3">
                            <!--<fa-icon [icon]="faAngleRight" />-->
                            <div class="daily-question">Did you have only healthy snacks today?</div>                            
                            <div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic example">
                                <button #buttonHealthySnacks1 class="btn btn-phoenix-secondary" type="button" (click)="buttonHealthySnacksClick('No')">No</button>                              
                                <button #buttonHealthySnacks2 class="btn btn-phoenix-secondary" type="button" (click)="buttonHealthySnacksClick('Yes')">Yes</button>                                                              
                            </div>
                        </div>

                        <div *ngIf="userModel?.journeyConfig?.liAlcohol" class="flex-fill flex-1 mt-2 me-3">
                            <!--<fa-icon [icon]="faAngleRight" />-->
                            <div class="daily-question">Did you drink alcohol today?</div>                            
                            <div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic example">
                                <button #buttonAlcohol1 class="btn btn-phoenix-secondary" type="button" (click)="buttonAlcoholClick('No')">No</button>                              
                                <button #buttonAlcohol2 class="btn btn-phoenix-secondary" type="button" (click)="buttonAlcoholClick('Yes')">Yes</button>                                                              
                            </div>
                        </div>

                        <div *ngIf="userModel?.journeyConfig?.liIntermittentFasting" class="flex-fill flex-1 mt-2 me-3">
                            <!--<fa-icon [icon]="faAngleRight" />-->
                            <div class="daily-question">Intermittent Fasting duration in hours:</div>
                            <div>   
                                <div class="input-group input-group-sm">
                                    <input #myIntermittentFastingField [(ngModel)]="this.dailyRoutineModel.intermittentFasting" class="form-control" id="weightInput" type="text" placeholder="12" (change)="changeIntermittentFasting()"/>
                                </div>                             
                            </div>
                        </div>

                        <div *ngIf="userModel?.journeyConfig?.liExcercise" class="flex-fill flex-1 mt-2 me-3">
                            <!--<fa-icon [icon]="faAngleRight" />-->
                            <div class="daily-question">Did you excercise today?</div>                            
                            <div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic example">
                                <button #buttonExcercise1 class="btn btn-phoenix-secondary" type="button" (click)="buttonExcerciseClick('No')">No</button>                              
                                <button #buttonExcercise2 class="btn btn-phoenix-secondary" type="button" (click)="buttonExcerciseClick('Yes')">Yes</button>                                                              
                            </div>
                        </div>   

                    </div>

                </div>
            </div>
        </div>
    </div>

</div>
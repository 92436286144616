<div class="d-flex min-vh-75 w-100 flex-column justify-content-center">
    <div class="">                    
        <p>Question: 8 of 10</p>
        <div class="progress" style="height:8px">
        <div class="progress-bar" role="progressbar" style="width: 80%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
    <div class="flex-grow-1 "></div>
    <div class="d-flex d-flex justify-content-center">

        <div class="assessment-block">
            
            <p class="question-header">Do you frequently experience low or high blood sugar episodes?</p>

            <div class="form-check">
                <input class="btn-check" id="flexRadioDefault1" type="radio" name="flexRadioDefault" value="No" [(ngModel)]="ss.selfAssessmentModel.bloodSugarEpisodes" (change)="onChange()"/>        
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault1">No, I rarely experience either</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault2" type="radio" name="flexRadioDefault" value="Hypoglycemia" [(ngModel)]="ss.selfAssessmentModel.bloodSugarEpisodes" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault2">I experience Hypoglycemia(low blood sugar) infrequently</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault3" type="radio" name="flexRadioDefault" value="Hyperglycemia" [(ngModel)]="ss.selfAssessmentModel.bloodSugarEpisodes" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault3">I experience hyperglycemia (high blood sugar) infrequently</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault4" type="radio" name="flexRadioDefault" value="Both" [(ngModel)]="ss.selfAssessmentModel.bloodSugarEpisodes" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault4">I get both low and high blood sugar episodes from time to time</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault5" type="radio" name="flexRadioDefault" value="Varies on condition" [(ngModel)]="ss.selfAssessmentModel.bloodSugarEpisodes" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault5">Varies depending on illness, stress and other conditions</label>
            </div>

        </div>
    </div>
    <div class="p-2 flex-grow-1"></div>
    
    <div class="d-flex mb-2">
        <div class="me-auto p-2">
        <button class="btn btn-link" (click)="_prevButton()" type="submit">Prev</button>
        </div>
        <div class="p-2">
        <button class="btn btn-link" (click)="_nextButton()" type="submit">Skip</button>
        </div>
        <div class="p-2">
        <button class="btn btn-primary" (click)="_nextButton()" type="submit">Next</button>
        </div>
    </div>
</div>

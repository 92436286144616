<div #demoModal class="modal" id="verticallyCentered" tabindex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div>      
      <!--<button class="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><span class="fas fa-times fs--1"></span></button>-->
    </div>
    <div class="modal-content">

      <div class="text-center mt-6 mb-6 mx-auto"><img class="mb-7 d-dark-none" style="width:200px" src="../../../assets/img/spot-illustrations/38.webp" alt="phoenix" /><img class="mb-7 d-light-none" src="../../../assets/img/spot-illustrations/dark_1.png" alt="phoenix" />
        <div class="mb-3">
                              
          <div *ngIf="loginInProgress">
            <h4 class="text-1000">One more moment. Loading your profile...</h4>
            <div class="spinner-border text-info mt-4" role="status">
              <span class="visually-hidden">Loading...</span>            
            </div>
          </div>
          
          <!--<p class="text-700">Thanks for using Phoenix. <br class="d-lg-none" />You are now successfully signed out.</p>-->
        
          <div *ngIf="!this.authService.isLoggedInFlag && !loginInProgress">

            <h4 class="text-1000 mb-5">Get Started by Signing In</h4>            

            <button (click)="clickSignInWithFacebook()" class="btn btn-phoenix-secondary w-100 mb-3 p-0 d-flex align-items-center  justify-content-center" style="height:36px"><fa-icon [icon]="faFacebook" class="text-primary me-3 fs--1" [size]="'xl'"></fa-icon>Sign In with Facebook</button>
            <button (click)="clickSignInWithGoogle()" class="btn btn-phoenix-secondary w-100 mb-3 p-0 d-flex align-items-center  justify-content-center " style="height:36px"><img class="me-2" style="width:36px" src="../../../assets/img/icons/google-store.png" />Sign In with Google</button>
            <button (click)="clickSignInWithMicrosoft()" class="btn btn-phoenix-secondary w-100 mb-3 p-0 d-flex align-items-center  justify-content-center " style="height:36px"><img class="me-3" style="width:20px" src="../../../assets/img/icons/microsoft.png" />Sign In with Microsoft</button>            


            <firebase-ui
              (signInSuccessWithAuthResult)="successCallback($event)"
              (signInFailure)="errorCallback($event)"
              (uiShown)="uiShownCallback()"
            ></firebase-ui>    
            <div class="fs--1 fw-semi-bold mt-3"> 
              By signing in, you agree to our <br><a href="/terms" target="_blank">Terms of Service</a> and <a href="/privacy" target="_blank">Privacy Notice</a>.
            </div>
          </div>
          <div *ngIf="authService.isLoggedInFlag && !loginInProgress">
            <div class="mb-7">
              <h5>You are already signed in as "{{ fbUser?.email }}"</h5>
            </div>
            <a href="/dashboard" class="btn btn btn-primary me-1 mb-1" type="button">Go to Dashboard</a>
          </div>          
        </div>
      </div>


      <!--
      <div class="modal-footer">
        <button class="btn btn-primary" type="button">Okay</button>
        <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
      </div>
      -->
    </div>
  </div>
</div>

    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="alternate-landing" style="--phoenix-scroll-margin-top: 1.2rem;">
        <div class="bg-white sticky-top landing-navbar" data-navbar-shadow-on-scroll="data-navbar-shadow-on-scroll">
          <nav class="navbar navbar-expand-lg container-small px-3 px-lg-7 px-xxl-3">
            <a class="navbar-brand flex-1 flex-lg-grow-0" routerLink="/">
              <div class="d-flex align-items-center"><img src="../../assets/img/icons/logo3.png" alt="phoenix" width="27" />
                <p class="logo-text ms-2">Novalumen</p>
              </div>
            </a>
            <div class="d-lg-none">
              <div class="theme-control-toggle fa-icon-wait px-2">
                <input class="form-check-input ms-0 theme-control-toggle-input" type="checkbox" data-theme-control="phoenixTheme" value="dark" id="themeControlToggleSm" />
                <label class="mb-0 theme-control-toggle-label theme-control-toggle-light" for="themeControlToggleSm" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch theme"><span class="icon" data-feather="moon"></span></label>
                <label class="mb-0 theme-control-toggle-label theme-control-toggle-dark" for="themeControlToggleSm" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch theme"><span class="icon" data-feather="sun"></span></label>
              </div>
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <div class="border-bottom border-bottom-lg-0 mb-2">
                <div class="search-box d-inline d-lg-none">
                  <form class="position-relative" data-bs-toggle="search" data-bs-display="static">
                    <input class="form-control search-input search rounded-pill my-4" type="search" placeholder="Search" aria-label="Search" />
                    <span class="fas fa-search search-box-icon"></span>
  
                  </form>
                </div>
              </div>
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item border-bottom border-bottom-lg-0"><a class="nav-link lh-1 py-0 fs--1 fw-bold py-3 active" aria-current="page" href="#">Home</a></li>
                <!--<li class="nav-item border-bottom border-bottom-lg-0"><a class="nav-link lh-1 py-0 fs--1 fw-bold py-3" href="/#mission">Mission</a></li>-->
                <li class="nav-item border-bottom border-bottom-lg-0"><a class="nav-link lh-1 py-0 fs--1 fw-bold py-3" href="/#feature">Features</a></li>                
                <li class="nav-item border-bottom border-bottom-lg-0"><a class="nav-link lh-1 py-0 fs--1 fw-bold py-3" href="/#pricing">Pricing</a></li>
                <li class="nav-item border-bottom border-bottom-lg-0"><a class="nav-link lh-1 py-0 fs--1 fw-bold py-3" href="/#contacts">Contacts</a></li>
                <li class="nav-item border-bottom border-bottom-lg-0"><a class="nav-link lh-1 py-0 fs--1 fw-bold py-3" href="/terms">Terms</a></li>
                <li class="nav-item border-bottom border-bottom-lg-0"><a class="nav-link lh-1 py-0 fs--1 fw-bold py-3" href="/privacy">Privacy</a></li>  
                <li class="nav-item border-bottom border-bottom-lg-0"><a class="nav-link lh-1 py-0 fs--1 fw-bold py-3" href="https://novalumen.zendesk.com/hc/en-us" target="_blank">Help Center</a></li>                              
              </ul>
              <div class="d-grid d-lg-flex align-items-center">
                <div class="nav-item d-flex align-items-center d-none d-lg-block pe-2">
                  <div class="theme-control-toggle fa-icon-wait px-2">
                    <input class="form-check-input ms-0 theme-control-toggle-input" type="checkbox" data-theme-control="phoenixTheme" value="dark" id="themeControlToggle" />
                    <label class="mb-0 theme-control-toggle-label theme-control-toggle-light" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch theme"><span class="icon" data-feather="moon"></span></label>
                    <label class="mb-0 theme-control-toggle-label theme-control-toggle-dark" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch theme"><span class="icon" data-feather="sun"></span></label>
                  </div>
                </div>
                <!--
                <a class="text-700 hover-text-1100 px-2 d-none d-lg-inline lh-sm" href="#" data-bs-toggle="modal" data-bs-target="#searchBoxModal"><span data-feather="search" style="height: 20px; width: 20px;"></span></a><a class="btn btn-phoenix-primary text-900 order-1 order-lg-0 ps-4 me-lg-2" routerLink="/login">Sign in</a>
                -->
                
                <a *ngIf="!this.authService.isLoggedInFlag" class="btn btn-phoenix-primary" type="button" data-bs-toggle="modal" data-bs-target="#verticallyCentered">Sign In</a>
                <a *ngIf="this.authService.isLoggedInFlag" class="btn btn-phoenix-primary" type="button" href="/dashboard" >My Dashboard</a>
                

              </div>
            </div>
          </nav>
        </div>
       
        <section class="pb-8 overflow-hidden" id="home">
          <div class="hero-header-container-alternate position-relative">
            <div class="container-small px-lg-7 px-xxl-3">
              <div class="row align-items-center">
                <div class="col-12 col-lg-6 pt-8 pb-6 position-relative z-index-5 text-center text-lg-start">
                  <h1 class="fs-5 fs-md-6 fs-xl-7 fw-black mb-4"><span class="text-gradient-info me-3">Diabetes Journal</span> Reimagined</h1>
                  <p class="mb-5 pe-xl-10 fs-1 fw-medium">Make life simpler for people dealing with diabetes!</p>
                  
                  <a class="btn btn-lg btn-primary rounded-pill me-3" data-bs-toggle="modal" data-bs-target="#verticallyCentered" role="button">Get Started</a>
                  <!--<a class="btn btn-link me-2 fs-0 p-0" href="#!" role="button">Check Demo<span class="fa-solid fa-angle-right ms-2 fs--1"></span></a>-->

                </div>
                <div class="col-12 col-lg-auto d-none d-lg-block">
                  <div class="hero-image-container position-absolute h-100 end-0 d-flex align-items-center">
                    <div class="position-relative">
                      <div class="position-absolute end-0 hero-image-container-overlay" style="transform: skewY(-8deg)"></div><img class="position-absolute end-0 hero-image-container-bg" src="../../assets/img/bg/bg-36.png" alt="" /><img class="w-100 d-dark-none rounded-2 hero-image-shadow" src="../../assets/img/bg/dashboard-bg-4.png" alt="hero-header" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-small px-md-8 mb-8 d-lg-none">
              <div class="position-relative">
                <div class="position-absolute end-0 hero-image-container-overlay"></div><img class="position-absolute top-50 hero-image-container-bg" src="../../assets/img/bg/bg-39.png" alt="" /><img class="img-fluid ms-auto d-dark-none rounded-2 hero-image-shadow" src="../../assets/img/bg/bg-34.png" alt="hero-header" />
              </div>
            </div>
          </div>
        </section>
  
  
      
        <!-- ============================================-->
        <!-- <section> begin ============================-->
        <section class="pt-13 pb-10" id="mission">
  
          <div class="container-small px-lg-7 px-xxl-3">
            <div class="text-center mb-10 mb-md-5">
              <h4 class="text-primary fw-bolder mb-4">Mission</h4>
              <h2 class="mb-3 lh-base">Your Ultimate Diabetes Tracking Companion</h2>
              <p class="mb-0">
                Living with diabetes is a constant juggling act. 
                Individuals are tasked with managing blood sugar levels, insulin doses, 
                diet, and overall health amidst the complexities of daily life.

                The key lies in tracking these efforts, a compass for informed choices.
                It's the journey of individuals, taking charge of their health, that leads 
                to a brighter, healthier future.

               

              </p>
              <!--<div class="text-center mt-5"><a class="btn btn-outline-primary" href="#!">See more<span class="fa-solid fa-angle-right ms-2"></span></a></div>-->
            </div>
            <div class="row flex-between-center px-xl-11 mb-10 mb-md-9">
              <div class="col-md-6 order-1 order-md-0 text-center text-md-start">
                <h4 class="mb-3">The Challenge</h4>
                <p class="mb-5">
                  Diabetes care involves a myriad of factors, from monitoring blood sugar 
                  to adjusting insulin doses based on various factors like meals, exercise, 
                  and stress. The sheer complexity can be overwhelming.
                </p>
                <p>
                  The need for constant attention disrupts daily routines. 
                  From work meetings to family dinners, diabetes demands attention at 
                  every turn, affecting both professional and personal aspects of life.
                </p>
                <a class="btn btn-link me-2 p-0 fs--1" data-bs-toggle="modal" data-bs-target="#verticallyCentered" role="button">Get Started</a>
              </div>
              <div class="col-md-5 mb-5 mb-md-0 text-center"><img class="w-75 w-md-100 d-dark-none" src="../../assets/img/spot-illustrations/34.png" alt="" /><img class="w-75 w-md-100 d-light-none" src="../../assets/img/spot-illustrations/34_2.png" alt="" /></div>
            </div>
            <div class="row flex-between-center px-xl-11 mb-10 mb-md-9">
              <div class="col-md-6 order-1 order-md-0 text-center text-md-start">
                <h4 class="mb-3">The Smart Digital Journal as Solution</h4>
                <p class="mb-5">
                  Novalumen offers a modern digital Diabetes Journal designed to simplify 
                  your Diabetes Care. This smart digital journal adapts to your lifestyle, 
                  empowering you to take control and transform your diabetes care experience. 
                  We put the control in your hands, making it easy to manage your health at your own pace.
                </p>
                <a class="btn btn-link me-2 p-0 fs--1" data-bs-toggle="modal" data-bs-target="#verticallyCentered" role="button">Get Started</a>
              </div>
              <div class="col-md-5 mb-5 mb-md-0 text-center"><img class="w-75 w-md-100 d-dark-none" src="../../assets/img/spot-illustrations/35.png" alt="" /><img class="w-75 w-md-100 d-light-none" src="../../assets/img/spot-illustrations/35_2.png" alt="" /></div>
            </div>
            <div class="row flex-between-center px-xl-11 false">
              <div class="col-md-6 order-1 order-md-0 text-center text-md-start">
                <h4 class="mb-3">Control Your Diabetes Life</h4>
                <p class="mb-5">
                  In the journey of diabetes care, turning complexity into simplicity is key. 
                  With Novalumen's Smart Digital Journal, we've reimagined the way you manage your health. It's not just about tracking; 
                  it's about empowerment, personalized support, and regaining control of your life.
                </p>
                <p>
                  Join us as we revolutionize diabetes management together. 
                  Let's transform the overwhelming challenges into a path of empowerment, 
                  well-being, and peace of mind. Take the first step towards a brighter future in 
                  diabetes care.
                </p>                
                <a class="btn btn-link me-2 p-0 fs--1" data-bs-toggle="modal" data-bs-target="#verticallyCentered" role="button">Get Started</a>
              </div>
              <div class="col-md-5 mb-5 mb-md-0 text-center"><img class="w-75 w-md-100 d-dark-none" src="../../assets/img/spot-illustrations/36.png" alt="" /><img class="w-75 w-md-100 d-light-none" src="../../assets/img/spot-illustrations/36_2.png" alt="" /></div>
            </div>
          </div>
          <!-- end of .container-->
  
        </section>
        <!-- <section> close ============================-->
        <!-- ============================================-->
  
  
          <!-- ============================================-->
      <!-- <section> begin ============================-->
      <section class="pt-15 pb-0 pb-10" id="feature" style="background-color: #f5f7fa;">

        <div class="container-small px-lg-7 px-xxl-3"> 
          <div class="position-relative z-index-2">
            <div class="row">
              <div class="col-lg-6 text-center text-lg-start pe-xxl-3">
                <h4 class="text-primary fw-bolder mb-4">Features</h4>
                <h2 class="mb-3 text-black lh-base">Precision Meets Personalization<br class="d-md-none" /></h2>
                <p class="mb-5">
                  From the elegant convinience of Insulin and Blood Sugar Journals to insightful Lifestyle Metrics, 
                  comprehensive Health Metrics, and a quick Vitals Summary – our platform is 
                  your personalized toolkit for managing every aspect of your diabetes journey.
                </p>
                <a class="btn btn-lg btn-outline-primary rounded-pill me-3" data-bs-toggle="modal" data-bs-target="#verticallyCentered" role="button">Get Started</a>
              </div>
              <div class="col-sm-6 col-lg-3 mt-7 text-center text-lg-start">
                <div class="h-100 d-flex flex-column justify-content-between">
                  <div class="border-start-lg border-dashed ps-4"><img class="mb-4" src="../../assets/img/icons/illustrations/bolt.png" width="48" height="48" alt="" />
                    <div>
                      <h5 class="fw-bolder mb-2">Holistic Wellness Hub</h5>
                      <p class="fw-semi-bold lh-sm">Individual health insights, nutrition tracking, and AI-driven guidance converge to empower your journey towards total well-being.</p>
                    </div>                    
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3 mt-7 text-center text-lg-start">
                <div class="h-100 d-flex flex-column">
                  <div class="border-start-lg border-dashed ps-4"><img class="mb-4" src="../../assets/img/icons/illustrations/pie.png" width="48" height="48" alt="" />
                    <div>
                      <h5 class="fw-bolder mb-2">Smart Personalization</h5>
                      <p class="fw-semi-bold lh-sm">Our intuitive platform tailors every aspect of your health journey, ensuring a uniquely personalized wellness experience just for you.</p>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-10 align-items-center justify-content-between text-center text-lg-start mb-6 mb-lg-0">
              <div class="col-lg-5"><img class="border border-2 border-200 rounded-4 p-1 feature-image img-fluid mb-9 mb-lg-0 d-dark-none" src="../../assets/img/spot-illustrations/insulin-journal.png" alt="" /><img class="feature-image img-fluid mb-9 mb-lg-0 d-light-none" src="../../assets/img/spot-illustrations/dark_22.png" alt="" /></div>
              <div class="col-lg-6">
                <h6 class="text-primary mb-2 ls-2">DATA</h6>
                <h3 class="fw-bolder mb-3">Insulin Journal</h3>
                <p class="mb-4 px-md-7 px-lg-0">
                  Effortlessly track your insulin dosage with precision using our intuitive 
                  Insulin Journal, ensuring every entry is seamlessly captured for a clearer 
                  understanding of your diabetes management journey.
                </p>
                <a class="btn btn-link me-2 p-0 fs--1" data-bs-toggle="modal" data-bs-target="#verticallyCentered" role="button">Get Started</a>
              </div>
            </div>
            <div class="row mt-5 align-items-center justify-content-between text-center text-lg-start mb-6 mb-lg-0">
              <div class="col-lg-5 order-0 order-lg-1"><img class="border border-2 border-200 rounded-4 p-1 feature-image img-fluid mb-9 mb-lg-0 d-dark-none" src="../../assets/img/spot-illustrations/blood-sugar-journal.png" height="394" alt="" /><img class="feature-image img-fluid mb-9 mb-lg-0 d-light-none" src="../../assets/img/spot-illustrations/dark_23.png" height="394" alt="" /></div>
              <div class="col-lg-6">
                <h6 class="text-primary mb-2 ls-2">TRENDS</h6>
                <h3 class="fw-bolder mb-3">Blood Sugar Journal</h3>
                <p class="mb-4 px-md-7 px-lg-0">
                  Monitor your blood sugar levels with ease through our intuitive Blood Sugar Journal.
                  Capture and analyze readings effortlessly, empowering you to make informed 
                  decisions for your health.
                </p>
                <a class="btn btn-link me-2 p-0 fs--1" data-bs-toggle="modal" data-bs-target="#verticallyCentered" role="button">Get Started</a>
              </div>
            </div>
            <div class="row mt-7 align-items-center justify-content-between text-center text-lg-start mb-6 mb-lg-0">
              <div class="col-lg-5"><img class="border border-2 border-200 rounded-4 p-1 feature-image img-fluid mb-9 mb-lg-0 d-dark-none" src="../../assets/img/spot-illustrations/health-metrics.png" height="394" alt="" /><img class="feature-image img-fluid mb-9 mb-lg-0 d-light-none" src="../../assets/img/spot-illustrations/dark_24.png" height="394" alt="" /></div>
              <div class="col-lg-6 text-center text-lg-start">
                <h6 class="text-primary mb-2 ls-2">INSIGHTS</h6>
                <h3 class="fw-bolder mb-3">Health Metrics</h3>
                <p class="mb-4 px-md-7 px-lg-0">
                  Dive into comprehensive insights with Health Metrics. 
                  From medications and blood pressure to foot care, symptomatic episodes, 
                  stress levels, and sleep quality — our holistic approach puts all your 
                  health data in one place for a 360-degree view of your well-being.
                </p>
                <a class="btn btn-link me-2 p-0 fs--1" data-bs-toggle="modal" data-bs-target="#verticallyCentered" role="button">Get Started</a>
              </div>
            </div> 
            <div class="row mt-7 align-items-center justify-content-between text-center text-lg-start mb-6 mb-lg-0">
              <div class="col-lg-5 order-0 order-lg-1"><img class="border border-2 border-200 rounded-4 p-1 feature-image img-fluid mb-9 mb-lg-0 d-dark-none" src="../../assets/img/spot-illustrations/lifestyle-insights.png" height="394" alt="" /><img class="feature-image img-fluid mb-9 mb-lg-0 d-light-none" src="../../assets/img/spot-illustrations/dark_23.png" height="394" alt="" /></div>
              <div class="col-lg-6">
                <h6 class="text-primary mb-2 ls-2">PROGRESS</h6>
                <h3 class="fw-bolder mb-3">Lifestyle Insights</h3>
                <p class="mb-4 px-md-7 px-lg-0">
                  Unlock Lifestyle Insights for a well-rounded perspective on your health. 
                  Explore weight, calories, carbs, healthy snacks, alcohol intake, 
                  intermittent fasting, and exercise details — empowering you to make informed 
                  choices that align with your unique lifestyle goals.
                </p>
                <a class="btn btn-link me-2 p-0 fs--1" data-bs-toggle="modal" data-bs-target="#verticallyCentered" role="button">Get Started</a>
              </div>
            </div>
          </div>
        </div>
        <!-- end of .container-->

      </section>
      <!-- <section> close ============================-->
      <!-- ============================================-->


  
        <!-- ============================================-->
        <!-- <section> begin ============================-->
        <section class="mt-10 pb-14 overflow-x-hidden">
  
          <div class="container-small px-lg-7 px-xxl-3">
            <div class="text-center mb-5 position-relative">
              <h4 class="text-primary fw-bolder mb-4">Testimonial</h4>
              <h2 class="mb-2 lh-base">What our users has to say about us</h2>
            </div>  
            <div data-bs-config='{"interval":7000}' class="carousel testimonial-carousel slide position-relative dark__bg-1100" id="carouselExampleIndicators" data-bs-ride="carousel">
              <div class="bg-holder d-none d-md-block" style="background-image:url(../../assets/img/bg/39.png);background-size:186px;background-position:top 20px right 20px;">
              </div>
              <!--/.bg-holder-->
              <img class="position-absolute d-none d-lg-block" src="../../assets/img/bg/bg-left-22.png" width="150" alt="" style="top: -100px; left: -70px" /><img class="position-absolute d-none d-lg-block" src="../../assets/img/bg/bg-right-22.png" width="150" alt="" style="bottom: -80px; right: -80px" />
              <div class="carousel-inner">
                <div class="carousel-item text-center py-8 px-5 px-xl-15 active"><span class="fa fa-star text-warning"></span><span class="fa fa-star text-warning"></span><span class="fa fa-star text-warning"></span><span class="fa fa-star text-warning"></span><span class="fa fa-star text-warning"></span>
                  <h3 class="fw-semi-bold fst-italic mt-3 mb-8 w-xl-70 mx-auto lh-base">
                    Novalumen's Diabetes Journal is a game-changer! 
                    It's user-friendly and empowers me to effortlessly track my glucose levels, 
                    medications, and meals. Thanks to Novalumen, managing my diabetes is 
                    easier and more fulfilling!
                  </h3>
                  <div class="d-flex align-items-center justify-content-center gap-3 mx-auto">
                    <div class="avatar avatar-3xl ">
                      <img class="rounded-circle border border-2 border-primary" src="../../assets/img/team/12.webp" alt="" />
  
                    </div>
                    <div class="text-start">
                      <h5>John Anderson</h5>                      
                    </div>
                  </div>
                </div>
                <div class="carousel-item text-center py-8 px-5 px-xl-15 false"><span class="fa fa-star text-warning"></span><span class="fa fa-star text-warning"></span><span class="fa fa-star text-warning"></span><span class="fa fa-star text-warning"></span><span class="fa fa-star-half-alt star-icon text-warning"></span>
                  <h3 class="fw-semi-bold fst-italic mt-3 mb-8 w-xl-70 mx-auto lh-base">
                    Novalumen stands out among diabetes apps. 
                    The simplicity of the Diabetes Journal helps me stay on top of my health goals. 
                    Grateful for the positive impact it's had on my well-being!
                  </h3>
                  <div class="d-flex align-items-center justify-content-center gap-3 mx-auto">
                    <div class="avatar avatar-3xl ">
                      <img class="rounded-circle border border-2 border-primary" src="../../assets/img/team/13.webp" alt="" />
  
                    </div>
                    <div class="text-start">
                      <h5>Mary Thompson</h5>                      
                    </div>
                  </div>
                </div>
                <div class="carousel-item text-center py-8 px-5 px-xl-15 false"><span class="fa fa-star text-warning"></span><span class="fa fa-star text-warning"></span><span class="fa fa-star text-warning"></span><span class="fa fa-star text-warning"></span><span class="fa-regular fa-star text-warning-300"></span>
                  <h3 class="fw-semi-bold fst-italic mt-3 mb-8 w-xl-70 mx-auto lh-base">
                    Novalumen has transformed my approach to diabetes care. 
                    The personalized insights and reminders are like having a friend by my side, 
                    encouraging me to make healthy choices. A reliable and empowering solution!
                  </h3>
                  <div class="d-flex align-items-center justify-content-center gap-3 mx-auto">
                    <div class="avatar avatar-3xl ">
                      <img class="rounded-circle border border-2 border-primary" src="../../assets/img/team/14.webp" alt="" />
  
                    </div>
                    <div class="text-start">
                      <h5>Sarah Johnson</h5>                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-indicators">
                <button class="active" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
            </div>
          </div>
          <!-- end of .container-->
  
        </section>
        <!-- <section> close ============================-->
        <!-- ============================================-->
  
  
        
  
  
        <!-- ============================================-->
        <!-- <section> begin ============================-->
        <section class="overflow-hidden rotating-earth-container pb-5 pb-md-0 pt-12">
  
          <div class="container-small px-lg-7 px-xxl-3">
            <div class="row">
              <div class="col-lg-6 text-center text-lg-start">
                <h5 class="text-info mb-3">Likely the best digital Diabetes Journal today</h5>
                <h2 class="mb-2 lh-base">Used by thousands of users</h2>
                <h1 class="fs-4 fs-sm-6 mb-3 text-gradient-info fw-black">WORLDWIDE</h1>
                <p class="mb-10">
                  Our digital Diabetes Journal is designed to make your life with diabetes not just 
                  manageable, but fulfilling and thriving.
                </p>
                <div class="row gy-6">
                  <div class="col-sm-6 text-center text-lg-start">
                    <!--<img class="mb-4 d-dark-none" src="../../assets/img/icons/lightning-speed.png" alt="" /><img class="mb-4 d-light-none" src="../../assets/img/icons/lightning-speed-dark.png" alt="" />-->
                    <h4 class="mb-2">Own Your Diabetes Journey</h4>
                    <p>Empower yourself with Novalumen. Take control effortlessly.</p>
                  </div>
                  <div class="col-sm-6 text-center text-lg-start">
                    <!--<img class="mb-4 d-dark-none" src="../../assets/img/icons/best-statistics.png" alt="" /><img class="mb-4 d-light-none" src="../../assets/img/icons/best-statistics-dark.png" alt="" />-->
                    <h4 class="mb-2">Simplify, Manage, Thrive</h4>
                    <p>Seamless tracking for ultimate diabetes management.</p>
                  </div>
                  <div class="col-sm-6 text-center text-lg-start">
                    <!--<img class="mb-4 d-dark-none" src="../../assets/img/icons/all-night.png" alt="" /><img class="mb-4 d-light-none" src="../../assets/img/icons/all-night-dark.png" alt="" />-->
                    <h4 class="mb-2">Smart Insights, Better Choices</h4>
                    <p>Informed decisions for a healthier life with Novalumen.</p>
                  </div>
                  <div class="col-sm-6 text-center text-lg-start">
                    <!--<img class="mb-4 d-dark-none" src="../../assets/img/icons/editable-features.png" alt="" /><img class="mb-4 d-light-none" src="../../assets/img/icons/editable-features-dark.png" alt="" />-->
                    <h4 class="mb-2">Your Pocket Health Partner</h4>
                    <p>Novalumen – your personal companion for diabetes care.</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-auto">
                <div class="position-relative position-lg-absolute rotating-earth">
                  <div class="lottie d-dark-none" data-options='{"path":"../../assets/img/animated-icons/rotating-earth.json"}'></div>
                  <div class="lottie d-light-none" data-options='{"path":"../../assets/img/animated-icons/rotating-earth-dark.json"}'></div><img class="position-absolute d-dark-none" src="../../assets/img/spot-illustrations/earth-plane.png" alt="" /><img class="position-absolute d-light-none" src="../../assets/img/spot-illustrations/earth-plane-dark.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <!-- end of .container-->
  
        </section>
        <!-- <section> close ============================-->
        <!-- ============================================-->
  
  
      
  
        <!-- ============================================-->
        <!-- <section> begin ============================-->
        <section id="pricing" class="position-static pt-15 pt-md-5 pt-lg-2 mt-12">
  
          <div class="container-small px-lg-7 px-xxl-3">
            <div class="text-center mb-3 mb-lg-7">
              <h4 class="text-primary fw-bolder mb-4">Pricing</h4>
              <h2 class="mb-2">Choose the best deal for you</h2>
            </div>
            <div class="row g-3 mb-7 mb-lg-11">
              <div class="col-lg-4">
                <div class="pricing-card">
                  <div class="card bg-transparent border-0">
                    <div class="card-body p-7">
                      <div class="d-flex align-items-center mb-4">
                        <div class="mb-0"><h3>Solo Free</h3></div>                         
                      </div>                      
                      <h1 class="fs-5 d-flex align-items-center gap-1 mb-3">$0<span class="fs-0 fw-normal">/ month</span></h1>
                      <button class="btn btn-lg w-100 mb-7 btn-primary" data-bs-toggle="modal" data-bs-target="#verticallyCentered">Subscribe</button>
                      <h5 class="mb-4">What’s included</h5>
                      <ul class="fa-ul ps-3 m-0 pricing">
                        <li class="d-flex align-items-center mb-3">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">Core Platform Functionality</p>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">Vitals Summary</p>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">Blood Sugar Journal</p>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">Insulin Journal</p>
                        </li>
                        <li class="d-flex align-items-center mb-0">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">Essential Notifications</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="pricing-card">
                  <div class="card bg-transparent border border-2 border-info rounded-4">
                    <div class="card-body p-7">
                      <div class="d-flex align-items-center mb-4">
                        <div class="mb-0"><h3>Solo Plus</h3></div> 
                        <div class="badge badge-phoenix badge-phoenix-success p-1 ps-3 pe-3 ms-3 mb-1 fs--1">30 Days Free</div>
                      </div>                      
                      <h1 class="fs-5 d-flex align-items-center gap-1 mb-3">$5.99<span class="fs-0 fw-normal">/ month</span></h1>
                      <button class="btn btn-lg w-100 mb-7 btn-primary" data-bs-toggle="modal" data-bs-target="#verticallyCentered">Subscribe</button>
                      <h5 class="mb-4">What’s included</h5>
                      <ul class="fa-ul ps-3 m-0 pricing">
                        <li class="d-flex align-items-center mb-3">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">Everything from Solo Free</p>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">Health Metrics</p>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">Lifestyle Insights</p>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">Fitness Trackers</p>
                          <span class="badge badge-phoenix badge-phoenix-info ms-2 fs--2">Coming Soon</span>
                        </li>
                        <li class="d-flex align-items-center mb-0">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">Notifications &amp; Reminders</p>
                        </li>                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="pricing-card">
                  <div class="card bg-transparent border-0">
                    <div class="card-body p-7">
                      <div class="d-flex align-items-center mb-4">
                        <div class="mb-0"><h3>Circle Plus</h3></div>                         
                      </div>                        
                      <h1 class="fs-5 d-flex align-items-center gap-1 mb-3">$9.99<span class="fs-0 fw-normal">/ month</span></h1>
                      <button class="btn btn-lg w-100 mb-7 btn-phoenix-primary">Coming Soon</button>
                      <h5 class="mb-4">What’s included</h5>
                      <ul class="fa-ul ps-3 m-0 pricing">
                        <li class="d-flex align-items-center mb-3">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">Everything from Solo Plus</p>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">Smart Devices Integration</p>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">"Share with Doctor"</p>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">"Care Circle Access"</p>
                        </li>
                        <li class="d-flex align-items-center mb-0">
                          <fa-icon [icon]="faCheck" [size]="'sm'" class="me-2 text-primary" />
                          <p class="mb-0">Enhanced Notifications</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <!--
            <div class="card rounded-4 border-0 offer-card">
              <div class="card-body d-md-flex align-items-center gap-4 py-5"><img class="mb-4 mb-md-0 d-dark-none" src="../../assets/img/spot-illustrations/air-plane.png" width="155" alt="" /><img class="mb-4 mb-md-0 d-light-none" src="../../assets/img/spot-illustrations/air-plane-dark.png" width="155" alt="" />
                <div>
                  <p>Business Starter, Business Standard, and Business Plus plans can be purchased for a maximum of 300 users. There is no minimum or maximum user limit for Enterprise plans.</p>
                  <p class="mb-6">Phoenix customers may have access to additional features for a limited promotional period</p><a class="btn btn-link me-2 p-0 fs--1" href="#!" role="button">Contact Support<i class="fa-solid fa-angle-right ms-2"></i></a>
                </div>
              </div>
            </div>
            -->

          </div>


          <!-- end of .container-->
  
        </section>
        <!-- <section> close ============================-->
        <!-- ============================================-->
  
  
  
  
        <!-- ============================================-->
        <!-- <section> BLOGS!!! begin ============================-->
        <!--
        <section class="pt-10" id="blog">
  
          <div class="container-small px-lg-7 px-xxl-3">
            <div class="text-center mb-5 mb-8">
              <h5 class="text-info mb-3">Blogs</h5>
              <h2 class="mb-2">Our most viewed articles</h2>
            </div>
            <div class="row gx-3 gy-7">
              <div class="col-lg-4">
                <div class="blog-card"><img class="w-100 rounded-3" src="../../assets/img/blog/blog-5.png" alt="" />
                  <div class="d-flex align-items-cente mt-3"><a class="btn-link text-decoration-none text-800 d-flex align-items-center me-3" href="#!"><span class="fa-solid fa-eye fs--1"></span><span class="fs--2 ms-1 lh-1">2563</span></a><a class="btn-link text-decoration-none text-800 d-flex align-items-center me-3" href="#!"><span class="fa-solid fa-heart fs--1"></span><span class="ms-1 fs--2 lh-1">125</span></a><a class="btn-link text-decoration-none text-800 d-flex align-items-center" href="#!"><span class="fa-solid fa-comment fs--1"></span><span class="ms-1 fs--2 lh-1">125</span></a></div><span class="badge bg-primary mb-2 mt-4">SEO</span>
                  <h4 class="mb-3 pe-sm-5 lh-lg">Top 10 ways to Ace SEO for your business</h4><a class="btn-link px-0 d-flex align-items-center fs--1 fw-bold" href="#!" role="button">See more<span class="fa-solid fa-angle-right ms-2"></span></a>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="blog-card"><img class="w-100 rounded-3" src="../../assets/img/blog/blog-6.png" alt="" />
                  <div class="d-flex align-items-cente mt-3"><a class="btn-link text-decoration-none text-800 d-flex align-items-center me-3" href="#!"><span class="fa-solid fa-eye fs--1"></span><span class="fs--2 ms-1 lh-1">1256</span></a><a class="btn-link text-decoration-none text-800 d-flex align-items-center me-3" href="#!"><span class="fa-solid fa-heart fs--1"></span><span class="ms-1 fs--2 lh-1">325</span></a><a class="btn-link text-decoration-none text-800 d-flex align-items-center" href="#!"><span class="fa-solid fa-comment fs--1"></span><span class="ms-1 fs--2 lh-1">32</span></a></div><span class="badge bg-primary mb-2 mt-4">Marketing</span>
                  <h4 class="mb-3 pe-sm-5 lh-lg">Top 12 Marketing strategies you can take</h4><a class="btn-link px-0 d-flex align-items-center fs--1 fw-bold" href="#!" role="button">See more<span class="fa-solid fa-angle-right ms-2"></span></a>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="blog-card"><img class="w-100 rounded-3" src="../../assets/img/blog/blog-7.png" alt="" />
                  <div class="d-flex align-items-cente mt-3"><a class="btn-link text-decoration-none text-800 d-flex align-items-center me-3" href="#!"><span class="fa-solid fa-eye fs--1"></span><span class="fs--2 ms-1 lh-1">142</span></a><a class="btn-link text-decoration-none text-800 d-flex align-items-center me-3" href="#!"><span class="fa-solid fa-heart fs--1"></span><span class="ms-1 fs--2 lh-1">123</span></a><a class="btn-link text-decoration-none text-800 d-flex align-items-center" href="#!"><span class="fa-solid fa-comment fs--1"></span><span class="ms-1 fs--2 lh-1">22</span></a></div><span class="badge bg-primary mb-2 mt-4">Marketing</span>
                  <h4 class="mb-3 pe-sm-5 lh-lg">The top 7 methods to improve as a marketer</h4><a class="btn-link px-0 d-flex align-items-center fs--1 fw-bold" href="#!" role="button">See more<span class="fa-solid fa-angle-right ms-2"></span></a>
                </div>
              </div>
            </div>
          </div>
          
  
        </section>
        -->
        <!-- <section> close ============================-->
        <!-- ============================================-->
  
  
  
  
        <!-- ============================================-->
        <!-- <section> begin ============================-->
        <section id="contacts" class="pb-10 pb-xl-14">
  
          <div class="container-small px-lg-7 px-xxl-3">
            <div class="text-center mb-7">
              <h4 class="text-primary fw-bolder mb-4">Contacts</h4>
              <h2 class="mb-2">Choose the best contact for you</h2>
            </div>
            <div class="row">
              <div class="col-12 mb-10">
                <div class="googlemap" style="height: 381px; border-radius:3.5rem;">
                  <gmp-map center="35.109612431367104, -106.6183849612892" scrollwheel="false" zoom="15" map-id="DEMO_MAP_ID" style="height: 381px; border-radius:3.5rem;">
                    <!--<gmp-advanced-marker position="35.109612431367104, -106.6183849612892" title="Our location">
                    </gmp-advanced-marker>-->
                  </gmp-map>
                </div>
              </div>
            </div>
            <div class="row g-5 g-lg-5">
              <div class="col-md-6 mb-5 mb-md-0 text-center text-md-start">
                <h3 class="mb-3">Stay connected</h3>
                <p class="mb-5">Stay connected with Novalumen's Help Center; We are available for your necessities at all times.</p>
                <div class="d-flex flex-column align-items-center align-items-md-start gap-3 gap-md-0">
                  <div class="d-md-flex align-items-center">
                    <div class="icon-wrapper shadow-info"><span class="uil uil-phone text-primary light fs-4 z-index-1 ms-2"></span></div>
                    <div class="flex-1 ms-5 fw-semi-bold text-900">(609) 620-2100</div>
                  </div>
                  <div class="d-md-flex align-items-center">
                    <div class="icon-wrapper shadow-info"><span class="uil uil-envelope text-primary light fs-4 z-index-1 ms-2"></span></div>
                    <div class="flex-1 ms-5 fw-semi-bold text-900">info&#64;novalumen.com</div>
                  </div>
                  <div class="mb-6 d-md-flex align-items-center">
                    <div class="icon-wrapper shadow-info"><span class="uil uil-map-marker text-primary light fs-4 z-index-1 ms-2"></span></div>
                    <div class="flex-1 ms-5 fw-semi-bold text-900">Novalumen LLC<br>2201 Menaul Boulevard Northeast<br>Albuquerque, NM 87107</div>
                  </div>
                  <div class="d-flex"><a href="#!"><span class="fa-brands fa-facebook fs-2 text-primary me-3"></span></a><a href="#!"><span class="fa-brands fa-twitter fs-2 text-primary me-3"></span></a><a href="#!"><span class="fa-brands fa-linkedin-in fs-2 text-primary"></span></a></div>
                </div>
              </div>
              <div class="col-md-6 text-center text-md-start">
                <h3 class="mb-3">Drop us a line</h3>
                <p class="mb-7">If you have any query or suggestion , we are open to learn from you, Lets talk, reach us anytime.</p>
                <form class="row g-4">
                  <div class="col-12">
                    <input [(ngModel)]="emailForm.name" class="form-control bg-white" type="text" name="name" placeholder="Name" required="required" />
                  </div>
                  <div class="col-12">
                    <input [(ngModel)]="emailForm.email" class="form-control bg-white" type="email" name="email" placeholder="Email" required="required" />
                  </div>
                  <div class="col-12">
                    <textarea [(ngModel)]="emailForm.message" class="form-control bg-white" rows="6" name="message" placeholder="Message" required="required"></textarea>
                  </div>
                  <div class="col-12 d-grid">
                    <button class="btn btn-outline-primary" type="submit" (click)="clickFeedbackForm()" data-bs-toggle="modal" data-bs-target="#feedbackModel">Submit</button> 
                  </div>
                  <div class="feedback"></div>
                </form>
              </div>
            </div>
          </div>
          <!-- end of .container-->
  
        </section>
        <!-- <section> close ============================-->
        <!-- ============================================-->
  
  
        
  
  
        <!-- ============================================-->
        <!-- <section> begin ============================-->
        <section class="bg-1100 dark__bg-1000">
  
          <div class="container-small px-lg-7 px-xxl-3">
            <!--
            <div class="row gx-xxl-8 gy-5 align-items-center mb-5">
              <div class="col-xl-auto text-center"><a href="#"><img src="../../assets/img/icons/logo-white.png" height="48" alt="" /></a></div>
              <div class="col-xl-auto flex-1">
                <ul class="list-unstyled d-flex justify-content-center flex-wrap mb-0 border-end-xl border-dashed border-800 gap-3 gap-xl-8 pe-xl-5 pe-xxl-8 w-75 w-md-100 mx-auto">
                  <li><a class="text-300 dark__text-300" href="#contacts">Contact us</a></li>
                  <li><a class="text-300 dark__text-300" href="">Newsroom</a></li>
                  <li><a class="text-300 dark__text-300" href="">Opportunities</a></li>
                  <li><a class="text-300 dark__text-300" href="">Login</a></li>
                  <li><a class="text-300 dark__text-300" href="">Sign Up</a></li>
                  <li><a class="text-300 dark__text-300" href="">Support</a></li>
                  <li><a class="text-300 dark__text-300" href="">FAQ</a></li>
                </ul>
              </div>
              <div class="col-xl-auto">
                <div class="d-flex align-items-center justify-content-center gap-8"><a class="text-white dark__text-white" href="#!"> <span class="fa-brands fa-facebook"></span></a><a class="text-white dark__text-white" href="#!"> <span class="fa-brands fa-twitter"></span></a><a class="text-white dark__text-white" href="#!"> <span class="fa-brands fa-linkedin-in"></span></a></div>
              </div>
            </div>
            <hr class="text-800" />
            -->
            <div class="d-sm-flex flex-between-center text-center">
              <p class="text-600 mb-0">Copyright 2024 © Novalumen LLC</p>              
            </div>
          </div>
          <!-- end of .container-->
  
        </section>
        <!-- <section> close ============================-->
        <!-- ============================================-->
  
  
        
      </main>
      <!-- ===============================================-->
      <!--    End of Main Content-->
      <!-- ===============================================-->



<div class="modal fade" id="feedbackModel" tabindex="-1" aria-labelledby="feedbackModelLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="feedbackModelLabel">Message Sent</h5>
        <button class="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><span class="fas fa-times fs--1"></span></button>
      </div>
      <div class="modal-body">
        <p class="text-700 lh-lg mb-0">
          Thank you for sharing your thoughts with us!
          Your feedback is invaluable and helps us improve.          
          We appreciate your time and input!
        </p>
      </div>
      <div class="modal-footer">        
        <button class="btn btn-small btn-outline-primary" type="button" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
    
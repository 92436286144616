
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">
        <div class="row vh-100 g-0">
          <div class="col-lg-6 position-relative d-none d-lg-block">
            <!--<div class="bg-holder login-bg-welcome" style="background-image:url(../assets/img/bg/login-bg.png);">-->
            <div class="bg-holder login-bg-welcome" style="background-image:url(../assets/img/spot-illustrations/auth.png);">
                <!--
                  <p>We are excited to be part of your journey towards a better health.</p>
                  <p class="login-bg-welcome-header">Welcome to Novalumen!</p>
                -->
            </div>
            <!--/.bg-holder-->
  
          </div>
          <div class="col-lg-6">
            <div class="row flex-center h-100 g-0 px-4 px-sm-0">
              <div class="col col-sm-6 col-lg-7 col-xl-6">
                <div class="text-center mb-6 mx-auto"><img class="mb-7 d-dark-none" src="../../../assets/img/spot-illustrations/paper-plane.png" alt="phoenix" /><img class="mb-7 d-light-none" src="../../../assets/img/spot-illustrations/dark_1.png" alt="phoenix" />
                  <div class="mb-6">
                    <h4 class="text-1000">Sign in to Your Account</h4>
                    <!--<p class="text-700">Thanks for using Phoenix. <br class="d-lg-none" />You are now successfully signed out.</p>-->
                  </div>
                  <div class="d-grid">
                    <div *ngIf="!fbUser">
                      <firebase-ui
                        (signInSuccessWithAuthResult)="successCallback($event)"
                        (signInFailure)="errorCallback($event)"
                        (uiShown)="uiShownCallback()"
                      ></firebase-ui>    
                      <p class="fs--1">
                        By logging in, you agree to our <br><a href="/terms" target="_blank">Terms of Service</a> and <a href="/privacy" target="_blank">Privacy Policy</a>.
                      </p>
                    </div>
                    <div *ngIf="fbUser">
                      <div class="mb-3">
                        You are already logged in as {{ fbUser.email }}
                      </div>
                      <button routerLink="/dashboard" class="btn btn btn-primary me-1 mb-1" type="button">Go to Dashboard</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->
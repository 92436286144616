<div class="row" *ngIf="authService.isAuthenticated as user">
    <p>user: {{ user | json }}</p>
    <p>token: {{ authService.isAuthenticated| json }}</p>
    <button (click)="_logout()">Logout 2</button>
</div>

<div class="row" *ngIf="!authService.isAuthenticated as user">
    <p>user not authenticated</p>
</div>

<p></p>

<firebase-ui></firebase-ui>

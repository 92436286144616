    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    
    <main class="main" id="top">

        <!--<nav class="navbar navbar-vertical navbar-expand-lg" style="display:block">
          vertical nav bar
        </nav>-->
        
          <app-header></app-header>
          
          
        <div class="content" >
  
          <!--==========================-->
  
          <div class="row g-0 w-100">

            <div class="card p-4 ">

                <p class="assessment-title">Stripe Integration</p>
                
                <stripe-pricing-table 

                    [attr.pricing-table-id]="stripe_table"
                    [attr.publishable-key]="stripe_key" 
                    [attr.client-reference-id]="stripe_customer_id"
                    [attr.customer-email]="stripe_customer_email"
                    >
                </stripe-pricing-table>  

            </div>
                        
              
  
          </div>
  
          <!--==========================-->
  
        </div>
        
      </main>
      <!-- ===============================================-->
      <!--    End of Main Content-->
      <!-- ===============================================-->


      <app-footer></app-footer>
  
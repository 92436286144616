<div class="d-flex min-vh-75 w-100 flex-column justify-content-center">
    <div class="">                    
        <p>Question: 5 of 10</p>
        <div class="progress" style="height:8px">
        <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
    <div class="flex-grow-1 "></div>
    <div class="d-flex d-flex justify-content-center">

        <div class="assessment-block">
            
            <p class="question-header">How physically active are you on a weekly basis?</p>

            <div class="form-check">
                <input class="btn-check" id="flexRadioDefault1" type="radio" name="flexRadioDefault" value="Light" [(ngModel)]="ss.selfAssessmentModel.activityLevel" (change)="onChange()"/>        
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault1">Light</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault2" type="radio" name="flexRadioDefault" value="Moderate" [(ngModel)]="ss.selfAssessmentModel.activityLevel" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault2">Moderate</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault3" type="radio" name="flexRadioDefault" value="Vigorous Exercise" [(ngModel)]="ss.selfAssessmentModel.activityLevel" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault3">Vigorous Exercise</label>
            </div>

        </div>
    </div>
    <div class="p-2 flex-grow-1"></div>
    
    <div class="d-flex mb-2">
        <div class="me-auto p-2">
        <button class="btn btn-link" (click)="_prevButton()" type="submit">Prev</button>
        </div>
        <div class="p-2">
        <button class="btn btn-link" (click)="_nextButton()" type="submit">Skip</button>
        </div>
        <div class="p-2">
        <button class="btn btn-primary" (click)="_nextButton()" type="submit">Next</button>
        </div>
    </div>
</div>

<div class="d-flex min-vh-75 w-100 flex-column justify-content-center">
    <div class="">                    
        <p>Assessment completed</p>
        <div class="progress" style="height:8px">
        <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
    <div class="flex-grow-1 "></div>
    <div class="d-flex d-flex justify-content-center">

        <div class="assessment-block">
            
            <p><b>Congratulations!</b></p>
            <p>            
                You've just completed our quick and insightful self-assessment, 
                taking the first step towards a healthier and more informed you. 
                You are almost there!
            </p>

            <button routerLink="/personal-journey" class="btn btn btn-primary me-1 mb-1" type="button">Next Step</button>
            
            <!--
            <p><b>What's Next?</b></p>

            <p>
                Now our platform learned a bit about you and created your very own personal 
                experience with an individual care plan for your diabetes journey.<br>
                Please click on a “My Personal Dashboard” button below to start. And remember, 
                it does not stop here – you can always customize your dashboard 
                and fine tune your daily routine anytime to keep it up to date with 
                your life and health!
            </p>

            <p>
                <b>We are so happy to have you in our platform!</b>
            </p>
            -->
            

        </div>
    </div>
    <div class="p-2 flex-grow-1"></div>
    
    <div class="d-flex mb-2">
        <!--
        <div class="me-auto p-2">
            <button class="btn btn-link" (click)="_prevButton()" type="submit">Prev</button>
        </div>
        <div class="p-2">
            <button class="btn btn-link" type="submit">Skip</button>
        </div>-->
       
    </div>
</div>

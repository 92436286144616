import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JourneyConfig } from 'src/app/shared/model/journey.config';
import { UserModel } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-personal-journey',
  templateUrl: './personal-journey.component.html',
  styleUrls: ['./personal-journey.component.css']
})
export class PersonalJourneyComponent implements OnInit{

  userModel: UserModel | any;
  suggestedJourney: JourneyConfig | undefined;

  constructor(
    private userService: UserService,  
    private renderer: Renderer2,  
    private toastr: ToastrService,
    private router: Router,
    //private actRoute: ActivatedRoute,
  ) {}


  ngOnInit(): void {

    // load initial userModel value
    //this.userModel = this.actRoute.snapshot.data['userModel'] as UserModel;
    
    // load userModel
    this.userService.getProfile().subscribe({
      next: (value) => {
        if(value) {
          this.userModel = value;               
          //this.initFormValues();     
        }
      },
    });

    // request Suggested Journey Config
    this.userService.getSuggestedJourney().subscribe({
      next: (value) => {
        //console.info("current user model: " + JSON.stringify(this.userModel))
        this.suggestedJourney = value;
        this.userModel.journeyConfig = value;
        this.saveData();
      }
    });
  
  }

  saveData() {
    this.userService.saveProfile(this.userModel).subscribe({
      next: (value) => {
        // nothing for now
      },
    })
  }


  nextStep() {
    if(this.userModel) {
      this.userModel.onboardingStatus = "Step 3";
      this.saveData();
      this.router.navigateByUrl("/pricing")
    }
  }

}
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { faAngleRight, faGears, faSliders, faTablets } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { BloodSugarModel } from 'src/app/shared/model/blood.sugar.model';
import { DailyRoutineModel } from 'src/app/shared/model/daily.routine.model';
import { DailyRoutineConfigComponent } from '../daily-routine/daily-routine-config/daily-routine-config.component';
import { UserModel } from 'src/app/shared/model/user.model';
import { SharedEventService } from 'src/app/shared/services/shared.event.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-daily-routine',
  templateUrl: './daily-routine.component.html',
  styleUrls: ['./daily-routine.component.css'],  
})
export class DailyRoutineComponent implements OnInit {

  faAngleRight = faAngleRight;
  faTablets = faTablets;
  faSliders = faSliders;
  faGears = faGears;
  
  @Input() public userModel: UserModel | any;

  //userModel: UserModel = new UserModel();
  dailyRoutineModel: DailyRoutineModel = new DailyRoutineModel();
  dailyRoutineProgress: number = 0;
  dietAndNutritionProgress: number = 0;

  //private renderer: Renderer2;

  @ViewChild('buttonAlcohol1') buttonAlcohol1: ElementRef | any;
  @ViewChild('buttonAlcohol2') buttonAlcohol2: ElementRef | any;
  
  @ViewChild('buttonExcercise1') buttonExcercise1: ElementRef | any;
  @ViewChild('buttonExcercise2') buttonExcercise2: ElementRef | any;
  
  @ViewChild('buttonFootCareGood') buttonFootCareGood: ElementRef | any;
  @ViewChild('buttonFootCareAverage') buttonFootCareAverage: ElementRef | any;
  @ViewChild('buttonFootCareNeedsAttention') buttonFootCareNeedsAttention: ElementRef | any;

  @ViewChild('buttonHealthySnacks1') buttonHealthySnacks1: ElementRef | any;
  @ViewChild('buttonHealthySnacks2') buttonHealthySnacks2: ElementRef | any;

  @ViewChild('buttonMedications1') buttonMedications1: ElementRef | any;
  @ViewChild('buttonMedications2') buttonMedications2: ElementRef | any;
  @ViewChild('buttonMedications3') buttonMedications3: ElementRef | any;  

  @ViewChild('buttonSleepQuality1') buttonSleepQuality1: ElementRef | any;
  @ViewChild('buttonSleepQuality2') buttonSleepQuality2: ElementRef | any;
  @ViewChild('buttonSleepQuality3') buttonSleepQuality3: ElementRef | any;

  @ViewChild('buttonStressLevel1') buttonStressLevel1: ElementRef | any;
  @ViewChild('buttonStressLevel2') buttonStressLevel2: ElementRef | any;
  @ViewChild('buttonStressLevel3') buttonStressLevel3: ElementRef | any;

  @ViewChild('buttonSymptomaticEpisodes1') buttonSymptomaticEpisodes1: ElementRef | any;
  @ViewChild('buttonSymptomaticEpisodes2') buttonSymptomaticEpisodes2: ElementRef | any;
  @ViewChild('buttonSymptomaticEpisodes3') buttonSymptomaticEpisodes3: ElementRef | any;

  @ViewChild('myBloodPressureField') myBloodPressureField: ElementRef | any;
  @ViewChild('myCaloriesField') myCaloriesField: ElementRef | any;
  @ViewChild('myCarbsField') myCarbsField: ElementRef | any;
  @ViewChild('myIntermittentFastingField') myIntermittentFastingField: ElementRef | any;
  @ViewChild('myWeightField') myWeightField: ElementRef | any;

  constructor(
    private eventService: SharedEventService,
    private userService: UserService,
    private el: ElementRef, 
    private renderer: Renderer2,
    private toastr: ToastrService,
  ) {

  }
  
  ngOnInit(): void {

    //console.log("dailyRoutineModel1: " + this.dailyRoutineModel.dateMarker);
    
    // subscribe to events from Shared Event Service
    this.eventService.messageWithObjectEmitter.subscribe((value: string[]) => {  
      const message = value[0];
      const object = value[1];
      this.eventListenerWithObject(message, object);
    });

    // load daily routine model
    this.userService.getDailyRoutine(this.dailyRoutineModel.getDateMarker()).subscribe({
      next: (value) => {
        //console.log("getDailyRoutine result: " + JSON.stringify(value));
        this.dailyRoutineModel = value;
        this.initDailyRoutineValues();  
        this.updateProgressBars();
      },
    });
  }

  initDailyRoutineValues() {

    if(this.userModel) {

      // Health Metrics components
      if(this.userModel.journeyConfig.hmMedications) {
        this.updateMedicationsButtonCss();
      }
      if(this.userModel.journeyConfig.hmFootCare) {
        this.updateFootCareButtonCss();
      }
      if(this.userModel.journeyConfig.hmSymptomaticEpisodes) {
        this.updateSymptomaticEpisodesButtonCss();
      }
      if(this.userModel.journeyConfig.hmStressLevel) {
        this.updateStressLevelButtonCss();
      }    
      if(this.userModel.journeyConfig.hmSleepQuality) {
        this.updateSleepQualityButtonCss();
      }

      // Diet & Nutrition components
      if(this.userModel.journeyConfig.liAlcohol) {
        this.updateAlcoholButtonCss();
      }
      if(this.userModel.journeyConfig.liExcercise) {
        this.updateExcerciseButtonCss();
      }
      if(this.userModel.journeyConfig.liHealthySnacks) {
        this.updateHealthySnacksButtonCss();
      }
    }
  }

  updateProgressBars() {
    this.updateHealthMetricsProgressBar();
    this.updateDietAndNutritionProgressBar();
  }

  updateHealthMetricsProgressBar() {

    let totalElements: number = 0;
    let selectedElements: number = 0;

    if(this.userModel) {

      if(this.userModel.journeyConfig.hmFastingBloodSugar) {
        totalElements++;
        if(this.dailyRoutineModel.fastingBloodSugar) selectedElements++;
      }
      if(this.userModel.journeyConfig.hmMedications) {
        totalElements++;
        if(this.dailyRoutineModel.medications) selectedElements++;
      }
      if(this.userModel.journeyConfig.hmBloodPressure) {
        totalElements++;
        if(this.dailyRoutineModel.bloodPressure) selectedElements++;
      } 
      if(this.userModel.journeyConfig.hmFootCare) {      
        totalElements++; 
        if(this.dailyRoutineModel.footCare) selectedElements++;
      } 
      if(this.userModel.journeyConfig.hmStressLevel) {      
        totalElements++; 
        if(this.dailyRoutineModel.stressLevel) selectedElements++;
      } 
      if(this.userModel.journeyConfig.hmSymptomaticEpisodes) {      
        totalElements++; 
        if(this.dailyRoutineModel.symptomaticEpisodes) selectedElements++;
      }    
      if(this.userModel.journeyConfig.hmSleepQuality) {      
        totalElements++; 
        if(this.dailyRoutineModel.sleepQuality) selectedElements++;
      }  
    }
    //console.log("total: " + totalElements + ", selected: " + selectedElements)

    this.dailyRoutineProgress = Math.ceil( selectedElements / totalElements * 100 );
  }

  updateDietAndNutritionProgressBar() {

    let totalElements: number = 0;
    let selectedElements: number = 0;

    if(this.userModel) {

      if(this.userModel.journeyConfig.liWeight) {
        totalElements++;
        if(this.dailyRoutineModel.weight) selectedElements++;
      }
      if(this.userModel.journeyConfig.liCalories) {
        totalElements++;
        if(this.dailyRoutineModel.calories) selectedElements++;
      }
      if(this.userModel.journeyConfig.liCarbs) {
        totalElements++;
        if(this.dailyRoutineModel.carbs) selectedElements++;
      } 
      if(this.userModel.journeyConfig.liHealthySnacks) {      
        totalElements++; 
        if(this.dailyRoutineModel.healthySnacks) selectedElements++;
      } 
      if(this.userModel.journeyConfig.liAlcohol) {      
        totalElements++; 
        if(this.dailyRoutineModel.alcohol) selectedElements++;
      } 
      if(this.userModel.journeyConfig.liIntermittentFasting) {      
        totalElements++; 
        if(this.dailyRoutineModel.intermittentFasting) selectedElements++;
      }    
      if(this.userModel.journeyConfig.liExcercise) {      
        totalElements++; 
        if(this.dailyRoutineModel.excercise) selectedElements++;
      }  
    }
    //console.log("total: " + totalElements + ", selected: " + selectedElements)

    this.dietAndNutritionProgress = Math.ceil( selectedElements / totalElements * 100 );
  }

  eventListenerWithObject(message: string, object: any) {

    if(message === this.eventService.NEW_BLOOD_SUGAR_VALUE) {
      const bloodSugar: BloodSugarModel = JSON.parse(object);
      if(bloodSugar 
        && this.isToday(bloodSugar.creationDate) 
        && bloodSugar.mealTag === 'Fasting Blood Sugar') {
          this.dailyRoutineModel.fastingBloodSugar = '' + bloodSugar.mgValue;  
          this.saveDailyRoutineModel();
      }
    }

  }

  saveDailyRoutineModel() {
    
    this.updateProgressBars();

    //console.log("saveDailyRoutineModel: " + JSON.stringify(this.dailyRoutineModel));
    
    if(this.dailyRoutineModel.uid) {
      // update dailyRoutineModel in DB
      this.userService.saveDailyRoutine(this.dailyRoutineModel).subscribe({
        next: (value) => {
          this.dailyRoutineModel = value;
        }
      });
    }
    else {
      // create dailyRoutineModel in DB
      this.userService.createDailyRoutine(this.dailyRoutineModel).subscribe({
        next: (value) => {
         this.dailyRoutineModel = value;
        }
      });
    }
  }

  isToday(dateInMilliseconds: number): boolean {
    // Convert the input date in milliseconds to a Date object
    const inputDate = new Date(dateInMilliseconds);
  
    // Get the current date
    const currentDate = new Date();
  
    // Compare year, month, and day to check if it's the same day
    return (
      inputDate.getFullYear() === currentDate.getFullYear() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getDate() === currentDate.getDate()
    );
  }

  addFastingBloodSugar() {
    this.eventService.sendMessage(this.eventService.FASTING_BLOOD_SUGAR_FORM);
  }

  buttonAlcoholClick(value: string) {
    this.dailyRoutineModel.alcohol = value;
    this.updateAlcoholButtonCss();
    this.saveDailyRoutineModel();
  }

  buttonExcerciseClick(value: string) {
    this.dailyRoutineModel.excercise = value;
    this.updateExcerciseButtonCss();
    this.saveDailyRoutineModel();
  }

  buttonFootCareClick(value: string) {
    this.dailyRoutineModel.footCare = value;    
    this.updateFootCareButtonCss();
    this.saveDailyRoutineModel();
  }

  buttonHealthySnacksClick(value: string) {
    this.dailyRoutineModel.healthySnacks = value;    
    this.updateHealthySnacksButtonCss();
    this.saveDailyRoutineModel();
  }

  buttonMedicationsClick(value: string) {
    this.dailyRoutineModel.medications = value;
    this.updateMedicationsButtonCss();
    this.saveDailyRoutineModel();
  }

  buttonSymptomaticEpisodesClick(value: string) {
    this.dailyRoutineModel.symptomaticEpisodes = value;    
    this.updateSymptomaticEpisodesButtonCss();
    this.saveDailyRoutineModel();
  }

  buttonStressLevelClick(value: string) {
    this.dailyRoutineModel.stressLevel = value;    
    this.updateStressLevelButtonCss();
    this.saveDailyRoutineModel();
  }

  buttonSleepQualityClick(value: string) {    
    this.dailyRoutineModel.sleepQuality = value;
    this.updateSleepQualityButtonCss();
    this.saveDailyRoutineModel();    
  }

  updateAlcoholButtonCss() {

    const value = this.dailyRoutineModel.alcohol;

    // update button css classes
    const button1 = this.buttonAlcohol1;
    const button2 = this.buttonAlcohol2;

    this.turnOffButton(button1);
    this.turnOffButton(button2);      

    if(value === 'No') 
      this.turnOnButton(button1);                     
    else if(value === 'Yes') 
      this.turnOnButton(button2);   
  }

  updateExcerciseButtonCss() {

    const value = this.dailyRoutineModel.excercise;

    // update button css classes
    const button1 = this.buttonExcercise1;
    const button2 = this.buttonExcercise2;

    this.turnOffButton(button1);
    this.turnOffButton(button2);      

    if(value === 'No') 
      this.turnOnButton(button1);                     
    else if(value === 'Yes') 
      this.turnOnButton(button2);   
  }

  updateHealthySnacksButtonCss() {

    const value = this.dailyRoutineModel.healthySnacks;

    // update button css classes
    const button1 = this.buttonHealthySnacks1;
    const button2 = this.buttonHealthySnacks2;

    this.turnOffButton(button1);
    this.turnOffButton(button2);      

    if(value === 'No') 
      this.turnOnButton(button1);                     
    else if(value === 'Yes') 
      this.turnOnButton(button2);   

  }

  updateMedicationsButtonCss() {

    const value = this.dailyRoutineModel.medications;

    // update button css classes
    const button1 = this.buttonMedications1;
    const button2 = this.buttonMedications2;
    const button3 = this.buttonMedications3;

    this.turnOffButton(button1);
    this.turnOffButton(button2);      
    this.turnOffButton(button3); 

    if(value === 'No') 
      this.turnOnButton(button1);                     
    else if(value === 'One') 
      this.turnOnButton(button2);   
    else if(value === 'Multiple') 
      this.turnOnButton(button3);    
  }

  updateFootCareButtonCss() {

    const value = this.dailyRoutineModel.footCare;

    // update button css classes
    const button1 = this.buttonFootCareGood;
    const button2 = this.buttonFootCareAverage;
    const button3 = this.buttonFootCareNeedsAttention;

    this.turnOffButton(button1);
    this.turnOffButton(button2);      
    this.turnOffButton(button3); 

    if(value === 'Good') 
      this.turnOnButton(button1);                     
    else if(value === 'Average') 
      this.turnOnButton(button2);   
    else if(value === 'Needs Attention') 
      this.turnOnButton(button3);   
  }

  updateSymptomaticEpisodesButtonCss() {
    
    const value = this.dailyRoutineModel.symptomaticEpisodes;

    // update button css classes
    const button1 = this.buttonSymptomaticEpisodes1;
    const button2 = this.buttonSymptomaticEpisodes2;
    const button3 = this.buttonSymptomaticEpisodes3;

    this.turnOffButton(button1);
    this.turnOffButton(button2);      
    this.turnOffButton(button3); 

    if(value === 'No') 
      this.turnOnButton(button1);                     
    else if(value === 'Mild') 
      this.turnOnButton(button2);   
    else if(value === 'Severe') 
      this.turnOnButton(button3);   
  
  }

  updateStressLevelButtonCss() {

    const value = this.dailyRoutineModel.stressLevel;

    // update button css classes
    const button1 = this.buttonStressLevel1;
    const button2 = this.buttonStressLevel2;
    const button3 = this.buttonStressLevel3;

    this.turnOffButton(button1);
    this.turnOffButton(button2);      
    this.turnOffButton(button3); 

    if(value === 'Low') 
      this.turnOnButton(button1);                     
    else if(value === 'Moderate') 
      this.turnOnButton(button2);   
    else if(value === 'High') 
      this.turnOnButton(button3);   
  }

  updateSleepQualityButtonCss() {

    const value = this.dailyRoutineModel.sleepQuality;

    // update button css classes
    const button1 = this.buttonSleepQuality1;
    const button2 = this.buttonSleepQuality2;
    const button3 = this.buttonSleepQuality3;

    this.turnOffButton(button1);
    this.turnOffButton(button2);      
    this.turnOffButton(button3); 
    
    if(value === 'Excellent') 
      this.turnOnButton(button1);                     
    else if(value === 'Regular') 
      this.turnOnButton(button2);   
    else if(value === 'Subpar') 
      this.turnOnButton(button3);   
  }

  turnOnButton(button: ElementRef) {
      this.renderer.addClass(button.nativeElement, "btn-secondary")
      this.renderer.removeClass(button.nativeElement, "btn-phoenix-secondary") 
  }

  turnOffButton(button:ElementRef) {
      this.renderer.addClass(button.nativeElement, "btn-phoenix-secondary")
      this.renderer.removeClass(button.nativeElement, "btn-secondary")     
  }

  changeBloodPressure() {

    const dailyRoutine = this.dailyRoutineModel;
    const isOk = this.validateBloodPressureValue(dailyRoutine.bloodPressure);
    const nativeElement = this.myBloodPressureField.nativeElement;
    if(isOk) {
      this.saveDailyRoutineModel();
      //this.toastr.success('New Blood Pressure value was saved!');          
      this.renderer.removeClass(nativeElement, 'is-invalid');
    }
    else {
      this.renderer.addClass(nativeElement, 'is-invalid');
      this.myBloodPressureField.nativeElement.focus();   
    }
    
  }

  validateBloodPressureValue(inputString: any): boolean {
    let result: boolean = false;

    if(inputString) {
      
      // Split the string using '/' as the delimiter
      const numbers: string[] = inputString.split('/');

      const firstNumber: number = Number(numbers[0]);
      const secondNumber: number = Number(numbers[1]);

      if (!isNaN(firstNumber) && !isNaN(firstNumber)) {
        //console.log("numbers: " + firstNumber + " & " + secondNumber);

        if(firstNumber > 0 && secondNumber > 0 && firstNumber > secondNumber)
          result = true;
      }
    }

    return result;
  }


  changeWeight() {
    const dailyRoutine = this.dailyRoutineModel;
    const isOk = this.isPositiveNumber(dailyRoutine.weight);
    const nativeElement = this.myWeightField.nativeElement;
    if(isOk) {
      this.saveDailyRoutineModel();
      //this.toastr.success('New Weight value was saved!');          
      this.renderer.removeClass(nativeElement, 'is-invalid');
    }
    else {
      this.renderer.addClass(nativeElement, 'is-invalid');
      this.myBloodPressureField.nativeElement.focus();   
    }
  }

  changeCalories() {
    const dailyRoutine = this.dailyRoutineModel;
    const isOk = this.isPositiveNumber(dailyRoutine.calories);
    const nativeElement = this.myCaloriesField.nativeElement;
    if(isOk) {
      this.saveDailyRoutineModel();
      //this.toastr.success('New Weight value was saved!');          
      this.renderer.removeClass(nativeElement, 'is-invalid');
    }
    else {
      this.renderer.addClass(nativeElement, 'is-invalid');
      this.myCaloriesField.nativeElement.focus();   
    }
  }

  changeCarbs() {
    const dailyRoutine = this.dailyRoutineModel;
    const isOk = this.isPositiveNumber(dailyRoutine.carbs);
    const nativeElement = this.myCarbsField.nativeElement;
    if(isOk) {
      this.saveDailyRoutineModel();
      //this.toastr.success('New Weight value was saved!');          
      this.renderer.removeClass(nativeElement, 'is-invalid');
    }
    else {
      this.renderer.addClass(nativeElement, 'is-invalid');
      this.myCarbsField.nativeElement.focus();   
    }
  }

  changeIntermittentFasting() {
    const dailyRoutine = this.dailyRoutineModel;
    const isOk = this.isPositiveNumber(dailyRoutine.intermittentFasting);
    const nativeElement = this.myIntermittentFastingField.nativeElement;
    if(isOk) {
      this.saveDailyRoutineModel();
      //this.toastr.success('New Weight value was saved!');          
      this.renderer.removeClass(nativeElement, 'is-invalid');
    }
    else {
      this.renderer.addClass(nativeElement, 'is-invalid');
      this.myIntermittentFastingField.nativeElement.focus();   
    }
  }

  isPositiveNumber(inputString: any): boolean {
    let result: boolean = false;

    if(inputString) {
      
      const firstNumber: number = Number(inputString);
      
      if (!isNaN(firstNumber)) {
        //console.log("numbers: " + firstNumber);

        if(firstNumber > 0)
          result = true;
      }
    }

    return result;
  }
    
}

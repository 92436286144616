import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ECharts } from 'echarts';
import flatpickr from 'flatpickr';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr';
import { FormsModule } from '@angular/forms';
import { BloodSugarModel } from 'src/app/shared/model/blood.sugar.model';
import { BloodSugarService } from 'src/app/shared/services/blood.sugar.service';
import { BloodSugarFormComponent } from './blood-sugar-form/blood-sugar-form.component';
import { NumberPair } from 'src/app/shared/model/util.model';
import { SharedEventService } from 'src/app/shared/services/shared.event.service';
import { UserModel } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/services/user.service';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DashboardSharedService } from 'src/app/shared/services/dashboard.shared.service';

@Component({
  selector: 'app-blood-sugar',
  templateUrl: './blood-sugar.component.html',
  styleUrls: ['./blood-sugar.component.css']
})

export class BloodSugarComponent implements OnInit, AfterViewInit {

  @ViewChild('addBloodSugarButton') addBloodSugarButton: ElementRef | any;

  faPlus = faPlus;

  @Input() public userModel: UserModel | undefined;
  @Input() public widgetHeight: UserModel | undefined;
  //userModel: UserModel = new UserModel();

  isBloodSugarDataEmpty = false;

  dataRandom: any[] = [];
  dataBeforeMeals: any[] = [];
  dataPostMeals: any[] = [];

  dataFasting: any[] = [];
  dataStressed: any[] = [];
  dataEpisode: any[] = [];

  chartData: any[] = [];
  chartOption: EChartsOption;
  echartsInstance: ECharts | undefined;

  constructor(
      private bloodSugarService: BloodSugarService,
      private eventService: SharedEventService,
      private userService: UserService,
      public dashboardSharedService: DashboardSharedService,
    ) {
      this.chartOption = {};
    }
  

  addBloodButton() {
    //this.myBloodSugarField.nativeElement.focus();
  }

  onChartInit(ec: any) {
    this.echartsInstance = ec;
    //this.echartsInstance?.hideLoading();
  }

  addBloodSugar() {
    //alert(123)
  }

  ngAfterViewInit() {
  }


  eventListener(message: string) {

    //console.log('eventListener: ' + message) 
    
    if(message === this.eventService.FASTING_BLOOD_SUGAR_FORM) {      
      // event to open a blood sugar form
      const button  = this.addBloodSugarButton.nativeElement;
      if(button) {
        button.click();
      }
    }
    else if(message === this.eventService.BLOOD_SUGAR_DATA_CHANGE) {   
       // event to reload a blood sugar values in this widget
      //console.log('got new blood sugar data') 
      this.reloadChartData();
    }

  }


  ngOnInit() {

    // subscribe to userModel
    this.userService.userData$.subscribe({
      next: (value) => {
        if(value) {
          this.userModel = value;   
          //this.loadChartData();     
          //this.updateChartData(this.chartData);                    
          // TODO - uncomment one of above - maybe

          // subscribe to blood sugar
          const days = this.userModel.dataConfig.rangeInDays; 
          this.dashboardSharedService.getBloodSugarData(days).subscribe({
            next: (value) => {
              //console.log("blood sugar subsribe to blood data: " + value?.length)
              if(value) {
                this.chartData = value;
                this.echartsInstance?.hideLoading();
                //this.updateChartData_old();
                this.updateChartData(value);    
                
                if(value.length === 0) this.isBloodSugarDataEmpty = true;
                else this.isBloodSugarDataEmpty = false;
              }
            }
          });
        }
      },
    });
    
    // subscribe to events from Shared Event Service
    this.eventService.messageEmitter.subscribe((message: string) => {      
      this.eventListener(message);
    });

    // load initial blood sugar data
    //this.loadChartData();  
        
  }

  reloadChartData() {

    if(this.userModel) {
      const rangeInDays = this.userModel.dataConfig.rangeInDays;    
      this.dashboardSharedService.loadBloodSugarData(rangeInDays);
    }

  }

  configLeversChange() {

    if(this.userModel) {
      // save user profile data
      this.userService.saveProfile(this.userModel).subscribe({
        next: (value) => {
          //console.log("saved")
        },
      });

      // refresh chart
      this.updateChartData(this.chartData);
    }
  }


  updateChartData(value: BloodSugarModel[]) {

    // Random Blood Sugar (RBS)
    this.dataRandom = value.filter((item: BloodSugarModel) => {
      return (item.mealTag === 'Random Blood Sugar (RBS)');
    }).map(item => [item.creationDate, item.mgValue]); 

    // Before Meals (Preprandial)
    this.dataBeforeMeals = value.filter((item: BloodSugarModel) => {
      return (item.mealTag === 'Before Meals (Preprandial)');
    }).map(item => [item.creationDate, item.mgValue]); 

    // Post Meals (Postprandial)
    this.dataPostMeals = value.filter((item: BloodSugarModel) => {
      return (item.mealTag === 'Post Meals (Postprandial)');
    }).map(item => [item.creationDate, item.mgValue]); 

    // Fasting Blood Sugar
    this.dataFasting = value.filter((item: BloodSugarModel) => {
      return (item.mealTag === 'Fasting Blood Sugar');
    }).map(item => [item.creationDate, item.mgValue]); 

    /*
    // Non Fasting
    this.dataNonFasting = value.filter((item: BloodSugarModel) => {
      return (item.mealTag !== 'Fasting Blood Sugar')        
    }).map(item => [item.creationDate, item.mgValue]); 
    */
    
    // Feeling Unwell or Stressed
    this.dataStressed = value.filter((item: BloodSugarModel) => {
      return (item.mealTag === 'Feeling Unwell or Stressed');
    }).map(item => [item.creationDate, item.mgValue]); 

    // During Symptomatic Episode
    this.dataEpisode = value.filter((item: BloodSugarModel) => {
      return (item.mealTag === 'During Symptomatic Episode');
    }).map(item => [item.creationDate, item.mgValue]); 
    
    //console.log("dataFasting: " + dataFasting);
    //console.log("dataNonFasting: " + dataNonFasting);

    //this.chartData = value.map(item => [item.creationDate, item.mgValue]); 
    //console.log("chart data: " + this.chartData);

    //this.chartData = dataNonFasting;

    // Convert string-based time to JavaScript Date objects
    const formattedData = this.dataFasting.map(([time, value]) => [new Date(time), value]);

    // Calculate the average of the y-values
    const averageY = formattedData.reduce((sum, point) => sum + point[1], 0) / formattedData.length;


    if(this.userModel) {
      const myChartOption: EChartsOption = {

        legend: {
          show: false,
          selected: {
            'Random Blood Sugar (RBS)': this.userModel.journeyConfig.showRandomBloodSugar,          
            'Before Meals (Preprandial)': this.userModel.journeyConfig.showBeforeMeals,
            'Post Meals (Postprandial)': this.userModel.journeyConfig.showPostMeals,
            'Fasting Blood Sugar': this.userModel.journeyConfig.showFastingBloodSugar,
            'Feeling Unwell or Stressed': this.userModel.journeyConfig.showFeelingUnwellOrStressed,
            'During Symptomatic Episode': this.userModel.journeyConfig.showDuringSymptomaticEpisode,
          },
        },
        grid: {
          left: '10px',   // Adjust left padding
          right: '10px',  // Adjust right padding
          top: '10px',    // Adjust top padding
          bottom: '10px', // Adjust bottom padding
          containLabel: true
        },
        xAxis: {
          type: 'time', // Set X-axis type to 'time'
          axisPointer: {
            type: 'line'
          }      
          //boundaryGap: false, // Adjust boundaryGap based on your preference.
        },
        yAxis: {
          type: 'value',          
          axisPointer: {
            type: 'line'
          },
          axisLabel: {
            formatter: '{value} mg/dL',
          }  
        },
        series: [
          {
            name: 'Random Blood Sugar (RBS)',
            data: this.dataRandom,
            type: 'scatter', // or other chart type,  
            //type: 'line', // or other chart type,  
            //smooth: true,
            symbol: 'circle', 
            symbolSize: 10,
            itemStyle: {
              color: '#007bff',
            },      
          }, 
          {
            name: 'Before Meals (Preprandial)',
            data: this.dataBeforeMeals,
            type: 'scatter', // or other chart type,  
            //type: 'line', // or other chart type,  
            //smooth: true,
            symbol: 'circle', 
            symbolSize: 10,
            itemStyle: {
              color: '#007bff',
            },      
          }, 
          {
            name: 'Post Meals (Postprandial)',
            data: this.dataPostMeals,
            type: 'scatter', // or other chart type,  
            //type: 'line', // or other chart type,  
            //smooth: true,
            symbol: 'circle', 
            symbolSize: 10,
            itemStyle: {
              color: '#007bff',
            },      
          },         
          {
            name: 'Fasting Blood Sugar',
            data: this.dataFasting,                    
            type: 'line', // or other chart type,  
            smooth: true,
            symbol: 'circle', 
            symbolSize: 10,
            itemStyle: {
              color: '#3ab14a',          
            },         
          },  
          {
            name: 'Feeling Unwell or Stressed',
            data: this.dataStressed,
            type: 'scatter', // or other chart type,  
            //smooth: true,
            symbol: 'circle', 
            symbolSize: 10,
            itemStyle: {
              color: '#d06405',          
            }, 
          },  
          {
            name: 'During Symptomatic Episode',
            data: this.dataEpisode,
            type: 'scatter', // or other chart type,  
            //smooth: true,
            symbol: 'circle', 
            symbolSize: 10,
            itemStyle: {
              color: '#d00505',          
            }, 
          },  
          // Add a custom mark line for the average
          /*{
            name: 'Average',
            type: 'line',
            data: [     
                [formattedData[0][0], averageY],          
                [formattedData[formattedData.length - 1][0], averageY],
            ],
            lineStyle: {
              type: 'dotted',
              width: 1,
              color: 'red'
            },
            itemStyle: {
              color: 'grey',  
              borderColor: 'white'                      
            },  
                    
          },  */   
        ],
        tooltip: {
          show: true,        
          trigger: 'axis', // Display tooltips on hover over data points
          axisPointer: {
            type: 'line',           
          },
          //valueFormatter: value => value.toFixed(2) + ' ml',
          valueFormatter: (value:any) => value + ' mg/dL',
          //formatter: '{HH}:{mm}<br> {MMM} {dd}, {yyyy}<br> {c}'
        },      
      };

      this.chartOption = myChartOption;
    }

  }

  disableBloodSugarWidget() {
    if(this.userModel) {
      const model: UserModel = this.userModel;
      model.journeyConfig.coreBloodSugar = false;

      this.userService.saveProfile(model).subscribe({
        next:(value) => {
          // nothing 
        }
      });
    }
  }

}

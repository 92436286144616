<!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="alternate-landing" style="--phoenix-scroll-margin-top: 1.2rem;">
        <div class="bg-white sticky-top landing-navbar" data-navbar-shadow-on-scroll="data-navbar-shadow-on-scroll">
          <nav class="navbar navbar-expand-lg container-small px-3 px-lg-7 px-xxl-3">
            <a class="navbar-brand flex-1 flex-lg-grow-0" routerLink="/">
              <div class="d-flex align-items-center"><img src="../../assets/img/icons/logo3.png" alt="phoenix" width="27" />
                <p class="logo-text ms-2">Novalumen</p>
              </div>
            </a>
            <div class="d-lg-none">
              <div class="theme-control-toggle fa-icon-wait px-2">
                <input class="form-check-input ms-0 theme-control-toggle-input" type="checkbox" data-theme-control="phoenixTheme" value="dark" id="themeControlToggleSm" />
                <label class="mb-0 theme-control-toggle-label theme-control-toggle-light" for="themeControlToggleSm" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch theme"><span class="icon" data-feather="moon"></span></label>
                <label class="mb-0 theme-control-toggle-label theme-control-toggle-dark" for="themeControlToggleSm" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch theme"><span class="icon" data-feather="sun"></span></label>
              </div>
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <div class="border-bottom border-bottom-lg-0 mb-2">
                <div class="search-box d-inline d-lg-none">
                  <form class="position-relative" data-bs-toggle="search" data-bs-display="static">
                    <input class="form-control search-input search rounded-pill my-4" type="search" placeholder="Search" aria-label="Search" />
                    <span class="fas fa-search search-box-icon"></span>
  
                  </form>
                </div>
              </div>
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item border-bottom border-bottom-lg-0"><a class="nav-link lh-1 py-0 fs--1 fw-bold py-3 active" aria-current="page" href="#">Home</a></li>
                <li class="nav-item border-bottom border-bottom-lg-0"><a class="nav-link lh-1 py-0 fs--1 fw-bold py-3" href="/terms">Terms</a></li>
                <li class="nav-item border-bottom border-bottom-lg-0"><a class="nav-link lh-1 py-0 fs--1 fw-bold py-3" href="/privacy">Privacy</a></li>                
              </ul>
              <div class="d-grid d-lg-flex align-items-center">
                <div class="nav-item d-flex align-items-center d-none d-lg-block pe-2">
                  <div class="theme-control-toggle fa-icon-wait px-2">
                    <input class="form-check-input ms-0 theme-control-toggle-input" type="checkbox" data-theme-control="phoenixTheme" value="dark" id="themeControlToggle" />
                    <label class="mb-0 theme-control-toggle-label theme-control-toggle-light" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch theme"><span class="icon" data-feather="moon"></span></label>
                    <label class="mb-0 theme-control-toggle-label theme-control-toggle-dark" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch theme"><span class="icon" data-feather="sun"></span></label>
                  </div>
                </div>
                <!--
                <a class="text-700 hover-text-1100 px-2 d-none d-lg-inline lh-sm" href="#" data-bs-toggle="modal" data-bs-target="#searchBoxModal"><span data-feather="search" style="height: 20px; width: 20px;"></span></a>
                <a class="btn btn-phoenix-primary text-900 order-1 order-lg-0 ps-4 me-lg-2" routerLink="/login">Sign in</a>
                -->
              </div>
            </div>
          </nav>
        </div>
        
        
       
  
  
  
  
        <!-- ============================================-->
        <!-- <section> begin ============================-->
        <section class="pt-13 pb-10" id="feature">
  
          <div class="container-small px-lg-7 px-xxl-3">
            <div class="mb-10 mb-md-5">

                <h2>PRIVACY NOTICE</h2>
                <h6 class="text-info mt-4">Last updated January 1st, 2024</h6>
                <p class="mt-4">
                    This privacy notice for Novalumen ("we," "us," or "our"), describes how and why we
                    might collect, store, use, and/or share ("process") your information when you use our
                    services ("Services"), such as when you:
                </p>
                <p>
                    <b>Questions or concerns?</b> Reading this privacy notice will help you understand your
                    privacy rights and choices. If you do not agree with our policies and practices, please
                    do not use our Services.
                </p>

                <h3 class="mt-6">SUMMARY OF KEY POINTS</h3> 
                <p class="mt-4">
                    This summary provides key points from our privacy notice, but you can find
                    out more details about any of these topics by clicking the link following each
                    key point or by using our table of contents below to find the section you are
                    looking for.
                </p>
                <p>
                    <b>What personal information do we process?</b> When you visit, use, or navigate our
                    Services, we may process personal information depending on how you interact with
                    us and the Services, the choices you make, and the products and features you use.
                    Learn more about personal information you disclose to us.
                </p>
                <p>
                    <b>Do we process any sensitive personal information?</b> We do not process sensitive
                    personal information.
                </p>
                <p>
                    <b>Do we receive any information from third parties?</b> We may receive information
                    from public databases, marketing partners, social media platforms, and other outside
                    sources. Learn more about information collected from other sources.
                </p>
                <p>
                    <b>How do we process your information?</b> We process your information to provide,
                    improve, and administer our Services, communicate with you, for security and fraud
                    prevention, and to comply with law. We may also process your information for other
                    purposes with your consent. We process your information only when we have a valid
                    legal reason to do so. Learn more about how we process your information.
                </p>
                <p>
                    <b>In what situations and with which parties do we share personal information?</b>
                    We may share information in specific situations and with specific third parties. Learn
                    more about when and with whom we share your personal information.
                </p>
                <p>
                    <b>What are your rights?</b> Depending on where you are located geographically, the
                    applicable privacy law may mean you have certain rights regarding your personal
                    information. Learn more about your privacy rights.
                </p>
                <p>
                    <b>How do you exercise your rights?</b> The easiest way to exercise your rights is by
                    submitting a data subject access request, or by contacting us. We will consider and
                    act upon any request in accordance with applicable data protection laws.
                </p>
                <p>
                    Want to learn more about what we do with any information we collect? Review the
                    privacy notice in full.
                </p>


                <h3 class="mt-6">TABLE OF CONTENTS</h3>
                <ol class="mt-4">
                    <li>WHAT INFORMATION DO WE COLLECT?</li>
                    <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
                    <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
                    <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
                    <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
                    <li>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
                    <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                    <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
                    <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
                    <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                    <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
                    <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
                    <li>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
                </ol>


                <h3 class="mt-6">1. WHAT INFORMATION DO WE COLLECT?</h3>
                <h4 class="mt-4">Personal information you disclose to us</h4>
                <p class="mt-4">
                    <b>In Short:</b> We collect personal information that you provide to us.
                    We collect personal information that you voluntarily provide to us when you register
                    on the Services, express an interest in obtaining information about us or our products
                    and Services, when you participate in activities on the Services, or otherwise when
                    you contact us.
                </p>
                <p>
                    <b>Sensitive Information.</b> We do not process sensitive information.
                </p>
                <p>
                    All personal information that you provide to us must be true, complete, and accurate,
                    and you must notify us of any changes to such personal information.
                </p>
                
                <h4 class="mt-4">Information automatically collected</h4>    
                <p class="mt-4">
                    <b>In Short:</b> Some information — such as your Internet Protocol (IP) address and/or
                    browser and device characteristics — is collected automatically when you visit our
                    Services.
                </p>    
                <p>
                    We automatically collect certain information when you visit, use, or navigate the
                    Services. This information does not reveal your specific identity (like your name or
                    contact information) but may include device and usage information, such as your IP
                    address, browser and device characteristics, operating system, language
                    preferences, referring URLs, device name, country, location, information about how
                    and when you use our Services, and other technical information. This information is
                    primarily needed to maintain the security and operation of our Services, and for our
                    internal analytics and reporting purposes.
                </p>      
                <p>
                    Like many businesses, we also collect information through cookies and similar
                    technologies.
                </p>  

                <h3 class="mt-6">2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
                <p class="mt-4">
                    <b>In Short:</b> We process your information to provide, improve, and administer our
                    Services, communicate with you, for security and fraud prevention, and to comply
                    with law. We may also process your information for other purposes with your consent.
                </p>
                <p>
                    We process your personal information for a variety of reasons, depending on
                    how you interact with our Services, including:
                </p>

                <h3 class="mt-6">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>
                <p class="mt-4">
                    <b>In Short:</b> We may share information in specific situations described in this section
                    and/or with the following third parties.
                </p>
                <p>
                    We may need to share your personal information in the following situations:
                </p>
                <ul>
                    <li>
                        <b>Business Transfers.</b> We may share or transfer your information in connection
                        with, or during negotiations of, any merger, sale of company assets, financing,
                        or acquisition of all or a portion of our business to another company.
                    </li>
                    <li>
                        <b>Affiliates.</b> We may share your information with our affiliates, in which case we
                        will require those affiliates to honor this privacy notice. Affiliates include our
                        parent company and any subsidiaries, joint venture partners, or other
                        companies that we control or that are under common control with us.
                    </li>
                    <li>
                        <b>Business Partners.</b> We may share your information with our business
                        partners to offer you certain products, services, or promotions.
                    </li>
                </ul>


                <h3 class="mt-6">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
                <p class="mt-4">
                    <b>In Short:</b> We may use cookies and other tracking technologies to collect and store
                    your information.
                </p>
                <p>
                    We may use cookies and similar tracking technologies (like web beacons and pixels)
                    to access or store information. Specific information about how we use such
                    technologies and how you can refuse certain cookies is set out in our Cookie Notice.
                </p>


                <h3 class="mt-6">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h3>
                <p class="mt-4">
                    <b>In Short:</b> If you choose to register or log in to our Services using a social media
                    account, we may have access to certain information about you.
                </p>
                <p>
                    Our Services offer you the ability to register and log in using your third-party social
                    media account details (like your Facebook or Twitter logins). Where you choose to do
                    this, we will receive certain profile information about you from your social media
                    provider. The profile information we receive may vary depending on the social media
                    provider concerned, but will often include your name, email address, friends list, and
                    profile picture, as well as other information you choose to make public on such a
                    social media platform.
                </p>
                <p>
                    We will use the information we receive only for the purposes that are described in this
                    privacy notice or that are otherwise made clear to you on the relevant Services.
                    Please note that we do not control, and are not responsible for, other uses of your
                    personal information by your third-party social media provider. We recommend that
                    you review their privacy notice to understand how they collect, use, and share your
                    personal information, and how you can set your privacy preferences on their sites
                    and apps.
                </p>


                <h3 class="mt-6">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h3>
                <p class="mt-4">
                    <b>In Short:</b> We may transfer, store, and process your information in countries other
                    than your own.
                </p>
                <p>
                    Our servers are located in. If you are accessing our Services from outside, please be
                    aware that your information may be transferred to, stored, and processed by us in
                    our facilities and by those third parties with whom we may share your personal
                    information (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                    INFORMATION?" above), in and other countries.
                </p>
                <p>
                    If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or
                    Switzerland, then these countries may not necessarily have data protection laws or
                    other similar laws as comprehensive as those in your country. However, we will take
                    all necessary measures to protect your personal information in accordance with this
                    privacy notice and applicable law.
                </p>


                <h3 class="mt-6">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
                <p class="mt-4">
                    <b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes
                    outlined in this privacy notice unless otherwise required by law.
                </p>
                <p>
                    We will only keep your personal information for as long as it is necessary for the
                    purposes set out in this privacy notice, unless a longer retention period is required or
                    permitted by law (such as tax, accounting, or other legal requirements).
                </p>
                <p>
                    When we have no ongoing legitimate business need to process your personal
                    information, we will either delete or anonymize such information, or, if this is not
                    possible (for example, because your personal information has been stored in backup
                    archives), then we will securely store your personal information and isolate it from
                    any further processing until deletion is possible.
                </p>


                <h3 class="mt-6">8. DO WE COLLECT INFORMATION FROM MINORS?</h3>
                <p class="mt-4">
                    <b>In Short:</b> We do not knowingly collect data from or market to children under 18 years
                    of age.
                </p>
                <p>
                    We do not knowingly solicit data from or market to children under 18 years of age. By
                    using the Services, you represent that you are at least 18 or that you are the parent
                    or guardian of such a minor and consent to such minor dependent’s use of the
                    Services. If we learn that personal information from users less than 18 years of age
                    has been collected, we will deactivate the account and take reasonable measures to
                    promptly delete such data from our records. If you become aware of any data we
                    may have collected from children under age 18, please contact us at info&#64;novalumen.com
                </p>
                

                <h3 class="mt-6">9. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
                <p class="mt-4">
                    <b>In Short:</b> You may review, change, or terminate your account at any time.
                </p>
                <p>
                    <b>Withdrawing your consent:</b> If we are relying on your consent to process your
                    personal information, which may be express and/or implied consent depending on
                    the applicable law, you have the right to withdraw your consent at any time. You can
                    withdraw your consent at any time by contacting us by using the contact details
                    provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                    below.
                </p>
                <p>
                    However, please note that this will not affect the lawfulness of the processing before
                    its withdrawal nor, when applicable law allows, will it affect the processing of your
                    personal information conducted in reliance on lawful processing grounds other than
                    consent.
                </p>

                <h4>Account Information</h4>

                <p class="mt-2">
                    If you would at any time like to review or change the information in your account or
                    terminate your account, you can:
                </p>
                <p>
                    Upon your request to terminate your account, we will deactivate or delete your
                    account and information from our active databases. However, we may retain some
                    information in our files to prevent fraud, troubleshoot problems, assist with any
                    investigations, enforce our legal terms and/or comply with applicable legal
                    requirements.
                </p>


                <h3 class="mt-6">10. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
                <p class="mt-4">
                    Most web browsers and some mobile operating systems and mobile applications
                    include a Do-Not-Track ("DNT") feature or setting you can activate to signal your
                    privacy preference not to have data about your online browsing activities monitored 
                    and collected. At this stage no uniform technology standard for recognizing and
                    implementing DNT signals has been finalized. As such, we do not currently respond
                    to DNT browser signals or any other mechanism that automatically communicates
                    your choice not to be tracked online. If a standard for online tracking is adopted that
                    we must follow in the future, we will inform you about that practice in a revised
                    version of this privacy notice.
                </p>


                <h3 class="mt-6">11. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
                <p class="mt-4">
                    <b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant
                    laws.
                </p>
                <p>
                    We may update this privacy notice from time to time. The updated version will be
                    indicated by an updated "Revised" date and the updated version will be effective as
                    soon as it is accessible. If we make material changes to this privacy notice, we may
                    notify you either by prominently posting a notice of such changes or by directly
                    sending you a notification. We encourage you to review this privacy notice frequently
                    to be informed of how we are protecting your information.
                </p>


                <h3 class="mt-6">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
                <p class="mt-4">
                    If you have questions or comments about this notice, you may contact us by post at:
                </p>
                <p>
                    Novalumen LLC<br>
                    2201 Menaul Boulevard Northeast<br>
                    Albuquerque, New Mexico 87107
                    USA
                </p>


                <h3 class="mt-6">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
                <p class="mt-4">
                    Based on the applicable laws of your country, you may have the right to request
                    access to the personal information we collect from you, change that information, or
                    delete it. To request to review, update, or delete your personal information, please fill
                    out and submit a data subject access request.
                </p>
            </div>

            
          </div>
          <!-- end of .container-->
  
        </section>
        <!-- <section> close ============================-->
        <!-- ============================================-->
  
  
  
  
        
  
  
       
  
  
        
        <!-- ============================================-->
        <!-- <section> begin ============================-->
        <section class="bg-1100 dark__bg-1000">
  
          <div class="container-small px-lg-7 px-xxl-3">
            <div class="row gx-xxl-8 gy-5 align-items-center mb-5">
              <div class="col-xl-auto text-center"><a href="#"><img src="../../assets/img/icons/logo-white.png" height="48" alt="" /></a></div>
              <div class="col-xl-auto flex-1">
                <ul class="list-unstyled d-flex justify-content-center flex-wrap mb-0 border-end-xl border-dashed border-800 gap-3 gap-xl-8 pe-xl-5 pe-xxl-8 w-75 w-md-100 mx-auto">
                  <li><a class="text-300 dark__text-300" href="">Contact us</a></li>
                  <li><a class="text-300 dark__text-300" href="">Newsroom</a></li>
                  <li><a class="text-300 dark__text-300" href="">Opportunities</a></li>
                  <li><a class="text-300 dark__text-300" href="">Login</a></li>
                  <li><a class="text-300 dark__text-300" href="">Sign Up</a></li>
                  <li><a class="text-300 dark__text-300" href="">Support</a></li>
                  <li><a class="text-300 dark__text-300" href="">FAQ</a></li>
                </ul>
              </div>
              <div class="col-xl-auto">
                <div class="d-flex align-items-center justify-content-center gap-8"><a class="text-white dark__text-white" href="#!"> <span class="fa-brands fa-facebook"></span></a><a class="text-white dark__text-white" href="#!"> <span class="fa-brands fa-twitter"></span></a><a class="text-white dark__text-white" href="#!"> <span class="fa-brands fa-linkedin-in"></span></a></div>
              </div>
            </div>
            <hr class="text-800" />
            <div class="d-sm-flex flex-between-center text-center">
              <p class="text-600 mb-0">Copyright © Company Name</p>
              <p class="text-600 mb-0">Made with love by <a href="https://themewagon.com">ThemeWagon</a></p>
            </div>
          </div>
          <!-- end of .container-->
  
        </section>
        <!-- <section> close ============================-->
        <!-- ============================================-->
  
  
        <div class="support-chat-container">
          <div class="container-fluid support-chat">
            <div class="card bg-white">
              <div class="card-header d-flex flex-between-center px-4 py-3 border-bottom">
                <h5 class="mb-0 d-flex align-items-center gap-2">Demo widget<span class="fa-solid fa-circle text-success fs--3"></span></h5>
                <div class="btn-reveal-trigger">
                  <button class="btn btn-link p-0 dropdown-toggle dropdown-caret-none transition-none d-flex" type="button" id="support-chat-dropdown" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span class="fas fa-ellipsis-h text-900"></span></button>
                  <div class="dropdown-menu dropdown-menu-end py-2" aria-labelledby="support-chat-dropdown"><a class="dropdown-item" href="#!">Request a callback</a><a class="dropdown-item" href="#!">Search in chat</a><a class="dropdown-item" href="#!">Show history</a><a class="dropdown-item" href="#!">Report to Admin</a><a class="dropdown-item btn-support-chat" href="#!">Close Support</a></div>
                </div>
              </div>
              <div class="card-body chat p-0">
                <div class="d-flex flex-column-reverse scrollbar h-100 p-3">
                  <div class="text-end mt-6"><a class="mb-2 d-inline-flex align-items-center text-decoration-none text-1100 hover-bg-soft rounded-pill border border-primary py-2 ps-4 pe-3" href="#!">
                      <p class="mb-0 fw-semi-bold fs--1">I need help with something</p><span class="fa-solid fa-paper-plane text-primary fs--1 ms-3"></span>
                    </a><a class="mb-2 d-inline-flex align-items-center text-decoration-none text-1100 hover-bg-soft rounded-pill border border-primary py-2 ps-4 pe-3" href="#!">
                      <p class="mb-0 fw-semi-bold fs--1">I can’t reorder a product I previously ordered</p><span class="fa-solid fa-paper-plane text-primary fs--1 ms-3"></span>
                    </a><a class="mb-2 d-inline-flex align-items-center text-decoration-none text-1100 hover-bg-soft rounded-pill border border-primary py-2 ps-4 pe-3" href="#!">
                      <p class="mb-0 fw-semi-bold fs--1">How do I place an order?</p><span class="fa-solid fa-paper-plane text-primary fs--1 ms-3"></span>
                    </a><a class="false d-inline-flex align-items-center text-decoration-none text-1100 hover-bg-soft rounded-pill border border-primary py-2 ps-4 pe-3" href="#!">
                      <p class="mb-0 fw-semi-bold fs--1">My payment method not working</p><span class="fa-solid fa-paper-plane text-primary fs--1 ms-3"></span>
                    </a>
                  </div>
                  <div class="text-center mt-auto">
                    <div class="avatar avatar-3xl status-online"><img class="rounded-circle border border-3 border-white" src="../../assets/img/team/30.webp" alt="" /></div>
                    <h5 class="mt-2 mb-3">Eric</h5>
                    <p class="text-center text-black mb-0">Ask us anything – we’ll get back to you here or by email within 24 hours.</p>
                  </div>
                </div>
              </div>
              <div class="card-footer d-flex align-items-center gap-2 border-top ps-3 pe-4 py-3">
                <div class="d-flex align-items-center flex-1 gap-3 border rounded-pill px-4">
                  <input class="form-control outline-none border-0 flex-1 fs--1 px-0" type="text" placeholder="Write message" />
                  <label class="btn btn-link d-flex p-0 text-500 fs--1 border-0" for="supportChatPhotos"><span class="fa-solid fa-image"></span></label>
                  <input class="d-none" type="file" accept="image/*" id="supportChatPhotos" />
                  <label class="btn btn-link d-flex p-0 text-500 fs--1 border-0" for="supportChatAttachment"> <span class="fa-solid fa-paperclip"></span></label>
                  <input class="d-none" type="file" id="supportChatAttachment" />
                </div>
                <button class="btn p-0 border-0 send-btn"><span class="fa-solid fa-paper-plane fs--1"></span></button>
              </div>
            </div>
          </div>
          <button class="btn p-0 border border-200 btn-support-chat"><span class="fs-0 btn-text text-primary text-nowrap">Chat demo</span><span class="fa-solid fa-circle text-success fs--1 ms-2"></span><span class="fa-solid fa-chevron-down text-primary fs-1"></span></button>
        </div>
      </main>
      <!-- ===============================================-->
      <!--    End of Main Content-->
      <!-- ===============================================-->
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/services/user.service';
import flatpickr from 'flatpickr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, AfterViewInit {

  @ViewChild('buttonBloodSugarUnits1') buttonBloodSugarUnits1: ElementRef | any;
  @ViewChild('buttonBloodSugarUnits2') buttonBloodSugarUnits2: ElementRef | any;

  @ViewChild('buttonSubscription1') buttonSubscription1: ElementRef | any;
  @ViewChild('buttonSubscription2') buttonSubscription2: ElementRef | any;
  @ViewChild('buttonSubscription3') buttonSubscription3: ElementRef | any;

  @ViewChild('buttonDiabetesType1') buttonDiabetesType1: ElementRef | any;
  @ViewChild('buttonDiabetesType2') buttonDiabetesType2: ElementRef | any;
  @ViewChild('buttonDiabetesType3') buttonDiabetesType3: ElementRef | any;

  @ViewChild('buttonGender1') buttonGender1: ElementRef | any;
  @ViewChild('buttonGender2') buttonGender2: ElementRef | any;
  @ViewChild('buttonGender3') buttonGender3: ElementRef | any;

  @ViewChild('fieldBirthYear') fieldBirthYear: ElementRef | any;
  @ViewChild('fieldDisplayName') fieldDisplayName: ElementRef | any;
  @ViewChild('fieldEmailAlternative') fieldEmailAlternative: ElementRef | any;

  public userModel: UserModel = new UserModel();

  formBirthYear = "";


  dateOptions:FlatpickrDefaultsInterface = {
    enableTime: false,
    noCalendar: false,
    dateFormat: 'F d, Y',
  }

  constructor(
    private userService: UserService,  
    private renderer: Renderer2,  
    private toastr: ToastrService,    
  ) {}

  ngOnInit(): void {  
    // load initial userModel value
    //this.userModel = this.actRoute.snapshot.data['userModel'] as UserModel;     
  }
  
  ngAfterViewInit(): void {

    // load userModel
    this.userService.getProfile().subscribe({
      next: (value) => {
        if(value) {
          this.userModel = value;   
          if(value.dataConfig.birthYear !== null)
            this.formBirthYear = "" +   value.dataConfig.birthYear
          this.initFormValues();     
        }
      },
    });

  }

  initFormValues() {

    
    if(this.userModel.dataConfig.bloodSugarMeasureType) {
      this.updateBloodSugarUnitsButtonCss();
    }
    if(this.userModel.dataConfig.diabetesType) {
      this.updateDiabetesTypeButtonCss();
    }
    if(this.userModel.dataConfig.gender) {
      this.updateGenderButtonCss();
    }    
    if(this.userModel.dataConfig.birthYear === 0) {
      this.userModel.dataConfig.birthYear = null;
    }
    
  }

  saveProfile() {

    // call API to crate a new measure
    this.userService.saveProfile(this.userModel).subscribe({
      next: (value) => {        
        this.toastr.success('Profile data has been updated!');                  
      },
    });
  }

  changeEmailAlternative() {
    
    const value = this.userModel.emailAlternative;
    const nativeElement = this.fieldEmailAlternative.nativeElement;
    this.saveProfile();
  }

  changeDisplayName() {

    const value = this.userModel.displayName;
    const nativeElement = this.fieldDisplayName.nativeElement;

    if(value) {     
      this.renderer.removeClass(nativeElement, 'is-invalid');
      this.saveProfile();
    } 
    else {
      this.renderer.addClass(nativeElement, 'is-invalid');
      this.fieldDisplayName.nativeElement.focus();   
    }
  }

  changeBirthYear() {
  
    const value = this.userModel.dataConfig.birthYear;
    const nativeElement = this.fieldBirthYear.nativeElement;

    if(value && value>1900) {     
      this.renderer.removeClass(nativeElement, 'is-invalid');
      this.saveProfile();
    } 
    else {
      this.renderer.addClass(nativeElement, 'is-invalid');
      this.fieldDisplayName.nativeElement.focus();   
    }
  }

  clickBloodSugarUnits(value: string) {
    this.userModel.dataConfig.bloodSugarMeasureType = value;
    this.updateBloodSugarUnitsButtonCss();
    this.saveProfile();
  }

  clickDiabetesType(value: string) {
    this.userModel.dataConfig.diabetesType = value;
    this.updateDiabetesTypeButtonCss();
    this.saveProfile();
  }

  clickGender(value: string) {
    this.userModel.dataConfig.gender = value;
    this.updateGenderButtonCss();
    this.saveProfile();
  }
  
  updateBloodSugarUnitsButtonCss() {

    const value = this.userModel.dataConfig.bloodSugarMeasureType;
    
    // update button css classes
    const button1 = this.buttonBloodSugarUnits1;
    const button2 = this.buttonBloodSugarUnits2;
    
    this.turnOffButton(button1);
    this.turnOffButton(button2);      

    if(value === 'mg/dL') 
      this.turnOnButton(button1);                     
    else if(value === 'mmol/L') 
      this.turnOnButton(button2);             
  }

  updateDiabetesTypeButtonCss() {

    const value = this.userModel.dataConfig.diabetesType;
    
    // update button css classes
    const button1 = this.buttonDiabetesType1;
    const button2 = this.buttonDiabetesType2;
    const button3 = this.buttonDiabetesType3;
    
    this.turnOffButton(button1);
    this.turnOffButton(button2);      
    this.turnOffButton(button3);   

    if(value === 'Type 1') 
      this.turnOnButton(button1);                     
    else if(value === 'Type 2') 
      this.turnOnButton(button2);             
    else if(value === 'None') 
      this.turnOnButton(button3);             
  }

  updateGenderButtonCss() {

    const value = this.userModel.dataConfig.gender;
    
    // update button css classes
    const button1 = this.buttonGender1;
    const button2 = this.buttonGender2;
    const button3 = this.buttonGender3;
    
    this.turnOffButton(button1);
    this.turnOffButton(button2);      
    this.turnOffButton(button3);   

    if(value === 'Male') 
      this.turnOnButton(button1);                     
    else if(value === 'Female') 
      this.turnOnButton(button2);             
    else if(value === 'Non-binary') 
      this.turnOnButton(button3);             
  }


  turnOnButton(button: ElementRef) {
      this.renderer.addClass(button.nativeElement, "btn-secondary")
      this.renderer.removeClass(button.nativeElement, "btn-phoenix-secondary") 
  }

  turnOffButton(button:ElementRef) {      
      this.renderer.addClass(button.nativeElement, "btn-phoenix-secondary")
      this.renderer.removeClass(button.nativeElement, "btn-secondary")     
  }

}

import { Component } from '@angular/core';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr';
import flatpickr from 'flatpickr'; 

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent {

  public time: string = "";

  environment = environment;


  timeOptions:FlatpickrDefaultsInterface = {
    enableTime: true,
    noCalendar: true,
    minuteIncrement: 1,
    dateFormat: 'G:i K',    
  }

  dexterLogin: string;

  constructor() {

    const clienId = "h34QdbitJJiHsTyputytg0WxhPG1OUOy";
    const redirectUrl = "http://localhost:8080/oauth/redirect/dexcom";
    const stateValue = "my_state_value";

    //this.dexterLogin = "https://api.dexcom.com"
    this.dexterLogin = "https://sandbox-api.dexcom.com"
      + "/v2/oauth2/login?client_id="
      + clienId
      + "&redirect_uri="
      + redirectUrl
      + "&response_type=code&scope=offline_access&state="
      + stateValue;
  }

}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SubscriptionModel } from 'src/app/shared/model/subscription';
import { UserModel } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UserService } from 'src/app/shared/services/user.service';
import { BloodSugarComponent  } from '../blood-sugar.component';
import { faPlus } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-blood-sugar-list',
  templateUrl: './blood-sugar-list.component.html',
  styleUrl: './blood-sugar-list.component.css'
})
export class BloodSugarListComponent implements OnInit {
  
  public userModel: UserModel | undefined;
  public subscription: SubscriptionModel | undefined;

  public isPremiumSubscription: boolean = false;

  faPlus = faPlus;

  constructor(
    public authService: AuthService,
    public router: Router,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private titleService: Title,
  ) {
    this.titleService.setTitle("Novalumen Blood Sugar List");
  }


  ngOnInit(): void {

    
    // load userModel
    this.userService.getProfile().subscribe({
      next: (value) => {
        if(value) {
          this.userModel = value;   
          //console.log("dashboard userModel subscription: " + value)            
          this.loadSubscription(value);             
        }
      },
    });
    
  }


  private loadSubscription(user: UserModel) {
    
    const uid = user.uid;      
    this.subscriptionService.getByUid(uid).subscribe({
      next: (value) => {
        if(value) {
          this.subscription = value;   
          this.checkSubscriptionStatus(user, value)    
        }
        else { 
          this.handleExpiredSubscription();
        }
      },        
    });
  
}

private handleExpiredSubscription() {
  console.log("handleExpiredSubscription")

  // get Subscription Session URL
  this.subscriptionService.getCustomerPortalURL().subscribe({
    next: (value) => {
      const url = (value as any).url;
      console.log("URL: " + url);
      this.router.navigateByUrl("/pricing");
    }
  })
  
}

private checkSubscriptionStatus(user: UserModel, subscription: SubscriptionModel) {
  const expDate = new Date(subscription.currentPeriodEnd);
  console.log("expiration date: " + subscription.currentPeriodEnd + "; " + expDate)

  const currentDateInMilliseconds: number = new Date().getTime();
  console.log(currentDateInMilliseconds);    

  if((currentDateInMilliseconds > subscription.currentPeriodEnd) ||
    subscription.status !== "active") {
    console.log("expired!")
    this.handleExpiredSubscription();
  }
  else {
    
    console.log("not expired")
    
    // check for a premium subscription
    if(
      this.subscription?.productName === "Starter" ||
      this.subscription?.productName === "Solo Plus" ||
      this.subscription?.productName === "CircleLife Plus" 
      ) {
        this.isPremiumSubscription = true;
    }
    
  }
}

addBloodButton() {}


}

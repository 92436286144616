export class BloodSugarModel {
    id: string;
    uid: string;
    date: string;
    time: string;
    value: number | null;
    mgValue: number | null;
    mmolValue: number | null;
    measureType: string;
    mealTag: string;
    activityTag: string;
    notes: string;
    creationDate: number;

    constructor() {
        this.creationDate = (new Date()).getTime();
        this.id = "";
        this.uid = "";
        this.date = "";
        this.time = "";
        this.value = null;
        this.mgValue = null;
        this.mmolValue = null;
        this.measureType = "mg/dL";
        this.mealTag = "Random Blood Sugar (RBS)";
        this.activityTag = "Sedentary (Little or No)";
        this.notes = "";            
    }

}
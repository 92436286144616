import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-daily-routine-config',
  templateUrl: './daily-routine-config.component.html',
  styleUrls: ['./daily-routine-config.component.css']
})
export class DailyRoutineConfigComponent implements OnInit {

  public userModel: UserModel = new UserModel();

  constructor(
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    
    // subscribe to userModel updates
    this.userService.getProfile().subscribe({
      next: (value) => { 
        if(value) this.userModel = value; 
      },
    });    
  }

  saveData() {
    this.userService.saveProfile(this.userModel).subscribe({
      next: (value) => {
        // todo        
      },
    })
  }


}

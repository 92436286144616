<div class="modal fade" #content2 id="consentModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header bg-info">
        <h5 class="modal-title text-white" id="staticBackdropLabel">Welcome to Novalumen</h5>        
      </div>
      <div class="modal-body">
        <div *ngIf="this.userModel" class="text-700 lh-lg mb-0">
          <h4 class="mb-2">Hi {{userModel.displayName}},</h4>
          <p>
            Excited to have you with us! Novalumen simplifies diabetes management. 
            Track routines, monitor blood sugar, manage insulin, and get stress-free 
            support with our smart AI. Cheers to a healthier, happier you!
          </p>

          <!--<h5 class="mt-4 mb-2">Bonus Perk</h5>  -->
          <div class="badge badge-phoenix badge-phoenix-info p-1 ps-3 pe-3 fs--1 mb-2">Welcome Bonus Perk</div>                                     
          <div>
              As a special welcome, you'll automatically enjoy the <b>full premium experience</b> for the first 30 days.  
              Take this time to explore, personalize, and decide how you want to 
              continue your journey. Your well-being, your choice!
          </div>
          
          <div class="form-check mt-4">
            <input class="form-check-input" id="flexCheckDefault" type="checkbox" value="" [checked]="isConsentChecked" (change)="onConsentChange($event)"/>          
            <label class="form-check-label" for="flexCheckDefault">
              I consent to Novalumen processing my personal and health data as per our  
              <a href="https://www.novalumen.com/privacy" target="_blank">Privacy Policy</a>. 
              I acknowledge the app tracks, not prescribes. 
              It's a helpful addition, not a substitute for my doctor's advice. 
              I'll consult my healthcare provider before making any changes.
            </label>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" type="button" data-bs-dismiss="modal" [disabled]="!isConsentChecked" (click)="clickConsentButton()">Agree & Continue</button>
        <!--<button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Sign out</button>-->
      </div>
    </div>
  </div>
</div>

    
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    
    <main class="main mb-3" id="top">

      <!--<nav class="navbar navbar-vertical navbar-expand-lg" style="display:block">
        vertical nav bar
      </nav>-->
          
      <app-header [userModel]="userModel" [subscription]="subscription"></app-header>

      <div class="content" >
        
        <!--==========================-->

        <!-- Summary component start -->
        <div *ngIf="userModel" class="row g-0">          
            <app-summary [userModel]="userModel"></app-summary>          
        </div>
         <!--Summary component end-->

        <!-- Daily Routine component start-->
        <div *ngIf="this.isPremiumSubscription" class="row g-0 mt-3">          
            <app-daily-routine [userModel]="userModel"></app-daily-routine>          
        </div>
        <!-- Daily Routine component end-->
        
        <!-- Two Columns for Blood Sugar and Insulin start -->
        <div *ngIf="this.userModel?.journeyConfig?.coreBloodSugar && this.userModel?.journeyConfig?.coreInsulin" class="row g-0 mt-3">  
          <div class="container2">
            <div class="box-left">
              <app-blood-sugar [userModel]="userModel"></app-blood-sugar>
            </div>
            <div class="box-right">
              <app-insulin [userModel]="userModel"></app-insulin>
            </div>
          </div>
        </div>
        <!-- Two Columns for Blood Sugar and Insulin end -->

        <!-- Single Row for Blood Sugar and Insulin start -->
        <div *ngIf="!(this.userModel?.journeyConfig?.coreBloodSugar && this.userModel?.journeyConfig?.coreInsulin)" class="row g-0 mt-3">            
            <div *ngIf="this.userModel?.journeyConfig?.coreBloodSugar" class="">
              <app-blood-sugar [userModel]="userModel"></app-blood-sugar>
            </div>
            <div *ngIf="this.userModel?.journeyConfig?.coreInsulin">
              <app-insulin [userModel]="userModel"></app-insulin>
            </div>          
        </div>
        <!-- Single Row for Blood Sugar and Insulin end -->


        <!-- Personalize Your Journey Offcanvas Start -->
        <app-daily-routine-config></app-daily-routine-config>
        <!-- Personalize Your Journey Offcanvas End -->
        
        <a #buttonConsentModal  class="invisible" type="button" data-bs-toggle="modal" data-bs-target="#consentModal">Launch demo modal 2</a>

      
        <!--==========================-->

      </div>
      
    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->


    <app-footer></app-footer>          
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelfAssessmentComponent } from '../self-assessment.component';
import { UserModel } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-sa-step-final',
  templateUrl: './sa-step-final.component.html',
  styleUrls: ['./sa-step-final.component.css']
})
export class SaStepFinalComponent implements AfterViewInit {

  public userModel: UserModel = new UserModel();

  constructor(
    public router: Router,
    public ss: SelfAssessmentComponent,
  ) { }

  ngAfterViewInit(): void {
    
    // update onboarding status for the user
    let userModel = this.ss.userModel;
    if(userModel) {
      userModel.onboardingStatus = "Step 2";
      this.ss.userService.saveProfile(userModel).subscribe({
        next: (value) => {
          // console.log("user profile step set to 2")
        }
      })
    }
  }


  onChange() {
    const value = this.ss.selfAssessmentModel;
    this.ss.userService.saveSelfAssessment(value).subscribe({
      next: (value) => {
          //console.log("saveSelfAssessment result: " + value);
      },
    });
  }
  
  _prevButton() { 
    this.router.navigateByUrl("/self-assessment/8");
 }

}

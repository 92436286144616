import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HeaderService } from './header.service';
import { HttpClient } from "@angular/common/http";
import { EmailFormModel } from "../model/email.form";
import { Observable } from "rxjs/internal/Observable";


@Injectable({
    providedIn: 'root'
})
export class FeedbackService {

    private API_SERVER_URL = environment.apiServerURL;

    private FEEDBACK_EMAIL = this.API_SERVER_URL + '/v1/feedbackEmail';

    constructor(
        private http: HttpClient,
        private headerService: HeaderService,        
    ){}       

    /*
    Create new self-assessment object and save it
    */
    submitEmailForm(value: EmailFormModel): Observable<EmailFormModel> {
        const headers = this.headerService.generateHttpHeaders();          
        return this.http.post<EmailFormModel>(this.FEEDBACK_EMAIL, value, { headers });      
    }

}
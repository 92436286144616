import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-disabled-user',
  templateUrl: './disabled-user.component.html',
  styleUrls: ['./disabled-user.component.css']
})
export class DisabledUserComponent implements OnInit {

  public uid: string | undefined;
  public email: string | undefined;

  constructor(
    private userService: UserService,  
    private authService: AuthService,
  ) {}
  
  ngOnInit(): void {
   
    // load userModel
    this.userService.getProfile().subscribe({
      next: (value) => {
        if(value) {
          this.uid = value.uid;
          this.email = value.email;  
          this._logout();         
        }
      },
    });
  }

  private _logout() { 
    this.authService.SignOut2();    
 }

}

    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    
    <main class="main" id="top">

        <!--<nav class="navbar navbar-vertical navbar-expand-lg" style="display:block">
          vertical nav bar
        </nav>-->
        
          <app-header></app-header>
          
          
        <div class="content" >
  
          <!--==========================-->
  
          <div class="row g-0">
            
            <div class="col-lg-6">
              <div class="row flex-center g-0 px-4 px-sm-0">
                
                <nav aria-label="breadcrumb" class="mb-4"> 
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item fs-0"><a routerLink="/onboarding">Onboarding</a></li>
                    <li class="breadcrumb-item active fs-0" aria-current="page">Step 2. Personalize Your Journey</li> 
                  </ol>
                </nav>
                
                    
                    <!--<p class="assessment-title">Step 2. Personalize Your Journey</p>-->
                    
                    <!-- profile starts -->

                    <div class="d-flex min-vh-75 w-100 flex-column justify-content-center">
                        
                        <div class="flex-grow-1 "></div>
                        <div class="d-flex d-flex justify-content-center">
                    
                            <div class="assessment-block" >
                                

                                <!-- Personalized Daily Routine -->
                                <div class="pe-4">                                  
                                  
                                    Discover the magic of Novalumen, a health platform that crafts your 
                                    unique care journey using intelligent algorithms and insights from 
                                    your self-assessment. See your your individual plan below, and you can 
                                    customize it on the spot for a wellness experience designed just for you!  
                                  
                                  <!-- Display personalized daily routine details here -->
                                  
                                </div>

                               
                                <!-- Blood Sugar and Insulin Start -->
                                <div class="row g-0 mt-3">
                                  <div class="w-100 pe-2">
                                    <div class="d-flex align-items-center">
                                      <div class="mb-0"><h5>Blood Sugar and Insulin</h5></div> 
                                      <div class="badge badge-phoenix badge-phoenix-success p-0.5 ps-2 pe-2 ms-2 mb-1 fs--2">Free Feature</div>
                                    </div>                                    
                                  </div>
                                </div>
                                <div class="row g-0 mt-1">
                                  <div class="w-50 pe-2">
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.coreBloodSugar" class="form-check-input" id="coreBloodSugar" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="coreBloodSugar">Blood Sugar Journal</label>
                                    </div>  
                                  </div>
                                  <div class="w-50 ps-2">
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.coreInsulin" class="form-check-input" id="coreInsulin" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="coreInsulin">Insulin Journal</label>
                                    </div> 
                                  </div>
                                </div>
                                <!-- Blood Sugar and Insulin End -->

                                <!-- Vitals Summary Start -->
                                <div class="row g-0 mt-3">
                                  <div class="w-100 pe-2">
                                    <div class="d-flex align-items-center">
                                      <div class="mb-0"><h5>Vitals Summary</h5></div> 
                                      <div class="badge badge-phoenix badge-phoenix-success p-0.5 ps-2 pe-2 ms-2 mb-1 fs--2">Free Feature</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row g-0 mt-1">
                                  <div class="w-50 pe-2">
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.vsFastingBloodSugar" class="form-check-input" id="vsFastingBloodSugar" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="vsFastingBloodSugar">Fasting Blood Sugar</label>
                                    </div>  
                                  </div>
                                  <div class="w-50 ps-2">
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.vsBeforeMeals" class="form-check-input" id="vsBeforeMeals" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="vsBeforeMeals">Before Meals (Preprandial)</label>
                                    </div> 
                                  </div>
                                </div>
                                <div class="row g-0 mt-1">
                                  <div class="w-50 pe-2">
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.vsPostMeals" class="form-check-input" id="vsPostMeals" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="vsPostMeals">Post Meals (Postprandial)</label>
                                    </div>  
                                  </div>
                                  <div class="w-50 ps-2">
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.vsInsulinTotalDailyDose" class="form-check-input" id="vsInsulinTotalDailyDose" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="vsInsulinTotalDailyDose">Insulin Todal Daily Dose</label>
                                    </div> 
                                  </div>
                                </div>
                                <!-- Vitals Summary End -->

                          

                                <div class="row g-0 mt-3">
                                  <div class="w-50 pe-2">
                                    
                                    <div class="d-flex align-items-center">
                                      <div class="mb-0"><h5>Health Metrics</h5></div> 
                                      <div class="badge badge-phoenix badge-phoenix-info p-0.5 ps-2 pe-2 ms-2 mb-1 fs--2">Premium Feature</div>
                                    </div>
                        
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.hmMedications" class="form-check-input" id="hmMedications" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="hmMedications">Medications</label>
                                    </div>
                        
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.hmBloodPressure" class="form-check-input" id="hmBloodPressure" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="hmBloodPressure">Blood Pressure</label>
                                    </div>            
                        
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.hmFootCare" class="form-check-input" id="hmFootCare" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="hmFootCare">Foot Care</label>
                                    </div>
                        
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.hmSymptomaticEpisodes" class="form-check-input" id="hmSymptomaticEpisodes" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="hmSymptomaticEpisodes">Symptomatic Episodes</label>
                                    </div>
                        
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.hmStressLevel" class="form-check-input" id="hmStressLevel" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="hmStressLevel">Stress Level</label>
                                    </div>
                        
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.hmSleepQuality" class="form-check-input" id="hmSleepQuality" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="hmSleepQuality">Sleep Quality</label>
                                    </div>                                    
                        
                                  </div>
                                  <div class="w-50 ps-2">
                                    
                                    <div class="d-flex align-items-center">
                                      <div class="mb-0"><h5>Lifestyle Insights</h5></div> 
                                      <div class="badge badge-phoenix badge-phoenix-info p-0.5 ps-2 pe-2 ms-2 mb-1 fs--2">Premium Feature</div>
                                    </div>
                        
                                    <div class="form-check form-switch mt-2">
                                      <input [(ngModel)]="this.userModel.journeyConfig.liWeight" class="form-check-input" id="liWeight" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="liWeight">Weight</label>
                                    </div>
                        
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.liCalories" class="form-check-input" id="liCalories" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="liCalories">Calories</label>
                                    </div>
                          
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.liCarbs" class="form-check-input" id="liCarbs" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="liCarbs">Carbs</label>
                                    </div>
                        
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.liHealthySnacks" class="form-check-input" id="liHealthySnacks" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="liHealthySnacks">Healthy Snacks</label>
                                    </div>
                          
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.liAlcohol" class="form-check-input" id="liAlcohol" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="liAlcohol">Alcohol</label>
                                    </div>
                          
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.liIntermittentFasting" class="form-check-input" id="liIntermittentFasting" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="liIntermittentFasting">Intermittent Fasting</label>
                                    </div>
                        
                                    <div class="form-check form-switch">
                                      <input [(ngModel)]="this.userModel.journeyConfig.liExcercise" class="form-check-input" id="liExcercise" type="checkbox" (change)="saveData()">
                                      <label class="form-check-label" for="liExcercise">Excercise</label>
                                    </div>
                                                            
                        
                                  </div>
                        
                                  
                                </div>
  
  
  

                                <button (click)="nextStep()" routerLink="/pricing" class="btn btn btn-primary me-1 mb-1" type="button">Next Step</button>

                            </div>
                        </div>

                        <div class="p-2 flex-grow-1"></div>
                        
                        <!--
                        <div class="d-flex mb-2">
                            <div class="me-auto p-2">
                            <button class="btn btn-link" type="submit">Prev</button>
                            </div>
                            <div class="p-2">
                            <button class="btn btn-link" type="submit">Skip</button>
                            </div>
                            <div class="p-2">
                                <button class="btn btn-primary" type="submit">Next</button>
                            </div>
                        </div>
                        -->

                    </div>
                    <!--
                    <p>
                        🌟Cheers to your health journey with Novalumen! For any assistance, we're here for you. 🌟

                    </p>
                -->

                    <!-- profile ends -->
                    
                                                                        
                
                
  
  
              </div>
            </div>
  
  
            <div class="col-lg-6 position-relative d-none d-lg-block  ">
              <div class="d-flex align-items-center justify-content-center h-100">
                <img class="w-100" src="../assets/img/spot-illustrations/15.png"/>
              </div>  
            </div>
  
          </div>
  
          <!--==========================-->
  
        </div>
        
      </main>
      <!-- ===============================================-->
      <!--    End of Main Content-->
      <!-- ===============================================-->


      <app-footer></app-footer>
  
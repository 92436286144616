import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent {

  constructor(
    private titleService: Title, 
    private metaService: Meta,
  ) {
    this.titleService.setTitle('Terms Of Service');
  }



}

import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { CachedUserService } from 'src/app/shared/services/cached.user.service';
//import {firebase, firebaseui, FirebaseUIModule, FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult} from 'firebaseui-angular';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  fbUser: any;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    //private cachedUserService: CachedUserService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    
    this.authService.user$.subscribe((user) => {      
      this.fbUser = user;
      console.log("subscribe user: " + user)
    });


  }

  successCallback(event: any) {}

  /*
  successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {
    console.log("successCallback")

    const isNewUser = signInSuccessData.authResult.additionalUserInfo?.isNewUser;
    const user = signInSuccessData.authResult.user;
    
    if (user) {      

      // update local user cache
      //localStorage.setItem('user', JSON.stringify(user));
      this.authService.reportSuccessfulLogin(user);

        // get user from our database         
        this.userService.getProfile().subscribe({
          next: (userModel) => {
            
            var url = "/onboarding";

            if(userModel) {

              // 1. check if user is disabled
              if(!userModel.active) {
                url = "/disabledUser";
              }

              // 2. check if user has finished onboarding
              if(userModel.onboardingStatus === "Finished" ||
                  userModel.onboardingStatus === "Baseline Period") {
                url = "/dashboard";
              }             


              console.log("userModel.uid: " + userModel?.uid)
              // now let's decide on the onboarding status and routing
                          
            }
            else {
              // TODO
              // userModel is somehow undefined
            }

            this.router.navigateByUrl(url);
          }
        })
        // get user from our database
        
      }  
    

  }
  */
    

  errorCallback(event: any) {}
  /*
  errorCallback(errorData: FirebaseUISignInFailure) {
    console.log("errorCallback")
  }
  */
    
  uiShownCallback() {
    console.log("uiShownCallback")
  }
  

  _logout() { 
    this.authService.SignOut2();
    this.router.navigateByUrl("/logout");
 }

}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

// Add Font Awesome library
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

// Add solid icons to the library
library.add(fas);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Firebase services + environment module
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/compat/firestore';
//import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

import { getAuth, provideAuth } from '@angular/fire/auth';

// import Echarts.js
import { NgxEchartsModule } from 'ngx-echarts';

import { FeatherModule } from 'angular-feather';
import { Camera, Heart, Github } from 'angular-feather/icons';


// Select some icons (use an object, not an array)
const icons = {
  Camera,
  Heart,
  Github
};

import { environment } from '../environments/environment';
import { Block1Component } from './components/block1/block1.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SelfAssessmentComponent } from './components/self-assessment/self-assessment.component';
import { SaStep1Component } from './components/self-assessment/sa-step-1/sa-step-1.component';
import { SaStep2Component } from './components/self-assessment/sa-step-2/sa-step-2.component';
import { SaStep3Component } from './components/self-assessment/sa-step-3/sa-step-3.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SaStep4Component } from './components/self-assessment/sa-step-4/sa-step-4.component';
import { SaStep5Component } from './components/self-assessment/sa-step-5/sa-step-5.component';
import { SaStep6Component } from './components/self-assessment/sa-step-6/sa-step-6.component';
import { SaStep7Component } from './components/self-assessment/sa-step-7/sa-step-7.component';
import { SaStep8Component } from './components/self-assessment/sa-step-8/sa-step-8.component';
import { SaStep9Component } from './components/self-assessment/sa-step-9/sa-step-9.component';
import { SaStep10Component } from './components/self-assessment/sa-step-10/sa-step-10.component';
import { SaStepFinalComponent } from './components/self-assessment/sa-step-final/sa-step-final.component';
import { BloodSugarComponent } from './components/blood-sugar/blood-sugar.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { BloodSugarFormComponent } from './components/blood-sugar/blood-sugar-form/blood-sugar-form.component';
import { SummaryComponent } from './components/summary/summary.component';
import { DailyRoutineComponent } from './components/daily-routine/daily-routine.component';
import { DailyRoutineConfigComponent } from './components/daily-routine/daily-routine-config/daily-routine-config.component';
import { InsulinComponent } from './components/insulin/insulin.component';
import { InsulinFormComponent } from './components/insulin/insulin-form/insulin-form.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { DisabledUserComponent } from './components/disabled-user/disabled-user.component';
import { PersonalJourneyComponent } from './components/personal-journey/personal-journey.component';
import { OnboardingFinalComponent } from './components/onboarding-final/onboarding-final.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { PricingStripeComponent } from './components/pricing-stripe/pricing-stripe.component';
import { StripeModule } from 'stripe-angular';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { SubscriptionConfirmationComponent } from './components/subscription-confirmation/subscription-confirmation.component';
import { MetricsComponent } from './components/metrics/metrics.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';
import { TestComponent } from './components/test/test.component';
import { provideFirebaseApp } from '@angular/fire/app';
import { BloodSugarListComponent } from './components/blood-sugar/blood-sugar-list/blood-sugar-list.component';


//import { FirebaseUiAuthConfig } from './shared/config/firebase.ui.auth.config';
//const firebaseUiAuthConfig: FirebaseUiAuthConfig = new FirebaseUiAuthConfig();

@NgModule({
  declarations: [
    AppComponent,
    Block1Component,
    LoginComponent,
    LogoutComponent,
    DashboardComponent,
    SelfAssessmentComponent,
    SaStep1Component,
    SaStep2Component,
    SaStep3Component,
    SaStep4Component,
    SaStep5Component,
    SaStep6Component,
    SaStep7Component,
    SaStep8Component,
    SaStep9Component,
    SaStep10Component,
    SaStepFinalComponent,
    BloodSugarComponent,
    BloodSugarFormComponent,
    BloodSugarListComponent,
    SummaryComponent,
    DailyRoutineComponent,
    DailyRoutineConfigComponent,
    InsulinComponent,
    InsulinFormComponent,
    FooterComponent,
    HeaderComponent,
    ProfileComponent,
    OnboardingComponent,
    DisabledUserComponent,
    PersonalJourneyComponent,
    OnboardingFinalComponent,
    PricingComponent,
    PricingStripeComponent,
    LandingPageComponent,
    SubscriptionConfirmationComponent,
    MetricsComponent,
    PrivacyComponent,
    TermsComponent,
    TestComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FlatpickrModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    //AngularFireAuthModule,
    provideFirebaseApp(() => { return initializeApp(environment.firebase) }),
    provideAuth(() => getAuth()),
    AngularFirestoreModule,
    AngularFireStorageModule,
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    FeatherModule.pick(icons),
    FontAwesomeModule,
    StripeModule.forRoot('pk_test_51OSQ8IFypOnWCy49P6HyQa2cj4ou5xUUNJcfygs0LBMNdpKjo8k8cPjpiaymzqnPvoSuZYLF0HPTMmQyvstiv12s003TH6uUxj'),
    NgbModule,
    //NgxGoogleAnalyticsModule.forRoot('G-9NTXHEHG7V'),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  
}

// Initialize Firebase
// const app = initializeApp(environment.firebase);
// const analytics = getAnalytics(app);

import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { AuthService } from "../../shared/services/auth.service";
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { UserModel } from 'src/app/shared/model/user.model';
import { BloodSugarComponent } from '../blood-sugar/blood-sugar.component';
import { InsulinComponent } from '../insulin/insulin.component';
import { MetricsComponent } from '../metrics/metrics.component';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { SubscriptionModel } from 'src/app/shared/model/subscription';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public userModel: UserModel | undefined;
  public subscription: SubscriptionModel | undefined;

  public isPremiumSubscription: boolean = false;
  
  constructor(
    public authService: AuthService,
    public router: Router,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private titleService: Title,
  ) {

    this.titleService.setTitle("Novalumen Dashboard");

  }

  @ViewChild('buttonConsentModal') buttonConsentModal: ElementRef | any;
  isConsentChecked:boolean = false;
  isConsentShown: boolean = false;

  
  ngOnInit(): void {

    
    // load userModel
    this.userService.getProfile().subscribe({
      next: (value) => {
        if(value) {
          this.userModel = value;   
          //console.log("dashboard userModel subscription: " + value)  
          
          this.titleService.setTitle("Novalumen Dashboard - " + value.displayName);

          const status = this.userModel.onboardingStatus;
          if(status !== "Finished") {
            //window.location.href = "/onboarding";
            const button  = this.buttonConsentModal.nativeElement;
            if(button && !this.isConsentShown) {              
              button.click();
              this.isConsentShown = true;
            }
            //console.log('show onboarding modal')
          }
          

          this.loadSubscription(value);             
        }
      },
    });
    
  }

  private loadSubscription(user: UserModel) {
    
      const uid = user.uid;      
      this.subscriptionService.getByUid(uid).subscribe({
        next: (value) => {
          if(value) {
            this.subscription = value;   
            this.checkSubscriptionStatus(user, value)    
          }
          else { 
            this.handleExpiredSubscription();
          }
        },        
      });
    
  }

  private handleExpiredSubscription() {
    //console.log("handleExpiredSubscription")

    // get Subscription Session URL
    this.subscriptionService.getCustomerPortalURL().subscribe({
      next: (value) => {
        const url = (value as any).url;
        //console.log("URL: " + url);
        this.router.navigateByUrl("/pricing");
      }
    })
    
  }

  private checkSubscriptionStatus(user: UserModel, subscription: SubscriptionModel) {
    const expDate = new Date(subscription.currentPeriodEnd);
    //console.log("expiration date: " + subscription.currentPeriodEnd + "; " + expDate)

    const currentDateInMilliseconds: number = new Date().getTime();
    //console.log(currentDateInMilliseconds);    

    if((currentDateInMilliseconds > subscription.currentPeriodEnd) ||
      subscription.status !== "active") {
      //console.log("expired!")
      this.handleExpiredSubscription();
    }
    else {
      
      //console.log("not expired")
      
      // check for a premium subscription
      if(
        this.subscription?.productName === "Starter" ||
        this.subscription?.productName === "Solo Plus" ||
        this.subscription?.productName === "CircleLife Plus" 
        ) {
          this.isPremiumSubscription = true;
      }
      
    }
  }


  onConsentChange(event: Event) {
    this.isConsentChecked = (event.target as HTMLInputElement).checked;
    // Your logic when the checkbox state changes
  }

  clickConsentButton() {

    if(this.userModel) {
      this.userModel.onboardingStatus = "Finished";
      this.userService.saveProfile(this.userModel).subscribe({
        next: (value) => {
          //console.log("user updated");
        }
      })
    }
    
  }

}

import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';

import { ToastrService } from 'ngx-toastr';
import { UserModel } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent implements OnInit, AfterViewInit {

  public userModel: UserModel | undefined; 

  faSquare = faSquare;
  faSquareCheck = faSquareCheck;

  isStep1Checked: boolean = false;
  isStep2Checked: boolean = false;
  isStep3Checked: boolean = false;

  constructor(
    private userService: UserService,  
    private renderer: Renderer2,  
    private toastr: ToastrService,
  ) {}

  ngAfterViewInit(): void {
    
    // load userModel
    this.userService.getProfile().subscribe({
      next: (value) => {
        //console.log("ngAfterViewInit new user")
        if(value) {
          this.userModel = value;     
          this.updateSteps();
        }
      },
    });

  }

  ngOnInit(): void {  
  }

  updateSteps() {
    if(this.userModel) {
      var currentStep = this.userModel.onboardingStatus;
      if(currentStep === "") {
        currentStep = "Step 1";
        this.userModel.onboardingStatus = currentStep;
      }
      else if(currentStep === "Step 2") {
        this.isStep1Checked = true;
      }
      else if(currentStep === "Step 3") {
        this.isStep1Checked = true;
        this.isStep2Checked = true;
      }
      else if(currentStep === "Baseline Period" || currentStep === "Finished") {
        this.isStep1Checked = true;
        this.isStep2Checked = true;
        this.isStep3Checked = true;
      }      
    }
  }

}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CachedUserService } from "./cached.user.service";
import { InsulinModel } from "../model/insulin.model";
import { Observable } from "rxjs";
import { HeaderService } from "./header.service";
import { SubscriptionModel } from "../model/subscription";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    private API_SERVER_URL = environment.apiServerURL;

    private SUBSCRIPTION_API = this.API_SERVER_URL + '/v1/subscription';
    private GET_CHECKOUT_SESSION_API = this.API_SERVER_URL + '/v1/stripe/create-checkout-session';
    private GET_CUSTOMER_PORTAL_SESSION_API = this.API_SERVER_URL + '/v1/stripe/create-customer-portal-session';
    
    constructor(
      private http: HttpClient,
      private headerService: HeaderService,
    ){}

    /*
    Get a Subscription by UID
    */
    getByUid(uid: string): Observable<SubscriptionModel> {
      const headers = this.headerService.generateHttpHeaders();
      const url = this.SUBSCRIPTION_API + "?uid=" + uid;
      return this.http.get<SubscriptionModel>(url, { headers });  
    }

    /*
    Get a Subscription by Session ID
    */
    getByCheckoutSessionId(id: string): Observable<SubscriptionModel> {
      const headers = this.headerService.generateHttpHeaders();
      const url = this.SUBSCRIPTION_API + "?checkoutSessionId=" + id;
      return this.http.get<SubscriptionModel>(url, { headers });  
    }

    /*
    Get a Checkout Session URL
    */
    getCheckoutSessionURL(productName: string): Observable<string> {
      const headers = this.headerService.generateHttpHeaders();    
      const url = this.GET_CHECKOUT_SESSION_API + "?productName=" + productName;
      return this.http.get<string>(url, { headers });  
    }

    /*
    Get a Customer Portal URL
    */
    getCustomerPortalURL(): Observable<string> {
      const headers = this.headerService.generateHttpHeaders();      
      return this.http.get<string>(this.GET_CUSTOMER_PORTAL_SESSION_API, { headers });  
    }
    
   

}
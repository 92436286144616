

    <div class="card p-4 h-100">   
        
        <div class="d-flex me-3">
            <div>
                <h4>Insulin Journal</h4>
                <p class="fs--1 fw-semi-bold">Explore your personal insulin trends</p>
            </div>
            <div class="ms-auto">
                <button #addInsulinButton class="btn btn-sm btn-phoenix-secondary  btn-small" data-bs-toggle="offcanvas" data-bs-target="#offcanvasInsulinForm"><fa-icon [icon]="faPlus" /></button>                
            </div>
        </div> 

        <div *ngIf="isInsulinDataEmpty" class="d-flex align- justify-content-center h-100">
            <div class="card-body alert alert-soft-primary">
                <h5 class="card-title">Tip</h5>
                <p class="text-secondary">
                    Begin tracking your insulin dosages here. 
                </p>
                <p class="text-secondary mb-5">
                    Click the button below to add your first dose, 
                    or choose to hide this widget if you don't plan to track insulin.
                </p>
                <a class="btn btn-primary" data-bs-toggle="offcanvas" data-bs-target="#offcanvasInsulinForm">Add Insulin Record</a>
                <a class="btn btn-outline-secondary ms-4" (click)="disableInsulinWidget()">Hide Insulin Journal</a>
            
            </div>
        </div>  

              
        <div *ngIf="!isInsulinDataEmpty" class="row g-0">
            
                <div class="d-flex align- justify-content-center h-100">
                    <div class="echart-total-sales-chart w-100">
                        <div echarts [loading]="false" [options]="chartOption" (chartInit)="onChartInit($event)" class="demo-chart"></div>
                    </div>
                    <!--<img class="w-100" src="../assets/img/bg/tmp-blood-sugar.png"/>-->
                </div>               
            
        </div>

        <div *ngIf="!isInsulinDataEmpty && this.userModel"  class="row g-0">            
            <div class="d-flex flex-wrap">
                <div class="flex-fill flex-1 d-flex">
                    <div class="form-check form-switch">
                        <input [(ngModel)]="this.userModel.journeyConfig.showRapidActing" class="form-check-input custom-switch-red" id="flexSwitchCheckDefault" type="checkbox" (change)="configLeversChange()">
                        <label class="form-check-label" for="flexSwitchCheckDefault">Rapid-acting</label>
                    </div>  
                </div>
                <div class="flex-fill flex-1 d-flex">
                    <div class="form-check form-switch">
                        <input [(ngModel)]="this.userModel.journeyConfig.showShortActing" class="form-check-input custom-switch-orange" id="flexSwitchCheckDisabled" type="checkbox" (change)="configLeversChange()">
                        <label class="form-check-label" for="flexSwitchCheckDisabled">Short-acting</label>
                    </div>  
                </div>
                <div class="flex-fill flex-1 d-flex">
                    <div class="form-check form-switch">
                        <input [(ngModel)]="this.userModel.journeyConfig.showIntermediateActing" class="form-check-input" id="flexSwitchCheckCheckedDisabled" type="checkbox" (change)="configLeversChange()">
                        <label class="form-check-label" for="flexSwitchCheckCheckedDisabled">Intermediate-acting</label>
                    </div> 
                </div>
                <div class="flex-fill flex-1 d-flex">
                    <div class="form-check form-switch">
                        <input [(ngModel)]="this.userModel.journeyConfig.showLongActing" class="form-check-input custom-switch-green" id="flexSwitchCheckChecked" type="checkbox" (change)="configLeversChange()">
                        <label class="form-check-label" for="flexSwitchCheckChecked">Long-acting</label>
                    </div> 
                </div>                
            </div>
        </div>
      
    </div>
    

  

  <!-- Insulin Offcanfas Form -->
  <app-insulin-form></app-insulin-form>
<div class="d-flex min-vh-75 w-100 flex-column justify-content-center">
    <div class="">                    
        <p>Question: 4 of 10</p>
        <div class="progress" style="height:8px">
        <div class="progress-bar" role="progressbar" style="width: 40%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
    <div class="flex-grow-1 "></div>
    <div class="d-flex d-flex justify-content-center">

        <div class="assessment-block">
            
            <p class="question-header">Are you taking insulin or any other medications for diabetes management?</p>

            <div class="form-check">
                <input class="btn-check" id="flexRadioDefault1" type="checkbox" value="Insulin" [(ngModel)]="ss.selfAssessmentModel.medications.insulin" (change)="onChange()"/>        
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault1">Insulin</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault2" type="checkbox" value="Metformin (Biguanides)" [(ngModel)]="ss.selfAssessmentModel.medications.metformin" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault2">Metformin (Biguanides)</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault3" type="checkbox" value="Sulfonylureas" [(ngModel)]="ss.selfAssessmentModel.medications.sulfonylureas" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault3">Sulfonylureas</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault4" type="checkbox" value="Dipeptidyl Peptidase-4 (DPP-4) Inhibitors:" [(ngModel)]="ss.selfAssessmentModel.medications.dpp4" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault4">Dipeptidyl Peptidase-4 (DPP-4) Inhibitors:</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault5" type="checkbox" value="Thiazolidinediones (TZDs)" [(ngModel)]="ss.selfAssessmentModel.medications.tzds" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault5">Thiazolidinediones (TZDs)</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault6" type="checkbox" value="SGLT2 Inhibitors (Sodium-Glucose Co-Transporter 2)" [(ngModel)]="ss.selfAssessmentModel.medications.sglt2" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault6">SGLT2 Inhibitors (Sodium-Glucose Co-Transporter 2)</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault7" type="checkbox" value="GLP-1 Receptor Agonists (Glucagon-Like Peptide-1)" [(ngModel)]="ss.selfAssessmentModel.medications.glp1" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault7">GLP-1 Receptor Agonists (Glucagon-Like Peptide-1)</label>
            </div>

            <div class="form-check">        
                <input class="btn-check" id="flexRadioDefault8" type="checkbox" value="Others" [(ngModel)]="ss.selfAssessmentModel.medications.others" (change)="onChange()" />
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault8">Others</label>
            </div>

        </div>
    </div>
    <div class="p-2 flex-grow-1"></div>
    
    <div class="d-flex mb-2">
        <div class="me-auto p-2">
        <button class="btn btn-link" (click)="_prevButton()" type="submit">Prev</button>
        </div>
        <div class="p-2">
        <button class="btn btn-link" (click)="_nextButton()" type="submit">Skip</button>
        </div>
        <div class="p-2">
        <button class="btn btn-primary" (click)="_nextButton()" type="submit">Next</button>
        </div>
    </div>
</div>

    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    
    <main class="main" id="top">

        <!--<nav class="navbar navbar-vertical navbar-expand-lg" style="display:block">
          vertical nav bar
        </nav>-->
        
          <app-header></app-header>
          
          
          
        
  
        <div class="content" >
  
          <!--==========================-->
  
          <div class="row g-0">
            
            <div class="col-lg-6">
              <div class="row flex-center g-0 px-4 px-sm-0">
                
                
                <div class="col-lg-11 ">
                    
                    <p class="assessment-title">My Profile</p>
                    
                    <!-- profile starts -->

                    <div class="d-flex min-vh-75 w-100 flex-column justify-content-center">
                        
                        <div class="flex-grow-1 "></div>
                        <div class="d-flex d-flex justify-content-center">
                    
                            <div class="assessment-block" style="width:350px">
                                
                                <!--<div class="mt-2 d-flex flex-wrap">-->
                                    <!--
                                    <div class="flex-fill flex-1 mb-4 me-3">   
                                        <div class="clearfix">
                                            <div class="float-start daily-question">SUBSCRIPTION PLAN:</div>
                                            <div class="float-end daily-question-click">(Click to change)</div>
                                        </div>
                                        <div>     
                                            <div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic example">                                                                            
                                                <button #buttonSubscription1 class="btn btn-phoenix-secondary" style="cursor: default;" type="button" >Solo Free</button>                                                  
                                                <button #buttonSubscription2 class="btn btn-phoenix-secondary" style="cursor: default;" type="button" >Solo Plus</button>                                                  
                                                <button #buttonSubscription3 class="btn btn-phoenix-secondary" style="cursor: default;" type="button" >CircleLife Plus</button>                                                  
                                            </div>  
                                        </div>
                                    </div>
                                    -->
                                   
                                    <div class="flex-fill flex-1 mt-2 me-3">                                        
                                        <div class="daily-question">DISPLAY NAME:</div>
                                        <div>     
                                            <div class="input-group input-group-sm">                                    
                                                <input #fieldDisplayName [(ngModel)]="userModel.displayName" class="form-control" type="text" placeholder="" (change)="changeDisplayName()" />                                    
                                                <div class="invalid-feedback">Display name can't be empty</div>
                                            </div>
                                            
                                        </div>
                                    </div>  
                                    
                                    <div class="flex-fill flex-1 mt-2 me-3">                                        
                                        <div class="daily-question">EMAIL (readonly):</div>
                                        <div>     
                                            <div class="input-group input-group-sm">                                    
                                                <input [(ngModel)]="userModel.email" class="form-control" type="text" placeholder="TODO" readonly />                                    
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex-fill flex-1 mt-2 me-3">                                        
                                        <div class="daily-question">ALTERNATIVE EMAIL (optional):</div>
                                        <div>     
                                            <div class="input-group input-group-sm">                                    
                                                <input #fieldEmailAlternative [(ngModel)]="userModel.emailAlternative" class="form-control" type="text" placeholder="name@domain.com" (change)="changeEmailAlternative()" />                                    
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="flex-fill flex-1 mt-2 me-3">                                        
                                        <div class="daily-question">BLOOD SUGAR UNITS:</div>
                                        <div>     
                                            <div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic example">
                                                <button #buttonBloodSugarUnits1 class="btn btn-phoenix-secondary" type="button" (click)="clickBloodSugarUnits('mg/dL')">mg/dL</button>                              
                                                <button #buttonBloodSugarUnits2 class="btn btn-phoenix-secondary" type="button" (click)="clickBloodSugarUnits('mmol/L')">mmol/L</button>                                                  
                                            </div>      
                                        </div>
                                    </div>

                                    <div class="flex-fill flex-1 mt-2 me-3">                                        
                                        <div class="daily-question">DIABETES TYPE:</div>
                                        <div>     
                                            <div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic example">
                                                <button #buttonDiabetesType1 class="btn btn-phoenix-secondary" type="button" (click)="clickDiabetesType('Type 1')">Type 1</button>                              
                                                <button #buttonDiabetesType2 class="btn btn-phoenix-secondary" type="button" (click)="clickDiabetesType('Type 2')">Type 2</button>                                                  
                                                <button #buttonDiabetesType3 class="btn btn-phoenix-secondary" type="button" (click)="clickDiabetesType('None')">None</button>                                                  
                                            </div>      
                                        </div>
                                    </div>
                                    
                                    <div class="flex-fill flex-1 mt-2 me-3">                                        
                                        <div class="daily-question">DATE OF BIRTH (optional):</div>
                                        <div>     
                                            <div class="input-group input-group-sm">                                    
                                                <input #fieldBirthYear [(ngModel)]="formBirthYear" class="form-control" type="text" placeholder="1985" (change)="changeBirthYear()" />                                    
                                                <div class="invalid-feedback">Please enter correct value</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex-fill flex-1 mt-2 me-3">                                        
                                        <div class="daily-question">GENDER (optional):</div>
                                        <div>     
                                            <div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic example">
                                                <button #buttonGender1 class="btn btn-phoenix-secondary" type="button" (click)="clickGender('Male')">Male</button>                              
                                                <button #buttonGender2 class="btn btn-phoenix-secondary" type="button" (click)="clickGender('Female')">Female</button>                                                  
                                                <button #buttonGender3 class="btn btn-phoenix-secondary" type="button" (click)="clickGender('Non-binary')">Non-binary</button>                                                  
                                            </div>      
                                        </div>
                                    </div>


                                    
                                    <!--<div>{{ userModel.subscriptionPlan | json }}</div>-->


            
                                <!--</div>-->

                            </div>
                        </div>

                        <div class="p-2 flex-grow-1"></div>
                        
                        <!--
                        <div class="d-flex mb-2">
                            <div class="me-auto p-2">
                            <button class="btn btn-link" type="submit">Prev</button>
                            </div>
                            <div class="p-2">
                            <button class="btn btn-link" type="submit">Skip</button>
                            </div>
                            <div class="p-2">
                                <button class="btn btn-primary" type="submit">Next</button>
                            </div>
                        </div>
                        -->

                    </div>
                    

                    <!-- profile ends -->
                    
                                                                        
                </div>
  
                <div class="col-lg-1">&nbsp;</div>
  
  
              </div>
            </div>
  
  
            <div class="col-lg-6 position-relative d-none d-lg-block  ">
              <div class="d-flex align-items-center justify-content-center h-100">
                <img class="w-100" src="../assets/img/bg/assessment-bg-boy.png"/>
              </div>  
            </div>
  
          </div>
  
          <!--==========================-->
  
        </div>
        
      </main>
      <!-- ===============================================-->
      <!--    End of Main Content-->
      <!-- ===============================================-->


      <app-footer></app-footer>
  
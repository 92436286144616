<div #offcanvasDailyRoutineConfig class="offcanvas offcanvas-end" id="offcanvasDailyRoutineConfig" tabindex="1" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header bg-soft border-bottom">
      <h5 id="offcanvasRightLabel">Personalize Your Journey</h5>
      <button #offcanvasDailyRoutineConfigCloseButton class="btn-close text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">

        <!--<div>{{ this.userModel | json }}</div>-->

        <!-- Blood Sugar and Insulin Start -->
        <div class="row g-0">
          <div class="w-100 pe-2">
            <h5>Blood Sugar and Insulin</h5>
          </div>
        </div>
        <div class="row g-0 mt-1">
          <div class="w-50 pe-2">
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.coreBloodSugar" class="form-check-input" id="coreBloodSugar" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="coreBloodSugar">Blood Sugar Journal</label>
            </div>  
          </div>
          <div class="w-50 ps-2">
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.coreInsulin" class="form-check-input" id="coreInsulin" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="coreInsulin">Insulin Journal</label>
            </div> 
          </div> 
        </div>
        <!-- Blood Sugar and Insulin End -->

        <!-- Vitals Summary Start -->
        <div class="row g-0 mt-4">
          <div class="w-100 pe-2">
            <h5>Vitals Summary</h5>
          </div>
        </div>
        <div class="row g-0 mt-1">
          <div class="w-50 pe-2">
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.vsFastingBloodSugar" class="form-check-input" id="vsFastingBloodSugar" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="vsFastingBloodSugar">Fasting Blood Sugar</label>
            </div>  
          </div>
          <div class="w-50 ps-2">
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.vsBeforeMeals" class="form-check-input" id="vsBeforeMeals" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="vsBeforeMeals">Before Meals (Preprandial)</label>
            </div> 
          </div>
        </div>
        <div class="row g-0 mt-1">
          <div class="w-50 pe-2">
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.vsPostMeals" class="form-check-input" id="vsPostMeals" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="vsPostMeals">Post Meals (Postprandial)</label>
            </div>  
          </div>
          <div class="w-50 ps-2">
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.vsInsulinTotalDailyDose" class="form-check-input" id="vsInsulinTotalDailyDose" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="vsInsulinTotalDailyDose">Insulin Todal Daily Dose</label>
            </div> 
          </div>
        </div>
        <!-- Vitals Summary End -->        

        <div class="row g-0 mt-4">
          <div class="w-50 pe-2">
            <h5>Health Metrics</h5>

            <!--
            <div class="form-check form-switch mt-2">
              <input [(ngModel)]="this.userModel.journeyConfig.fastingBloodSugar" class="form-check-input" id="flexSwitchCheckDefault" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="flexSwitchCheckDefault">Fasting Blood Sugar</label>
            </div>
            -->
            
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.hmMedications" class="form-check-input" id="hmMedications" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="hmMedications">Medications</label>
            </div>

            <!--
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.medications2" class="form-check-input" id="flexSwitchCheckDefault" type="checkbox" disabled (change)="saveData()">
              <label class="form-check-label" for="flexSwitchCheckDefault">Medications - Advanced</label>
            </div>
            -->

            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.hmBloodPressure" class="form-check-input" id="hmBloodPressure" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="hmBloodPressure">Blood Pressure</label>
            </div>            

            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.hmFootCare" class="form-check-input" id="hmFootCare" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="hmFootCare">Foot Care</label>
            </div>

            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.hmSymptomaticEpisodes" class="form-check-input" id="hmSymptomaticEpisodes" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="hmSymptomaticEpisodes">Symptomatic Episodes</label>
            </div>

            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.hmStressLevel" class="form-check-input" id="hmStressLevel" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="hmStressLevel">Stress Level</label>
            </div>

            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.hmSleepQuality" class="form-check-input" id="hmSleepQuality" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="hmSleepQuality">Sleep Quality</label>
            </div>
            
            <!--
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.sleepQuality2" class="form-check-input" id="flexSwitchCheckDefault" type="checkbox" disabled (change)="saveData()">
              <label class="form-check-label" for="flexSwitchCheckDefault">Sleep Quality - Advanced</label>
            </div>
            -->

          </div>
          <div class="w-50 ps-2">
            <h5>Lifestyle Insights</h5>

            <div class="form-check form-switch mt-2">
              <input [(ngModel)]="this.userModel.journeyConfig.liWeight" class="form-check-input" id="liWeight" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="liWeight">Weight</label>
            </div>

            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.liCalories" class="form-check-input" id="liCalories" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="liCalories">Calories</label>
            </div>
  
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.liCarbs" class="form-check-input" id="liCarbs" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="liCarbs">Carbs</label>
            </div>

            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.liHealthySnacks" class="form-check-input" id="liHealthySnacks" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="liHealthySnacks">Healthy Snacks</label>
            </div>
  
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.liAlcohol" class="form-check-input" id="liAlcohol" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="liAlcohol">Alcohol</label>
            </div>
            
            <!--
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.alcohol2" class="form-check-input" id="flexSwitchCheckDefault" type="checkbox" disabled (change)="saveData()">
              <label class="form-check-label" for="flexSwitchCheckDefault">Alcohol</label>
            </div>            
            -->
  
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.liIntermittentFasting" class="form-check-input" id="liIntermittentFasting" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="liIntermittentFasting">Intermittent Fasting</label> 
            </div>

            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.liExcercise" class="form-check-input" id="liExcercise" type="checkbox" (change)="saveData()">
              <label class="form-check-label" for="liExcercise">Excercise</label> 
            </div>
            
            <!--
            <div class="form-check form-switch">
              <input [(ngModel)]="this.userModel.journeyConfig.excercise2" class="form-check-input" id="flexSwitchCheckDefault" type="checkbox" disabled (change)="saveData()">
              <label class="form-check-label" for="flexSwitchCheckDefault">Excercise - Advanced</label>
            </div>
            -->

          </div>

          
        </div>


    </div>
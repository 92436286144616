import { AfterViewInit, Component } from '@angular/core';
import { faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { UserModel } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/services/user.service';
//import { StripeModule } from 'stripe-angular';
//import { loadStripe } from '@stripe/stripe-js';
//import { StripeScriptTag } from "stripe-angular"

@Component({
  selector: 'app-pricing-stripe',
  templateUrl: './pricing-stripe.component.html',
  styleUrls: ['./pricing-stripe.component.css']
})
export class PricingStripeComponent implements AfterViewInit {

  public stripe_key: string = 'pk_test_51OSQ8IFypOnWCy49P6HyQa2cj4ou5xUUNJcfygs0LBMNdpKjo8k8cPjpiaymzqnPvoSuZYLF0HPTMmQyvstiv12s003TH6uUxj';
  public stripe_table: string = 'prctbl_1OT8dUFypOnWCy49TVDSeEqz'  
  public stripe_customer_id: string = "";
  public stripe_customer_email: string = "";

  faSquareCheck = faSquareCheck;
  faCheck = faCheck;

  userModel: UserModel = new UserModel();

  constructor(
    private userService: UserService,
  ) {}


  ngAfterViewInit(): void {
    // load userModel
    this.userService.getProfile().subscribe({
      next: (value) => {
        if(value) {
          this.userModel = value;  
          this.stripe_customer_id = value.uid;  
          this.stripe_customer_email = value.email;           
        }
      },
    });
  }

}
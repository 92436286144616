    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    
    <main class="main" id="top">

        <!--<nav class="navbar navbar-vertical navbar-expand-lg" style="display:block">
          vertical nav bar
        </nav>-->
        
          <app-header></app-header>
          
          
          
        
  
        <div class="content" >
  
          <!--==========================-->
  
          <div class="row g-0">
            
            <div class="col-lg-6">
              <div class="row flex-center g-0 px-4 px-sm-0">

                <nav aria-label="breadcrumb" class="mb-4"> 
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item fs-0"><a routerLink="/onboarding">Onboarding</a></li>
                    <li class="breadcrumb-item active fs-0" aria-current="page">Step 1. Quick Self-Assessment</li> 
                  </ol>
                </nav>
                
                
                <div class="col-lg-11">
                    
                    <!--<p class="assessment-title">Step 1. Self-Assessment</p>-->
                    
                    <!--<p>{{ selfAssessmentModel | json }}</p>-->

                        <!--<app-sa-step-1></app-sa-step-1>-->
                        <router-outlet></router-outlet> 
                                                                        
                </div>
  
                <div class="col-lg-1">&nbsp;</div>
  
  
              </div>
            </div>
  
  
            <div class="col-lg-6 position-relative d-none d-lg-block ">
              <div class="d-flex align-items-center justify-content-center h-100">
                <img class="w-100" src="../assets/img/spot-illustrations/13.png"/>
              </div>  
            </div>
  
          </div>
  
          <!--==========================-->
  
        </div>
        
      </main>
      <!-- ===============================================-->
      <!--    End of Main Content-->
      <!-- ===============================================-->


      <app-footer></app-footer>
  
<div class="d-flex min-vh-75 w-100 flex-column justify-content-center">
    <div class="">                    
        <p>Question: 1 of 10</p>
        <div class="progress" style="height:8px">
        <div class="progress-bar" role="progressbar" style="width: 10%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
    <div class="flex-grow-1 "></div>
    <div class="d-flex d-flex justify-content-center">

        <div class="assessment-block">
            
            <p class="question-header">Which type of diabetes do you have?</p>

            
            <div class="form-check">
                <input [(ngModel)]="ss.selfAssessmentModel.diabetesType" class="btn-check" id="flexRadioDefault1" type="radio" name="question1" value="Diabetes Type 1" (change)="onChange()"/>        
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault1">Diabetes Type 1</label>
            </div>

            <div class="form-check">        
                <input [(ngModel)]="ss.selfAssessmentModel.diabetesType" class="btn-check" id="flexRadioDefault2" type="radio" name="question1" value="Diabetes Type 2" (change)="onChange()"/>
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault2">Diabetes Type 2</label>
            </div>

            <div class="form-check">        
                <input [(ngModel)]="ss.selfAssessmentModel.diabetesType" class="btn-check" id="flexRadioDefault3" type="radio" name="question1" value="Pre-diabetes" (change)="onChange()"/>
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault3">Pre-diabetes</label>
            </div>

            <div class="form-check">        
                <input [(ngModel)]="ss.selfAssessmentModel.diabetesType" class="btn-check" id="flexRadioDefault4" type="radio" name="question1" value="I don't have it" (change)="onChange()"/>
                <label class="btn btn-outline-secondary w-100" for="flexRadioDefault4">I don't have it</label>
            </div>

            

        </div>
    </div>
    <div class="p-2 flex-grow-1"></div>
    
    <div class="d-flex mb-2">
        <div class="me-auto p-2">
            <!--<button class="btn btn-link" type="submit">Prev</button>-->
        </div>
        <div class="p-2">
        <button class="btn btn-link" (click)="_nextButton()" type="submit">Skip</button>
        </div>
        <div class="p-2">
        <button class="btn btn-primary" (click)="_nextButton()" type="submit">Next</button>
        </div>
    </div>
</div>

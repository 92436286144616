import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { CachedUserService } from './cached.user.service';
import { UserModel, UserSelfAssessmentModel } from '../model/user.model';
import { BloodSugarModel } from '../model/blood.sugar.model';
import { environment } from 'src/environments/environment';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root'
})
export class BloodSugarService {

    private API_SERVER_URL = environment.apiServerURL;

    private BLOOD_SUGAR_API = this.API_SERVER_URL + '/v1/bloodSugar';
    private BLOOD_SUGAR_SEARCH_API = this.API_SERVER_URL + '/v1/bloodSugar';
    
    constructor(
      private http: HttpClient,
      private headerService: HeaderService,
    ){}

    
    /*
    Get a user's Blood Sugar Measurements from backend
    */
    getAll(): Observable<BloodSugarModel[]> {
      const headers = this.headerService.generateHttpHeaders();
      return this.http.get<BloodSugarModel[]>(this.BLOOD_SUGAR_SEARCH_API, { headers });  
    }

    /*
    Get a user's Blood Sugar Measurements from backend
    */
    getForLastDays(days: number): Observable<BloodSugarModel[]> {
      const headers = this.headerService.generateHttpHeaders();
      const url = this.BLOOD_SUGAR_SEARCH_API + "?days=" + days;      
      return this.http.get<BloodSugarModel[]>(url, { headers });  
    }
    
   

    /*
    Create new Blood Sugar Measurement object and save it
    */
    create(value: BloodSugarModel): Observable<BloodSugarModel> {
      const headers = this.headerService.generateHttpHeaders();          
      return this.http.post<BloodSugarModel>(this.BLOOD_SUGAR_API, value, { headers });      
    }


}
export class JourneyConfig {

    // Core Functions config
    coreBloodSugar: boolean = false;
    coreInsulin: boolean = false;

    // Vitals Summary
    vsFastingBloodSugar: boolean = true;
    vsBeforeMeals: boolean = true;
    vsPostMeals: boolean = true;
    vsInsulinTotalDailyDose: boolean = true;

    // Health Metrics config
    hmFastingBloodSugar: boolean = false;
    hmMedications: boolean = false;
    hmMedications2: boolean = false;
    hmBloodPressure: boolean = false;
    hmFootCare: boolean = false;
    hmSymptomaticEpisodes: boolean = false;
    hmStressLevel: boolean = false;
    hmSleepQuality: boolean = false;
    hmSleepQuality2: boolean = false;

    // Lifestyle Insights config
    liWeight: boolean = false;
    liCalories: boolean = false;
    liCarbs: boolean = false;   
    liHealthySnacks: boolean = false; 
    liAlcohol: boolean = false;
    liAlcohol2: boolean = false;
    liIntermittentFasting: boolean = false;
    liExcercise: boolean = false;
    liExcercise2: boolean = false;

    // Blood Sugar Journal config
    showRandomBloodSugar: boolean = true;
    showBeforeMeals: boolean = true;
    showPostMeals: boolean = true;
    showFastingBloodSugar: boolean = true;
    showFeelingUnwellOrStressed: boolean = true;
    showDuringSymptomaticEpisode: boolean = true;

    // Insulin Journal config
    showRapidActing: boolean = true;
    showShortActing: boolean = true;
    showIntermediateActing: boolean = true;
    showLongActing: boolean = true;

    constructor() {}
  }
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { CachedUserService } from './cached.user.service';
import { UserModel, UserSelfAssessmentModel } from '../model/user.model';
import { DailyRoutineModel } from '../model/daily.routine.model';
import { JourneyConfig } from '../model/journey.config';
import { environment } from 'src/environments/environment';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {

    private API_SERVER_URL = environment.apiServerURL;

    private PROFILE_API = this.API_SERVER_URL + '/v1/profile';    
    private SELF_ASSESSMENT_API = this.API_SERVER_URL + '/v1/profile/self-assessment';
    private DAILY_ROUTINE_API = this.API_SERVER_URL + '/v1/profile/daily-routine';
    private SUGGESTED_JOURNEY_API = this.API_SERVER_URL + '/v1/profile/suggested-journey';
  

    private timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    //public userProfileSubject = new BehaviorSubject<any>(null);
    private userSubject;
    public userData$: Observable<UserModel | null>;
  
    constructor(
      private http: HttpClient,
      private headerService: HeaderService,      
    ){
      this.userSubject = new BehaviorSubject<UserModel | null>(null); 
      this.userData$ = this.userSubject.asObservable();
    }

    public getCurrentUserModelValue() {
      return this.userSubject.value;
    }

    public pushNextValue(value: UserModel | null) {
      this.userSubject.next(value);
    }

    getObs() {
      return this.userData$;
    }

    /*
    Get a user profile from backend
    */
    getProfile(): Observable<UserModel | null> {

      const cachedUserProfile = this.userSubject.value;
      if (!cachedUserProfile) {

        //console.log("cachedUserProfile is null/undefined")
  
        const headers = this.headerService.generateHttpHeaders();
        //console.log("headers: " + JSON.stringify(headers))
        const observable = this.http.get<UserModel>(this.PROFILE_API, { headers });

        observable.subscribe({
            next: (value: UserModel) => {
              this.userSubject.next(value);
              //console.log("got value for user profile: " + value);
            }          
        });                        
      }
      return this.userData$;
    }


    /*
    Save user profile object to the backend
    */
    saveProfile(value: UserModel): Observable<UserModel> {
      this.userSubject.next(value);   
      //console.log('pushed new user value')      
      const headers = this.headerService.generateHttpHeaders();          
      return this.http.put<UserModel>(this.PROFILE_API, value, { headers });         
    }

    /*
    Get a user's self assessment from backend
    */
    getSelfAssessment(): Observable<UserSelfAssessmentModel> {
      const headers = this.headerService.generateHttpHeaders();
      return this.http.get<UserSelfAssessmentModel>(this.SELF_ASSESSMENT_API, { headers });  
    }

    /*
    Create new self-assessment object and save it
    */
    createSelfAssessment(value: UserSelfAssessmentModel): Observable<UserSelfAssessmentModel> {
      const headers = this.headerService.generateHttpHeaders();          
      return this.http.post<UserSelfAssessmentModel>(this.SELF_ASSESSMENT_API, value, { headers });      
    }

    /*
    Save self assessemnt object to the backend
    */
    saveSelfAssessment(value: UserSelfAssessmentModel): Observable<UserSelfAssessmentModel> {
      const headers = this.headerService.generateHttpHeaders();          
      return this.http.put<UserSelfAssessmentModel>(this.SELF_ASSESSMENT_API, value, { headers });      
    }

    /*
    Get a user's initial suggested journey config 
    */
    getSuggestedJourney(): Observable<JourneyConfig> {
      const headers = this.headerService.generateHttpHeaders();
      return this.http.get<JourneyConfig>(this.SUGGESTED_JOURNEY_API, { headers });  
    }


    /*
    Get a user's daily routine from backend
    */
    getDailyRoutine(dateMarker: number): Observable<DailyRoutineModel> {
      const headers = this.headerService.generateHttpHeaders();
      const url = this.DAILY_ROUTINE_API + "?dateMarker=" + dateMarker;
      return this.http.get<DailyRoutineModel>(url, { headers });  
    }

    /*
    Create new daily routine object and save it
    */
    createDailyRoutine(value: DailyRoutineModel): Observable<DailyRoutineModel> {
      const headers = this.headerService.generateHttpHeaders();          
      return this.http.post<DailyRoutineModel>(this.DAILY_ROUTINE_API, value, { headers });      
    }

    /*
    Save daily routine object to the backend
    */
    saveDailyRoutine(value: DailyRoutineModel): Observable<DailyRoutineModel> {
      const headers = this.headerService.generateHttpHeaders();          
      return this.http.put<DailyRoutineModel>(this.DAILY_ROUTINE_API, value, { headers });      
    }

}
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    
    <main class="main" id="top">

        <!--<nav class="navbar navbar-vertical navbar-expand-lg" style="display:block">
          vertical nav bar
        </nav>-->
        
          <app-header></app-header>
          
          
        <div class="content" >
  
          <!--==========================-->
  
          <div class="row g-0">            
            
            <div class="col-lg-6">
              <div class="row flex-center g-0 px-4 px-sm-0">
                
                <nav aria-label="breadcrumb" class="mb-4"> 
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item fs-0"><a routerLink="/onboarding">Onboarding</a></li>
                    <li class="breadcrumb-item active fs-0" aria-current="page">Welcome Message</li> 
                  </ol>
                </nav>
                
                
                    
                    <!--<p class="assessment-title">Welcome to Novalumen!</p>-->
                    
                    <!-- profile starts -->

                    <div class="d-flex min-vh-75 w-100 flex-column justify-content-center">
                        
                        <div class="flex-grow-1 "></div>
                        <div class="d-flex d-flex justify-content-center">
                    
                            <div *ngIf="this.userModel" class="assessment-block" >
                                
                                <h4 class="mb-2">Hi {{userModel.displayName}},</h4>
                                <p>
                                  Welcome to Novalumen, your simple, smart companion for managing diabetes. 
                                  Easily track daily routines, monitor blood sugar, manage insulin, 
                                  plan your day, and receive stress-free support with our smart AI 
                                  and notifications. Here's to a healthier, happier you!
                                </p>
                                <!--
                                <p>
                                    We're thrilled to have you on board. 
                                    Novalumen is a super easy and smart app created to make life simpler 
                                    for people dealing with diabetes. It's like a new and friendlier 
                                    way to keep track of your daily routines for a healthier you!                        
                                </p>
                                <p>
                                    Discover platform's awesome features designed just for you! 
                                    Keep tabs on your blood sugar and insulin with our easy trackers. 
                                    Plan out your day and manage medications effortlessly. 
                                    We've even got your diet and nutrition covered. 
                                    Plus, our smart AI and notifications make handling all the tricky stuff a breeze.
                                    No stress, just simple, everyday support for a healthier, happier you!
                                </p>
                                -->
                                                                  
                                <h4 class="mt-4 mb-4">Get started in three simple steps:</h4> 

                                <div class="mb-2">
                                    <div *ngIf="isStep1Checked">
                                      <fa-icon [icon]="faSquareCheck" [size]="'lg'" class="me-2" />
                                      <a routerLink="/self-assessment/1"><b>Step 1. Quick Self-Assessment</b></a>
                                    </div>
                                    <div *ngIf="!isStep1Checked">
                                      <fa-icon [icon]="faSquare" [size]="'lg'" class="me-2" />
                                      <b>Step 1. Quick Self-Assessment</b>
                                    </div>
                                    
                                    Let our platform learn more about you through 
                                    a brief self-assessment.
                                    We'll guide you through it, empowering you to take control 
                                    of your health journey.
                                </div>
                                <div class="mb-2">
                                    <div *ngIf="isStep2Checked">
                                      <fa-icon [icon]="faSquareCheck" [size]="'lg'" class="me-2" />
                                      <a routerLink="/personal-journey"><b>Step 2. Personalize Your Journey</b></a>
                                    </div>
                                    <div *ngIf="!isStep2Checked">
                                      <fa-icon [icon]="faSquare" [size]="'lg'" class="me-2" />
                                      <b>Step 2. Personalize Your Journey</b>
                                    </div>                                    
                                    Tailor your Novalumen experience with an AI-generated individual journey. Personalize and make it uniquely yours! 
                                </div>
                                <div class="mb-4">
                                    <fa-icon *ngIf="isStep3Checked" [icon]="faSquareCheck" [size]="'lg'" class="me-2" />
                                    <fa-icon *ngIf="!isStep3Checked" [icon]="faSquare" [size]="'lg'" class="me-2" />
                                    <!--<b>Step 3. Launch Your Journey</b><br>
                                    Start using Novalumen app for 2 weeks and let it learn about you. We'll adapt and provide insights to make your diabetes journey uniquely yours.-->
                                    <b>Step 3. Choose Your Path</b><br>
                                    Embark on your Novalumen adventure by selecting the plan that 
                                    suits you best. Take a moment to explore our subscription options, 
                                    each designed to enhance your experience and support your well-being. 
                                    Your journey, your choice!

                                </div>

                                <button *ngIf="userModel.onboardingStatus==='Step 1'" routerLink="/self-assessment/1" class="btn btn btn-primary me-1 mb-1" type="button">Get Started</button>
                                <button *ngIf="userModel.onboardingStatus==='Step 2'" routerLink="/personal-journey" class="btn btn btn-primary me-1 mb-1" type="button">Personalize Your Journey</button>
                                <button *ngIf="userModel.onboardingStatus==='Step 3'" routerLink="/pricing" class="btn btn btn-primary me-1 mb-1" type="button">Choose Your Path</button>

                                <button *ngIf="userModel.onboardingStatus==='Baseline Period'" routerLink="/dashboard" class="btn btn btn-primary me-1 mb-1" type="button">My Dashboard</button>
                                <button *ngIf="userModel.onboardingStatus==='Finished'" routerLink="/dashboard" class="btn btn btn-primary me-1 mb-1" type="button">My Dashboard</button>

                            </div>
                        </div>

                        <div class="p-2 flex-grow-1"></div>
                        
                        <!--
                        <div class="d-flex mb-2">
                            <div class="me-auto p-2">
                            <button class="btn btn-link" type="submit">Prev</button>
                            </div>
                            <div class="p-2">
                            <button class="btn btn-link" type="submit">Skip</button>
                            </div>
                            <div class="p-2">
                                <button class="btn btn-primary" type="submit">Next</button>
                            </div>
                        </div>
                        -->

                    </div>
                    <!--
                    <p>
                        🌟Cheers to your health journey with Novalumen! For any assistance, we're here for you. 🌟

                    </p>
                -->

                    <!-- profile ends -->
                    
                                                                        
                
                
  
  
              </div>
            </div>
  
  
            <div class="col-lg-6 position-relative d-none d-lg-block  ">
              <div class="d-flex align-items-center justify-content-center h-100">
                <img class="w-100" src="../assets/img/bg/assessment-bg-boy.png"/>
              </div>  
            </div>
  
          </div>
  
          <!--==========================-->
  
        </div>
        
      </main>
      <!-- ===============================================-->
      <!--    End of Main Content-->
      <!-- ===============================================-->


      <app-footer></app-footer>
  
<main class="main mb-3" id="top">

    <!--<nav class="navbar navbar-vertical navbar-expand-lg" style="display:block">
      vertical nav bar
    </nav>-->

        
    <app-header [userModel]="userModel" [subscription]="subscription"></app-header>

    <div class="content" >

        <div class="card p-4">   

            <div class="d-flex me-3">
                <div>
                    <h4>Blood Sugar Journal</h4>
                    <p class="fs--1 fw-semi-bold">Explore your personal blood sugar trends</p>
                </div>
                <div class="ms-auto">
                    <!--<button #addBloodSugarButton (click)="addBloodButton()" class="btn btn-primary btn-sm" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBloodSugarForm">New Record</button>-->
                    <button #addBloodSugarButton (click)="addBloodButton()" class="btn btn-sm btn-phoenix-secondary  btn-small" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBloodSugarForm"><fa-icon [icon]="faPlus" /></button>
                    <!--<button #addBloodSugarButton class="btn btn-sm btn-phoenix-secondary rounded-pill btn-small" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDailyRoutineConfig"><fa-icon [icon]="faSliders" /></button>                                                                                                                  -->
                </div>
            </div> 
        </div>
    
    </div>

    
    
  </main>
  <!-- ===============================================-->
  <!--    End of Main Content-->
  <!-- ===============================================-->


  <app-footer></app-footer>   
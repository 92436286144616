import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FirebaseAuthResolver } from './shared/resolvers/FirebaseAuthResolver';

import { AuthGuard } from '@angular/fire/auth-guard';

import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SelfAssessmentComponent } from './components/self-assessment/self-assessment.component';
import { SaStep1Component } from './components/self-assessment/sa-step-1/sa-step-1.component';
import { SaStep2Component } from './components/self-assessment/sa-step-2/sa-step-2.component';
import { SaStep3Component } from './components/self-assessment/sa-step-3/sa-step-3.component';
//import { AuthGuard } from './shared/services/auth.guard';
import { UserModelResolver } from './shared/resolvers/UserModelResolver';
import { SelfAssessmentModelResolver } from './shared/resolvers/SelfAssessmentResolver';
import { AppComponent } from './app.component';
import { SaStep4Component } from './components/self-assessment/sa-step-4/sa-step-4.component';
import { SaStep5Component } from './components/self-assessment/sa-step-5/sa-step-5.component';
import { SaStep6Component } from './components/self-assessment/sa-step-6/sa-step-6.component';
import { SaStep7Component } from './components/self-assessment/sa-step-7/sa-step-7.component';
import { SaStep8Component } from './components/self-assessment/sa-step-8/sa-step-8.component';
import { SaStep9Component } from './components/self-assessment/sa-step-9/sa-step-9.component';
import { SaStep10Component } from './components/self-assessment/sa-step-10/sa-step-10.component';
import { SaStepFinalComponent } from './components/self-assessment/sa-step-final/sa-step-final.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { DisabledUserComponent } from './components/disabled-user/disabled-user.component';
import { PersonalJourneyComponent } from './components/personal-journey/personal-journey.component';
import { OnboardingFinalComponent } from './components/onboarding-final/onboarding-final.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { PricingStripeComponent } from './components/pricing-stripe/pricing-stripe.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { SubscriptionConfirmationComponent } from './components/subscription-confirmation/subscription-confirmation.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';
import { TestComponent } from './components/test/test.component';
import { BloodSugarListComponent } from './components/blood-sugar/blood-sugar-list/blood-sugar-list.component';

const routes: Routes = [
  //{path: '', component: AppComponent, canActivate: [AuthGuard]  },
  //{path: '', component: LoginComponent },
  {path: '', component: LandingPageComponent },
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent},
  {path: 'disabledUser', component: DisabledUserComponent},
  {path: 'privacy', component: PrivacyComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'test', component: TestComponent},
  {path: 'onboarding', 
    component: OnboardingComponent, 
    canActivate: [AuthGuard],
    resolve: {
      fbAuthResolver: FirebaseAuthResolver,
      ///userModel: UserModelResolver
    }
  },
  {path: 'personal-journey', 
    component: PersonalJourneyComponent, 
    canActivate: [AuthGuard],
    resolve: {
      fbAuthResolver: FirebaseAuthResolver,
      //userModel: UserModelResolver
    }
  },
  {path: 'onboarding-final', 
    component: OnboardingFinalComponent, 
    canActivate: [AuthGuard],
    resolve: {
      fbAuthResolver: FirebaseAuthResolver,
      //userModel: UserModelResolver
    }
  },
  {path: 'pricing', 
    component: PricingComponent, 
    canActivate: [AuthGuard],
    resolve: {
      fbAuthResolver: FirebaseAuthResolver,
      //userModel: UserModelResolver
    }
  },
  {path: 'subscription-confirmation/:sessionId', 
    component: SubscriptionConfirmationComponent, 
    canActivate: [AuthGuard],
    resolve: {
      fbAuthResolver: FirebaseAuthResolver,
      //userModel: UserModelResolver
    }
  },
  {path: 'pricing-stripe', 
    component: PricingStripeComponent, 
    canActivate: [AuthGuard],
    resolve: {
      fbAuthResolver: FirebaseAuthResolver,
      //userModel: UserModelResolver
    }
  },
  {path: 'dashboard', 
    component: DashboardComponent, 
    canActivate: [AuthGuard],
    resolve: {
      fbAuthResolver: FirebaseAuthResolver,
      //userModel: UserModelResolver
    }
  },
  {path: 'blood-sugar-list', 
    component: BloodSugarListComponent, 
    canActivate: [AuthGuard],
    resolve: {
      fbAuthResolver: FirebaseAuthResolver,
      //userModel: UserModelResolver
    }
  },
  {path: 'profile', 
    component: ProfileComponent, 
    canActivate: [AuthGuard],
    resolve: {
      fbAuthResolver: FirebaseAuthResolver,
      //userModel: UserModelResolver
    }
  },
  {path: 'self-assessment', 
    component: SelfAssessmentComponent,
    children: [
      { path: '1', component: SaStep1Component},
      { path: '2', component: SaStep2Component},
      { path: '3', component: SaStep3Component},
      { path: '4', component: SaStep4Component},
      { path: '5', component: SaStep5Component},
      { path: '6', component: SaStep6Component},
      { path: '7', component: SaStep7Component},
      { path: '8', component: SaStep8Component},
      { path: '9', component: SaStep9Component},
      { path: '10', component: SaStep10Component},
      { path: 'final', component: SaStepFinalComponent},
    ],
    canActivate: [AuthGuard],
    resolve: {
      fbAuthResolver: FirebaseAuthResolver,
      //userModel: UserModelResolver,
      //selfAssessmentModel: SelfAssessmentModelResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

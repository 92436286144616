import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CachedUserService } from "./cached.user.service";
import { InsulinModel } from "../model/insulin.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HeaderService } from "./header.service";

@Injectable({
    providedIn: 'root'
})
export class InsulinService {

    private API_SERVER_URL = environment.apiServerURL;

    private INSULIN_API = this.API_SERVER_URL + '/v1/insulin';
    
    constructor(
      private http: HttpClient,
      private headerService: HeaderService
    ){}

    


    /*
    Get a user's Insulin Records from backend
    */
    getAll(): Observable<InsulinModel[]> {
      const headers = this.headerService.generateHttpHeaders();
      return this.http.get<InsulinModel[]>(this.INSULIN_API, { headers });  
    }

    /*
    Get a user's Insulin Measurements from backend
    */
    getForLastDays(days: number): Observable<InsulinModel[]> {
      const headers = this.headerService.generateHttpHeaders();
      const url = this.INSULIN_API + "?days=" + days;      
      return this.http.get<InsulinModel[]>(url, { headers });  
    }
    
   

    /*
    Create new Insulin Records object and save it
    */
    create(value: InsulinModel): Observable<InsulinModel> {
      const headers = this.headerService.generateHttpHeaders();          
      return this.http.post<InsulinModel>(this.INSULIN_API, value, { headers });      
    }

}
<div #offcanvasInsulinForm class="offcanvas offcanvas-end" id="offcanvasInsulinForm" tabindex="1" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header bg-soft border-bottom">
      <h5 id="offcanvasRightLabel">New Insulin Record</h5>
      <button #offcanvasCloseButton class="btn-close text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">

        <!--<div>{{ this.bloodMeasure | json }}</div>-->

        <!--<form #heroForm="ngForm" class="needs-validation">-->
            <div class="row g-0">
                <div class="w-50 pe-2">
                    <label class="form-label" for="datepicker">Date</label>
                    <input [(ngModel)]="this.insulinRecord.date" mwlFlatpickr [options]="dateOptions" class="form-control datetimepicker flatpickr-input w-100 pe-2" type="text" placeholder="dd/mm/yyyy" >
                </div>
                <div class="w-50 ps-2">
                    <label class="form-label" for="timepicker1">Time</label>
                    <input [(ngModel)]="this.insulinRecord.time" mwlFlatpickr [options]="timeOptions"  class="form-control timeOptions datetimepicker flatpickr-input w-100 pe-2" type="text" placeholder="hour : minute" >
                </div>
            </div>

            <div class="row g-0 mt-3">
                <div class="w-50 pe-2">
                    <label class="form-label" for="timepicker1">Dosage Units</label>
                    <input #myDosageUnitsField [(ngModel)]="this.insulinRecord.dosageUnits" (ngModelChange)="this.bloodSugarValueChange()" class="form-control w-100 pe-2" type="number" [step]="0" placeholder="" required="" >
                    <div class="invalid-feedback">Please provide a valid value.</div>
                </div>
                <div class="w-50 ps-2">
                    <label class="form-label" for="timepicker1">Dosage Type</label><br/>
                    
                    <div class="mt-2 ms-3">
                        <div class="form-check form-check-inline ">
                            <input [(ngModel)]="this.insulinRecord.dosageType" class="form-check-input" type="radio" name="dosageType" id="inlineRadio1" value="Bolus">
                            <label class="form-check-label" for="inlineRadio1">Bolus</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.insulinRecord.dosageType" class="form-check-input" type="radio" name="dosageType" id="inlineRadio2" value="Basal">
                            <label class="form-check-label" for="inlineRadio2">Basal</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.insulinRecord.dosageType" class="form-check-input" type="radio" name="dosageType" id="inlineRadio2" value="Non-Relevant">
                            <label class="form-check-label" for="inlineRadio2">Non-Relevant</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row g-0 mt-3">
                <div class="w-50">
                    <label class="form-label" for="timepicker1">Insulin Type</label><br/>
                    
                    <div class="mt-2">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.insulinRecord.insulinType" class="form-check-input" type="radio" name="insulinType" id="mealTag1" value="Rapid-acting">
                            <label class="form-check-label" for="mealTag1">Rapid-acting</label>
                        </div>
                    </div>   
                    <div class="">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.insulinRecord.insulinType" class="form-check-input" type="radio" name="insulinType" id="mealTag2" value="Short-acting">
                            <label class="form-check-label" for="mealTag2">Short-acting</label>
                        </div>
                    </div>            
                    <div class="">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.insulinRecord.insulinType" class="form-check-input" type="radio" name="insulinType" id="mealTag3" value="Intermediate-acting">
                            <label class="form-check-label" for="mealTag3">Intermediate-acting</label>
                        </div>
                    </div>
                    <div class="">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.insulinRecord.insulinType" class="form-check-input" type="radio" name="insulinType" id="mealTag4" value="Long-acting">
                            <label class="form-check-label" for="mealTag4">Long-acting</label>
                        </div>
                    </div>                         
                    
                </div>
                <div class="w-50 ps-2">
                    <label class="form-label" for="timepicker1">Injection Site</label><br/>
                    
                    <div class="mt-2 ms-3">
                        <div class="form-check form-check-inline ">
                            <input [(ngModel)]="this.insulinRecord.injectionSite" class="form-check-input" type="radio" name="injectionSite" id="activityTag1" value="Abdomen">
                            <label class="form-check-label" for="activityTag1">Abdomen</label>
                        </div>
                    </div>
                    <div class="ms-3">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.insulinRecord.injectionSite" class="form-check-input" type="radio" name="injectionSite" id="activityTag2" value="Thigh">
                            <label class="form-check-label" for="activityTag2">Thigh</label>
                        </div>
                    </div>
                    <div class="ms-3">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.insulinRecord.injectionSite" class="form-check-input" type="radio" name="injectionSite" id="activityTag3" value="Arm">
                            <label class="form-check-label" for="activityTag3">Arm</label>
                        </div>
                    </div>
                    <div class="ms-3">
                        <div class="form-check form-check-inline">
                            <input [(ngModel)]="this.insulinRecord.injectionSite" class="form-check-input" type="radio" name="injectionSite" id="activityTag4" value="Other">
                            <label class="form-check-label" for="activityTag4">Other</label>
                        </div>
                    </div>                    
                    
                </div>
            </div>

            <div class="row g-0 mt-3">
                <div class="w-100">
                    <label for="exampleFormControlTextarea1" class="form-label">Notes</label>
                    <textarea [(ngModel)]="this.insulinRecord.notes" class="form-control" id="notes" rows="3" placeholder="You can add a note here for this measurement"></textarea>
                </div>            
            </div>


            <div class="row g-0 mt-3">
                <div class="w-50 pe-2">
                    <button type="button" class="btn btn-outline-secondary mb-3 w-100" (click)="this.closeForm()">Cancel</button> <!-- data-bs-dismiss="offcanvas" -->
                </div>
                <div class="w-50 ps-2">
                    <button type="submit" class="btn btn-primary mb-3 w-100" (click)="formSubmit()">Submit</button>
                </div>
            </div>

        <!--</form>-->

    </div>